<template>
  <el-container>
    <el-dialog
      :title="title"
      :visible.sync="addKnow"
      width="50%"
      @close="close"
      @opened="getEditor()"
      append-to-body
      :close-on-click-modal="false"
    >
      <div class="content">
        <el-form ref="formData" :model="formData" label-width="80px">
          <el-form-item label="标题:">
            <el-input v-model="formData.title"></el-input>
          </el-form-item>
          <el-form-item label="图片Url:" class="item_input">
            <el-input v-model="formData.imgUrl" placeholder="请输入"></el-input>
            <el-upload
              class="upload-demo"
              :action="`https://kwangda.com:8092/sys/appImg/upload/${formData.title} ${new Date().getTime() / 1000}`"
              :before-upload="beforeFileUp"
              :on-success="fileSuced"
              multiple
              :limit="1"
            >
              <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>
          </el-form-item>
          <el-form-item label="所属分类:">
            <el-cascader
              ref="cascader"
              v-model="formData.threeLevelId"
              :options="classKnowList"
              :props="proCityAreaTreeProps"
              @change="handleChange"
            ></el-cascader>
            <!-- <el-select v-model="formData.classId" placeholder="请选择" class="f_sekect">
              <el-option v-for="item in classKnowList" :key="item.id" :label="item.title" :value="item.id"> </el-option>
            </el-select> -->
          </el-form-item>
          <el-form-item label="标签:">
            <el-input v-model="formData.knowTags"></el-input>
          </el-form-item>
          <el-form-item label="浏览量:">
            <el-input v-model="formData.pageView"></el-input>
          </el-form-item>
          <el-form-item label="作者:">
            <el-input v-model="formData.author"></el-input>
          </el-form-item>
          <el-form-item label="来源链接:">
            <el-input v-model="formData.source"></el-input>
          </el-form-item>
          <el-form-item label="公告内容:">
            <div id="div1"></div>
          </el-form-item>
          <el-form-item label="备用字段:">
            <el-input v-model="formData.prompt"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addKnow = false">取 消</el-button>
        <el-button type="primary" @click="getAddKnowInfo()">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>
<script>
import E from 'wangeditor'
let editor
export default {
  props: ['addKnow', 'addKnowInfo', 'navDataList', 'knowDataInfo', 'title', 'editKnowData'],
  data() {
    return {
      formData: {
        id: '',
        title: '',
        imgUrl: '',
        knowTags: null,
        content: '',
        oneLevelId: '',
        twoLevelId: '',
        threeLevelId: '',
        className: '',
        pageView: '',
        prompt: '',
        source: '',
        author: ''
      },
      proCityAreaTreeProps: {
        emitPath: false,
        value: 'id',
        label: 'title',
        children: 'children'
      },
      classKnowList: [],
      addKnowShow: this.addKnow
    }
  },
  watch: {
    navDataList() {
      this.classKnowList = this.navDataList
    },
    knowDataInfo() {
      this.formData = this.knowDataInfo
      editor.txt.html(this.formData.content)
    },
    title() {
      if (this.title == '新增知识') {
        this.formData.pageView = ''
        this.formData.imgUrl = ''
        this.formData.knowTags = null
        this.formData.content = ''
        this.formData.oneLevelId = ''
        this.formData.twoLevelId = ''
        this.formData.threeLevelId = ''
        this.formData.className = ''
        this.formData.pageView = ''
        this.formData.prompt = ''
        this.formData.source = ''
        this.formData.author = ''
        editor.txt.html((this.formData.content = ''))
      }
    }
  },
  methods: {
    close() {
      this.$emit('closeAddKnow')
    },
    handleChange(value) {
      let nodesObj = this.$refs['cascader'].getCheckedNodes()
      this.formData.oneLevelId = nodesObj[0].path[0]
      this.formData.twoLevelId = nodesObj[0].path[1]
      this.formData.threeLevelId = nodesObj[0].path[2]
    },
    getEditor() {
      editor = new E('#div1')
      editor.create()
    },
    closeNotice() {
      if (this.title == '新增知识') {
        this.addKnow = false
        this.formData.title = ''
        this.formData.imgUrl = ''
        this.formData.knowTags = ''
        this.formData.content = ''
        this.formData.oneLevelId = ''
        this.formData.twoLevelId = ''
        this.formData.threeLevelId = ''
        this.formData.className = ''
        this.formData.pageView = ''
        this.formData.prompt = ''
        this.formData.source = ''
        this.formData.author = ''
        editor.txt.html((this.formData.content = ''))
      } else {
        this.addKnow = false
      }
    },
    beforeFileUp() {
      if (!this.formData.title) {
        this.$message.error('请先输入知识标题')
        return false
      }
    },
    fileSuced(res) {
      if (res.success) {
        // console.log('文件上传成功：：', res)
        this.formData.imgUrl = res.data
        this.$message.success('上传成功')
      }
    },
    // 提交表单
    async getAddKnowInfo() {
      const content = editor.txt.html()
      this.formData.content = content
      if (this.title == '新增知识') {
        this.addKnow = false
        const data = {
          oneLevelId: this.formData.oneLevelId,
          twoLevelId: this.formData.twoLevelId,
          threeLevelId: this.formData.threeLevelId,
          knowImg: this.formData.imgUrl,
          knowTitle: this.formData.title,
          knowContent: this.formData.content,
          prompt: this.formData.prompt,
          knowTags: this.formData.knowTags,
          author: this.formData.author,
          knowSource: this.formData.source,
          pageView: this.formData.pageView
        }
        this.addKnowInfo(data)
        this.formData.pageView = ''
        this.formData.imgUrl = ''
        this.formData.knowTags = null
        this.formData.content = ''
        this.formData.oneLevelId = ''
        this.formData.twoLevelId = ''
        this.formData.threeLevelId = ''
        this.formData.className = ''
        this.formData.pageView = ''
        this.formData.prompt = ''
        this.formData.source = ''
        this.formData.author = ''
        editor.txt.html((this.formData.content = ''))
      } else if (this.title == '编辑知识') {
        const data = {
          id: this.formData.id,
          oneLevelId: this.formData.oneLevelId,
          twoLevelId: this.formData.twoLevelId,
          threeLevelId: this.formData.threeLevelId,
          knowImg: this.formData.imgUrl,
          knowTitle: this.formData.title,
          knowContent: this.formData.content,
          prompt: this.formData.prompt,
          knowTags: this.formData.knowTags,
          author: this.formData.author,
          knowSource: this.formData.source,
          pageView: this.formData.pageView
        }
        this.editKnowData(data)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.el-select {
  width: 100%;
}
</style>
