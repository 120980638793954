<template>
  <el-container>
    <el-dialog
      :title="title"
      :visible.sync="addGoods"
      width="50%"
      @close="close"
      append-to-body
      :close-on-click-modal="false"
    >
      <div class="content">
        <el-form ref="formData" :model="formData" label-width="80px">
          <el-form-item label="服务名称:">
            <el-input v-model="formData.serviceName" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="广告标语:">
            <el-input v-model="formData.adText" placeholder="请输入"></el-input>
          </el-form-item>
          <div class="item_unit">
            <el-form-item label="服务金额:" class="item_input">
              <el-input v-model="formData.serviceAmount" placeholder="请输入"></el-input>
            </el-form-item>
            <div class="item_text">元</div>
          </div>
          <div class="item_unit">
            <el-form-item label="原价金额:" class="item_input">
              <el-input v-model="formData.originalPrice" placeholder="请输入"></el-input>
            </el-form-item>
            <div class="item_text">元</div>
          </div>
          <div class="item_unit">
            <el-form-item label="服务期限:" class="item_input">
              <el-input v-model="formData.effectivePeriod" placeholder="请输入"></el-input>
            </el-form-item>
            <div class="item_text">年</div>
          </div>
          <el-form-item label="服务内容:">
            <el-input :rows="10" type="textarea" v-model="formData.serviceContent" placeholder="富文本"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addGoods = false">取 消</el-button>
        <el-button type="primary" @click="getAddGoods()">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>
<script>
export default {
  props: ['addGoods', 'addGoodsFormData', 'goodsDataInfo', 'title', 'editGoodsFormData'],
  data() {
    return {
      formData: {
        id: '',
        serviceName: '',
        adText: '',
        serviceAmount: '',
        originalPrice: '',
        effectivePeriod: '',
        serviceContent: '',
        sold: ''
      },
      addGoodsShow: this.addGoods
    }
  },
  watch: {
    goodsDataInfo() {
      this.formData = this.goodsDataInfo
    }
  },
  mounted() {},
  methods: {
    close() {
      this.$emit('closeAddGoods')
    },
    // 提交表单
    async getAddGoods() {
      if (this.title == '新增服务') {
        this.addGoodsFormData(this.formData)
        this.addGoods = false
        this.formData.serviceName = ''
        this.formData.serviceAmount = ''
        this.formData.effectivePeriod = ''
        this.formData.serviceContent = ''
        this.formData.sold = ''
      } else if (this.title == '编辑服务') {
        this.editGoodsFormData(this.formData)
        this.addGoods = false
        this.formData.serviceName = ''
        this.formData.serviceAmount = ''
        this.formData.effectivePeriod = ''
        this.formData.serviceContent = ''
        this.formData.sold = ''
      }
    }
  }
}
</script>
<style lang="less" scoped>
.el-input {
  width: 100%;
}
.item_unit {
  display: flex;
  .item_input {
    width: 100%;
  }
  .item_text {
    padding-top: 10px;
  }
}
</style>
