<template>
  <el-container>
    <el-main>
      <el-row>
        <el-col :span="4" class="leftBox">
          <div class="left_titBox">
            <div class="l_t_txt">知识库分类</div>
            <div class="l_t_btn" @click="OpenAddKnowClass">添加</div>
          </div>
          <div class="left_contentBox">
            <el-tree :data="navDataList" :props="treesProps" @node-click="handleNodeClick"></el-tree>
            <!-- <div class="l_c_items" v-for="(items, v) in navDataList" :key="v">
              <div class="l_c_i_txt" :class="{ active: isActive == v }" @click="getClassKnowList(items, v)">
                {{ items.className }}
                (
                <p v-if="items.displayTerminal == 0">用户中心</p>
                <p v-if="items.displayTerminal == 1">物业中心</p>
                <p v-if="items.displayTerminal == 2">运营中心</p>
                <p v-if="items.displayTerminal == null">其他</p>
                )
              </div>
              <div class="l_c_i_btn">
                <div class="l_c_i_edit" @click="openEditKnowClass(items)">编辑</div>
                <div class="l_c_i_dele" @click="openDeleteKnowClass(items)">删除</div>
              </div>
            </div> -->
          </div>
        </el-col>
        <!-- 列表 -->
        <div class="rightBox">
          <div class="rightBtn">
            <div class="leftTit">知识列表</div>
            <el-button type="primary" @click="OpenAddKnow">新增知识</el-button>
          </div>
          <el-col class="list" :span="20">
            <el-table :data="tableData.rows" style="width: 100%">
              <el-table-column prop="id" label="ID" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="className" label="分类名称" :show-overflow-tooltip="true"> </el-table-column>
              <!-- <el-table-column label="分类名称" :show-overflow-tooltip="true">
                <template>
                  <p>{{ className }}</p>
                </template>
              </el-table-column> -->
              <el-table-column label="显示终端" :show-overflow-tooltip="true">
                <template>
                  <p v-if="displayTerminal == 0">用户中心</p>
                  <p v-if="displayTerminal == 1">物业中心</p>
                  <p v-if="displayTerminal == 2">运营中心</p>
                  <p v-if="displayTerminal == null">其他</p>
                </template>
              </el-table-column>
              <el-table-column prop="title" label="标题" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="imgUrl" label="图片" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <el-image style="width: 100px; height: 100px" :src="scope.row.knowImg"> </el-image>
                </template>
              </el-table-column>
              <el-table-column label="标签" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <p>
                    {{ scope.row.knowTags == 0 ? '热点新闻' : '' }}
                  </p>
                </template>
              </el-table-column>
              <el-table-column prop="pageView" label="浏览量" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="createTime" label="发布时间" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <p>
                    {{ formatDate(scope.row.createTime) }}
                  </p>
                </template>
              </el-table-column>
              <el-table-column fixed="right" label="操作" width="120">
                <template slot-scope="scope">
                  <el-button @click.native.prevent="openKnowDataInfo(scope.row)" type="text" size="small">
                    编辑
                  </el-button>
                  <el-button @click.native.prevent="deleteRow(scope.row)" type="text" size="small"> 删除 </el-button>
                </template>
              </el-table-column>
            </el-table>
            <div class="pageBox">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :page-sizes="[5, 10, 20]"
                layout="total, sizes, prev, pager, next, jumper"
                :total="tableData.total"
              >
              </el-pagination>
            </div>
          </el-col>
        </div>
      </el-row>
      <!-- 添加分类 -->
      <addKnowClass
        :title="title"
        :addKnowClass="addKnowClassShow"
        :navDataList="navDataList"
        @closeAddKnowClass="showAddKnowClass"
        :addKnowClassInfo="addKnowClassInfo"
        :knowClassDataInfo="knowClassDataInfo"
        :editKnowClass="editKnowClass"
      ></addKnowClass>
      <!-- 添加知识 -->
      <addKnow
        :addKnow="addKnowShow"
        :title="title"
        @closeAddKnow="showAddKnow"
        :addKnowInfo="addKnowInfo"
        :navDataList="navDataList"
        :knowDataInfo="knowDataInfo"
        :editKnowData="editKnowData"
      ></addKnow>
    </el-main>
  </el-container>
</template>
<script>
import {
  getKnowledgeData,
  getClassToKnowledgeData,
  deleteKnowClass,
  addKnowClass,
  addKnow,
  deleteKnow,
  editKnow,
  putEditKnowClass,
  getKnowClassThree
} from '@/api/public.js'
import AddKnowClass from '@/components/know/addKnowClass'
import AddKnow from '@/components/know/addKnow'
export default {
  components: {
    AddKnowClass: AddKnowClass,
    AddKnow: AddKnow
  },
  data() {
    return {
      addKnowClassShow: false,
      addKnowShow: false,
      isActive: 0,
      input: '',
      navDataList: [],
      treesProps: {
        label: 'title',
        children: 'children'
      },
      tableData: [],
      query: {
        page: 1,
        pageSize: 10
      },
      classId: '',
      className: '',
      displayTerminal: '',
      knowDataInfo: '', // 编辑知识
      title: '',
      knowClassDataInfo: '' //编辑分类
    }
  },
  mounted() {
    this.getKnowClassList()
  },
  methods: {
    //获取分类
    async getKnowClassList() {
      let result = await getKnowClassThree()
      // let result = await getKnowledgeData()
      if (result.success) {
        this.navDataList = result.data
        let res = await getClassToKnowledgeData(this.navDataList[0].id, this.query)
        if (res.success) {
          this.tableData = res.data
        }
      }
    },
    async handleNodeClick(data) {
      let res = await getClassToKnowledgeData(data.id, this.query)
      if (res.success) {
        this.tableData = res.data
      }
    },
    // 获取分类下知识
    getClassKnowList(items, v) {
      this.isActive = v
      this.classId = items.id
      // this.className = items.className
      this.displayTerminal = items.displayTerminal
      this.getKnowDataList()
    },
    async getKnowDataList() {
      let res = await getClassToKnowledgeData(this.classId, this.query)
      if (res.success) {
        this.tableData = res.data
      }
    },
    //分页-size
    handleSizeChange(val) {
      this.query.pageSize = val
      this.getKnowDataList()
    },
    //分页-page
    handleCurrentChange(val) {
      this.query.page = val
      this.getKnowDataList()
    },
    // 新增分类
    OpenAddKnowClass() {
      this.addKnowClassShow = true
      this.title = '新增分类'
    },
    showAddKnowClass() {
      this.addKnowClassShow = false
    },
    // 新增分类接口
    async addKnowClassInfo(data) {
      let res = await addKnowClass(data)
      if (res.success) {
        this.$message({
          type: 'success',
          message: '新增分类成功!'
        })
        this.getKnowDataList()
        this.addKnowClassShow = false
      } else {
        this.$message({
          type: 'error',
          message: '新增分类失败!'
        })
      }
      this.getKnowClassList()
    },
    // 编辑分类
    async openEditKnowClass(items) {
      this.addKnowClassShow = true
      this.knowClassDataInfo = items
      this.title = '编辑分类'
    },
    // 编辑分类接口
    async editKnowClass(data) {
      let res = await putEditKnowClass(data, data.id)
      if (res.success) {
        this.$message({
          type: 'success',
          message: '编辑知识成功!'
        })
        let result = await getKnowledgeData()
        if (result.success) {
          this.navDataList = result.data
        }
        this.addKnowClassShow = false
      } else {
        this.$message({
          type: 'error',
          message: '编辑知识失败!'
        })
      }
    },
    // 删除分类
    async openDeleteKnowClass(items) {
      this.$confirm('此操作将永久删除该分类, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      })
        .then(async () => {
          let res = await deleteKnowClass(items.id)
          if (res.success) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.getKnowClassList()
          } else {
            this.$message({
              type: 'error',
              message: '删除失败'
            })
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 新增知识
    async OpenAddKnow() {
      this.addKnowShow = true
      this.title = '新增知识'
      let result = await getKnowClassThree()
      if (result.success) {
        this.navDataList = result.data
      }
    },
    showAddKnow() {
      this.addKnowShow = false
    },
    // 新增知识接口
    async addKnowInfo(data) {
      let res = await addKnow(data)
      if (res.success) {
        this.$message({
          type: 'success',
          message: '新增知识成功!'
        })
        this.getKnowDataList()
      } else {
        this.$message({
          type: 'error',
          message: '新增知识失败!'
        })
      }
    },
    // 编辑知识
    openKnowDataInfo(row) {
      this.knowDataInfo = row
      this.addKnowShow = true
      this.title = '编辑知识'
    },
    // 编辑知识接口
    async editKnowData(data) {
      let res = await editKnow(data, data.id)
      if (res.success) {
        this.$message({
          type: 'success',
          message: '编辑知识成功!'
        })
        this.addKnowShow = false
        this.getKnowDataList()
      } else {
        this.$message({
          type: 'error',
          message: '编辑知识失败!'
        })
      }
    },
    // 删除知识
    deleteRow(row) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      })
        .then(async () => {
          let res = await deleteKnow(row.id)
          if (res.success) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.getKnowDataList()
          } else {
            this.$message({
              type: 'error',
              message: '删除失败'
            })
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    }
  }
}
</script>
<style lang="less" scoped>
.listData {
  padding: 16px;
  .grid-content {
    height: 95px;
    display: flex;
    align-items: center;
    justify-content: center;
    .grid_inner {
      text-align: center;
      .data_number {
        font-size: 32px;
        font-weight: 500;
        padding-bottom: 8px;
      }
    }
  }
}
.searchBox {
  margin: 0 16px;
  padding: 16px;
  background: #ffffff;
  border: 1px solid #c7cfcf;
  .searchTit {
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 16px;
  }
  .key_group {
    display: flex;
    .key_group_item {
      display: flex;
      align-items: center;
      margin-right: 24px;
      .key_tit {
        margin-right: 16px;
      }
    }
  }
}
.active {
  color: #09bebe;
}
.rightBtn {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 500;
}
.leftBox {
  border-right: #c7cfcf 0.5px solid;
  height: calc(100vh - 76px);
  .left_titBox {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .l_t_txt {
      font-size: 16px;
      font-weight: 500;
    }
    .l_t_btn {
      font-size: 14px;
      color: #09bebe;
      cursor: pointer;
    }
  }
  .left_contentBox {
    .l_c_items {
      padding: 16px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .l_c_i_dele {
        color: #be0909;
        display: none;
      }
      .l_c_i_edit {
        color: #09bebe;
        display: none;
        margin-right: 16px;
      }
      .l_c_i_btn {
        display: flex;
        justify-content: space-between;
      }
      .l_c_i_txt {
        display: flex;
        p {
          padding: 0;
          margin: 0;
        }
      }
    }
    .l_c_items:hover {
      background: #f2f3f4;
      .l_c_i_dele {
        display: block;
      }
      .l_c_i_edit {
        display: block;
      }
    }
  }
}
.list {
  padding: 16px;
  .pageBox {
    height: 60px;
    display: flex;
    justify-content: end;
    align-items: center;
  }
}
</style>
