<template>
  <el-container>
    <el-header class="header">待购保单</el-header>
    <el-main>
      <!-- 数据统计 -->
      <div class="listData">
        <el-row :gutter="8">
          <el-col :span="4">
            <div class="grid-content bg-purple" style="background: #e9fbfb">
              <div class="grid_inner">
                <div class="data_number">{{ userRoomTotal }}</div>
                <div>总户数(户)</div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- 精准查询 -->
      <div class="searchBox">
        <div class="searchTit">精准查询</div>
        <div class="key_group">
          <div class="key_group_item">
            <div class="key_tit">物业公司</div>
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </div>
          <div class="key_group_item">
            <div class="key_tit">所属地区</div>
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </div>
          <div class="key_group_item">
            <div class="key_tit">小区</div>
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </div>
          <div class="key_group_item">
            <div class="key_tit">房号</div>
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </div>
          <el-button type="primary">查询</el-button>
        </div>
      </div>
      <!-- 列表 -->
      <div class="list">
        <el-table :data="tableData.rows" style="width: 100%">
          <el-table-column prop="id" label="ID" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="propertyName" label="所属物业" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="propertyArea" label="所属地区" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="villageName" label="所属小区" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="propertyAddress" label="小区地址" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="floorName" label="房号" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <p>
                {{ scope.row.floorName }}号楼 {{ scope.row.unitName }}单元 {{ scope.row.layerName }}层
                {{ scope.row.roomName }}室
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="ownerName" label="业主姓名" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="serviceType" label="服务类型" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="servicePackage" label="服务套餐" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="serviceStatus" label="服务状态" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <p>
                {{ scope.row.serviceStatus == 1 ? '正常' : '异常' }}
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="equipmentStatus" label="设备状态" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <p>
                {{ scope.row.serviceStatus == 1 ? '正常' : '异常' }}
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="接入时间" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <p>
                {{ formatDate(scope.row.createTime) }}
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="dueDate" label="到期时间" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <p>
                {{ formatDate(scope.row.dueDate) }}
              </p>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="120">
            <template slot-scope="scope">
              <el-button @click.native.prevent="callbackRow(scope.$index, tableData)" type="text" size="small">
                电话
              </el-button>
              <el-button @click.native.prevent="openStayInsuranceShow(scope.row)" type="text" size="small">
                投保
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="pageBox">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-sizes="[5, 10, 20]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="tableData.total"
          >
          </el-pagination>
        </div>
      </div>
      <!-- 隐私电话弹框 -->
      <privacyCall :privacyCall="privacyCallShow" @closePrivacyCall="showPrivacyCall"></privacyCall>
      <!-- 投保 -->
      <stayInsurance
        :stayInsuranceRow="stayInsuranceRow"
        :stayInsurance="stayInsuranceShow"
        @closeStayInsurance="showStayInsurance"
        :stayInsuranceInfo="stayInsuranceInfo"
      ></stayInsurance>
    </el-main>
  </el-container>
</template>
<script>
import PrivacyCall from '@/components/public/privacyCall'
import StayInsurance from '@/components/public/stayInsurance'
import { queryFindByStayInsureAll, queryAddUserInsureInfo } from '@/api/insurance.js'
import { formatDate } from '@/utils/index.js'
export default {
  props: ['rescind', 'PrivacyCall'],
  components: {
    privacyCall: PrivacyCall,
    stayInsurance: StayInsurance
  },
  data() {
    return {
      rescindShow: false,
      privacyCallShow: false,
      stayInsuranceShow: false,
      formatDate: formatDate,
      query: {
        page: 1,
        pageSize: 10
      },
      options: [
        {
          value: '选项1',
          label: '黄金糕'
        },
        {
          value: '选项2',
          label: '双皮奶'
        },
        {
          value: '选项3',
          label: '蚵仔煎'
        },
        {
          value: '选项4',
          label: '龙须面'
        },
        {
          value: '选项5',
          label: '北京烤鸭'
        }
      ],
      value: '',
      tableData: [],
      // 获取需投保信息
      stayInsuranceRow: {},
      userRoomTotal: ''
    }
  },
  created() {
    this.init()
  },
  methods: {
    //初始化表格
    async init() {
      let result = await queryFindByStayInsureAll(this.query)
      if (result.success) {
        this.tableData = result.data
        this.userRoomTotal = result.data.total
      }
    },
    //分页-size
    handleSizeChange(val) {
      this.query.pageSize = val
      this.init()
    },
    //分页-page
    handleCurrentChange(val) {
      this.query.page = val
      this.init()
    },
    callbackRow(index) {
      this.privacyCallShow = true
    },
    showPrivacyCall() {
      this.privacyCallShow = false
    },
    openStayInsuranceShow(row) {
      this.stayInsuranceRow = row
      this.stayInsuranceShow = true
    },
    showStayInsurance() {
      this.stayInsuranceShow = false
    },
    // 提交投保信息
    async stayInsuranceInfo(data) {
      let result = await queryAddUserInsureInfo(data)
      console.log(result)
      if (result.success) {
        this.$message.success('保单提交成功')
        this.init()
      }
    }
  }
}
</script>
<style lang="less" scoped>
.header {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  line-height: 60px;
}
.listData {
  padding: 16px;
  .grid-content {
    height: 95px;
    display: flex;
    align-items: center;
    justify-content: center;
    .grid_inner {
      text-align: center;
      .data_number {
        font-size: 32px;
        font-weight: 500;
        padding-bottom: 8px;
      }
    }
  }
}
.searchBox {
  margin: 0 16px;
  padding: 16px;
  background: #ffffff;
  border: 1px solid #c7cfcf;
  .searchTit {
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 16px;
  }
  .key_group {
    display: flex;
    .key_group_item {
      display: flex;
      align-items: center;
      margin-right: 24px;
      .key_tit {
        margin-right: 16px;
      }
    }
  }
}
.list {
  padding: 16px;
  .pageBox {
    height: 60px;
    display: flex;
    justify-content: end;
    align-items: center;
  }
}
</style>
