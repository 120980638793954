<template>
  <el-container>
    <el-dialog
      :title="title"
      :visible.sync="addGoodsClass"
      width="20%"
      @close="close"
      append-to-body
      :close-on-click-modal="false"
    >
      <div class="content">
        <div class="input_item" :model="formData" ref="formData">
          <div class="item_vlaue">标题</div>
          <div class="item_key">
            <el-input v-model="formData.title" placeholder="请输入内容"></el-input>
          </div>
        </div>
        <div class="input_item" :model="formData" ref="formData">
          <div class="item_vlaue">一级分类名称</div>
          <div class="item_key">
            <el-select v-model="formData.oneLevelId" placeholder="请选择">
              <el-option v-for="item in oneLevelList" :key="item.id" :label="item.title" :value="item.id"> </el-option>
            </el-select>
          </div>
        </div>
        <div class="input_item" :model="formData" ref="formData">
          <div class="item_vlaue">二级分类名称</div>
          <div class="item_key">
            <el-select v-model="formData.twoLevelId" placeholder="请选择">
              <el-option v-for="item in twoLevelList" :key="item.id" :label="item.title" :value="item.id"> </el-option>
            </el-select>
          </div>
        </div>
        <div class="input_item" :model="formData" ref="formData">
          <div class="item_vlaue">三级分类名称</div>
          <div class="item_key">
            <el-select v-model="formData.threeLevelId" placeholder="请选择">
              <el-option v-for="item in threeLevelList" :key="item.id" :label="item.title" :value="item.id">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="input_item" :model="formData" ref="formData">
          <div class="item_vlaue">浏览量</div>
          <div class="item_key">
            <el-input v-model="formData.pageView" placeholder="请输入浏览量"></el-input>
          </div>
        </div>
        <div class="input_item" :model="formData" ref="formData">
          <div class="item_vlaue">显示终端</div>
          <div class="item_key">
            <el-radio v-model="formData.displayTerminal" label="0">用户</el-radio>
            <el-radio v-model="formData.displayTerminal" label="1">物业</el-radio>
            <el-radio v-model="formData.displayTerminal" label="2">运营</el-radio>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addGoodsClass = false">取 消</el-button>
        <el-button type="primary" @click="getaddGoodsClassInfo()">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>
<script>
export default {
  props: ['addGoodsClass', 'addGoodsClassInfo', 'title', 'goodsClassDataInfo', 'editGoodsClass', 'navDataList'],
  data() {
    return {
      formData: {
        id: null,
        title: null,
        oneLevelId: null,
        twoLevelId: null,
        threeLevelId: null,
        pageView: null,
        displayTerminal: null
      },
      addGoodsClassShow: this.addGoodsClass,
      classKnowList: [],
      oneLevelList: [],
      twoLevelList: [],
      threeLevelList: []
    }
  },
  watch: {
    navDataList() {
      var oneList = []
      var twoList = []
      var threeList = []
      this.navDataList.forEach((element) => {
        var oneJson = {
          id: element.id,
          title: element.title
        }
        oneList.push(oneJson)
        element.children.forEach((a) => {
          var twoJson = {
            id: a.id,
            title: a.title
          }
          twoList.push(twoJson)
          a.children.forEach((a) => {
            var threeJson = {
              id: a.id,
              title: a.title
            }
            threeList.push(threeJson)
          })
        })
      })
      this.oneLevelList = oneList
      this.twoLevelList = twoList
      this.threeLevelList = threeList
    },
    goodsClassDataInfo() {
      this.formData.title = this.goodsClassDataInfo.title
      this.formData.displayTerminal = this.goodsClassDataInfo.displayTerminal
      this.formData.id = this.goodsClassDataInfo.id
      this.formData.oneLevelId = this.goodsClassDataInfo.oneLevelId
      this.formData.twoLevelId = this.goodsClassDataInfo.twoLevelId
      this.formData.threeLevelId = this.goodsClassDataInfo.threeLevelId
      this.formData.pageView = this.goodsClassDataInfo.pageView
    }
  },
  methods: {
    close() {
      this.$emit('closeAddGoodsClass')
      this.formData.title = null
      this.formData.displayTerminal = null
      this.formData.id = null
      this.formData.oneLevelId = null
      this.formData.twoLevelId = null
      this.formData.threeLevelId = null
      this.formData.pageView = null
    },
    async getaddGoodsClassInfo() {
      if (this.title == '新增分类') {
        this.addGoodsClassInfo(this.formData)
        this.addGoodsClass = false
        this.formData.title = null
        this.formData.displayTerminal = null
        this.formData.id = null
        this.formData.oneLevelId = null
        this.formData.twoLevelId = null
        this.formData.threeLevelId = null
        this.formData.pageView = null
      } else if (this.title == '编辑分类') {
        this.editGoodsClass(this.formData)
        this.addGoodsClass = false
        this.formData.title = null
        this.formData.displayTerminal = null
        this.formData.id = null
        this.formData.oneLevelId = null
        this.formData.twoLevelId = null
        this.formData.threeLevelId = null
        this.formData.pageView = null
      }
    }
  }
}
</script>
<style lang="less" scoped>
.input_item {
  padding: 8px 0;
  .item_vlaue {
    padding-bottom: 8px;
  }
  .select {
    width: 100%;
  }
}
</style>
