import request from '@/utils/request'

// 设备公司管理 - 公司列表分页查询
export function deviceCompanyPageList(data) {
    return request({
        url: '/sys/deviceBusiness',
        method: 'get',
        params:data
        
    })
}
// 设备列表 - 查询公司列表不分页
export function deviceCompanyList(data) {
    return request({
        url: '/sys/deviceBusiness/list',
        method: 'get',
        params:data
        
    })
    }
// 设备公司管理 - 添加公司
export function addDeviceInfo(data) {
    return request({
        url: '/sys/deviceBusiness',
        method: 'POST',
        data:data
    }) 
}
// 设备公司管理 - 编辑公司
export function editDeviceInfo(data,id) {
    return request({
        url: '/sys/deviceBusiness/'+ id,
        method: 'PUT',
        data:data
    }) 
}
// 设备公司管理 - 删除
export function delDeviceCompany(id) {
    return request({
        url: '/sys/deviceBusiness/' + id,
        method: 'delete',
    })
}
  // 设备公司管理 - 根据id查询
  export function getDeviceBusinessId(id) {
    return request({
        url: '/sys/deviceBusiness/' + id,
        method: 'get',
    })
  }
// 设备列表管理 - 根据公司查询
export function deviceGoodsById(data,id) {
    return request({
        url: '/sys/deviceGoods/list/' + id,
        method: 'get',
        params:data
        
    })
}
  // 设备列表管理 - 添加
export function addDeviceGoods(data) {
    return request({
        url: '/sys/deviceGoods',
        method: 'POST',
        data:data
    }) 
}
  // 设备列表管理 - 根据id查询
  export function getDeviceGoods(id) {
    return request({
        url: '/sys/insuranceGoods/' + id,
        method: 'get',
    })
  }
  // 设备列表管理 - 删除
  export function delDeviceGoods(id) {
    return request({
        url: '/sys/deviceGoods/' + id,
        method: 'delete',
    })
  }
  
  // 设备列表管理 - 更新
  export function updateDeviceGoods(data) {
    return request({
        url: '/sys/deviceGoods/'+ data.id,
        method: 'put',
        data:data
  
    })
  }
// 查询智能电话列表
export function queryPhoneList(data) {
    return request({
        url: '/sys/smartPhone/list',
        method: 'POST',
        data:data
    }) 
}
// 查询智能设备列表
export function queryDeviceList(data) {
    console.log(data)
    return request({
        url: '/sys/smartDevice/list',
        method: 'POST',
        data:data
    })
}
// 根据roomId查询智能电话列表
export function queryRoomIdPhoneList(roomId) {
    return request({
        url: '/sys/smartPhone/' + roomId,
        method: 'get',
    })
}
// 查询roomId智能设备列表
export function queryRoomIdDeviceList(roomId,data) {
    console.log(data)
    return request({
        url: '/sys/smartDevice/list/'+roomId ,
        method: 'POST',
        data:data
    })
}