<template>
  <el-container>
    <el-dialog
      :title="title"
      :visible.sync="addTagsClass"
      width="20%"
      @close="close"
      append-to-body
      :close-on-click-modal="false"
    >
      <div class="content">
        <div class="input_item" :model="formData" ref="formData">
          <div class="item_vlaue">标题</div>
          <div class="item_key">
            <el-input v-model="formData.title" placeholder="请输入内容"></el-input>
          </div>
        </div>
        <div class="input_item" :model="formData" ref="formData">
          <div class="item_vlaue">类型</div>
          <div class="item_key">
            <el-input v-model="formData.type" placeholder="0 标签 1 功效"></el-input>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addTagsClass = false">取 消</el-button>
        <el-button type="primary" @click="getaddTagsClassInfo()">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>
<script>
export default {
  props: ['addTagsClass', 'addTagsClassInfo', 'title', 'tagsClassDataInfo', 'editTagsClass'],
  data() {
    return {
      formData: {
        id: '',
        type: null,
        title: ''
      },
      addTagsClassShow: this.addTagsClass
    }
  },
  watch: {
    tagsClassDataInfo() {
      this.formData.title = this.tagsClassDataInfo.title
      this.formData.id = this.tagsClassDataInfo.id
      this.formData.type = this.tagsClassDataInfo.type
    }
  },
  methods: {
    close() {
      this.$emit('closeAddTagsClass')
      this.formData.title = ''
      this.formData.id = ''
    },
    async getaddTagsClassInfo() {
      if (this.title == '新增分类') {
        this.addTagsClassInfo(this.formData)
        this.addTagsClass = false
        this.formData.title = ''
        this.formData.id = ''
      } else if (this.title == '编辑分类') {
        this.editTagsClass(this.formData)
        this.addTagsClass = false
        this.formData.title = ''
        this.formData.id = ''
      }
    }
  }
}
</script>
<style lang="less" scoped>
.input_item {
  padding: 8px 0;
  .item_vlaue {
    padding-bottom: 8px;
  }
  .select {
    width: 100%;
  }
}
</style>
