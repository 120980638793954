import request from '@/utils/request'

// 菏康 - 查询全部列表
export function queryHkFoodsItemsList() {
    return request({
        url: '/sys/hkFoodsItems/findAll',
        method: 'get',
    })
}
// 新增分类
export function addFoodsClass(data) {
    return request({
        url: '/sys/hkFoodsItems',
        method: 'post',
        data: data
    })
}
// 编辑分类
export function putEditFoodsClass(data, id) {
    return request({
        url: '/sys/hkFoodsItems/' + id,
        method: 'put',
        data: data
    })
}
// 删除分类
export function deleteFoodsClass(id) {
    return request({
        url: '/sys/hkFoodsItems/' + id,
        method: 'delete',
    })
}
// 根据分类获取列表
export function getHkFoodsByItemsPageFindAll(id, data) {
    return request({
        url: '/sys/hkFoods/findByClassItemPageFindAll/' + id,
        method: 'get',
        params: data
    })
}
// 新增
export function addFoods(data) {
    return request({
        url: '/sys/hkFoods',
        method: 'post',
        data: data
    })
}
// 编辑
export function editFoods(data, id) {
    return request({
        url: '/sys/hkFoods/' + id,
        method: 'put',
        data: data
    })
}
// 删除
export function deleteFoods(id) {
    return request({
        url: '/sys/hkFoods/' + id,
        method: 'delete'
    })
}

// 不分页获取标签列表
export function getTagsFindAll() {
    return request({
        url: '/sys/hkFoodsTags/findAll',
        method: 'get',
    })
}
// 新增
export function addFoodsTags(data) {
    return request({
        url: '/sys/hkFoodsTags',
        method: 'post',
        data: data
    })
}
// 编辑
export function editFoodsTags(data, id) {
    return request({
        url: '/sys/hkFoodsTags/' + id,
        method: 'put',
        data: data
    })
}
// 删除
export function deleteFoodsTags(id) {
    return request({
        url: '/sys/hkFoodsTags/' + id,
        method: 'delete'
    })
}