<template>
  <el-container>
    <el-header class="header">
      <div>服务商配置</div>
      <div><el-button type="primary" @click="addSpSetInfo">新增配置</el-button></div>
    </el-header>
    <el-main>
      <!-- 列表 -->
      <div class="list">
        <el-table :data="tableData.rows" style="width: 100%">
          <el-table-column prop="id" label="ID" :show-overflow-tooltip="true" width="240"> </el-table-column>
          <el-table-column prop="setJson" label="详情" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column fixed="right" label="操作" width="120">
            <template slot-scope="scope">
              <el-button @click="updateSpSetInfo(scope.row)" type="text" size="small"> 编辑 </el-button>
              <el-popconfirm title="确定删除此账号吗？" @confirm="delSpSetInfo(scope.row)">
                <el-button slot="reference" type="text" size="small"> 删除</el-button>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
        <div class="pageBox">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-sizes="[5, 10, 20]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="tableData.total"
          >
          </el-pagination>
        </div>
      </div>
      <!-- 新增/编辑弹框 -->
      <addSpSet
        :addSpSet="addSpSetShow"
        :title="title"
        @closeAddSpSet="showAddSpSet"
        :spSetInfo="spSetInfo"
        :openEditSpSet="openEditSpSet"
        :openAddSpSet="openAddSpSet"
      ></addSpSet>
    </el-main>
  </el-container>
</template>
<script>
import AddSpSet from '@/components/serviceProvider/addSpSet'
import { spSetFindAll, spSetAdd, spSetPUT, spSetDelete } from '@/api/serviceProvider.js'
export default {
  components: {
    AddSpSet: AddSpSet
  },
  data() {
    return {
      query: {
        page: 1,
        pageSize: 10
      },
      tableData: [],
      addSpSetShow: false,
      spSetInfo: null,
      title: null
    }
  },
  created() {
    this.init()
  },
  mounted() {},
  methods: {
    //初始化表格
    async init() {
      let result = await spSetFindAll(this.query)
      if (result.success) {
        this.tableData = result.data
      }
    },
    //分页-size
    handleSizeChange(val) {
      this.query.pageSize = val
      this.init()
    },
    //分页-page
    handleCurrentChange(val) {
      this.query.page = val
      this.init()
    },
    showAddSpSet() {
      this.addSpSetShow = false
    },
    // 新增
    addSpSetInfo() {
      this.title = '新增'
      this.addSpSetShow = true
    },
    //新增回调
    async openAddSpSet(data) {
      let res = await spSetAdd(data)
      if (res.success) {
        this.init()
      } else {
        this.$message.error('新增失败！')
      }
    },
    //编辑
    updateSpSetInfo(row) {
      this.spSetInfo = row
      this.addSpSetShow = true
      this.title = '编辑'
    },
    // 编辑回调
    async openEditSpSet(data) {
      let res = await spSetPUT(data)
      if (res.success) {
        this.init()
      } else {
        this.$message.error('编辑失败！')
      }
    },
    // 删除
    async delSpSetInfo(data) {
      let res = await spSetDelete(data)
      if (res.success) {
        this.init()
      } else {
        this.$message.error('删除失败！')
      }
    }
  }
}
</script>
<style lang="less" scoped>
.header {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  line-height: 60px;
  display: flex;
  justify-content: space-between;
}
.searchBox {
  margin: 0 16px;
  padding: 16px;
  background: #ffffff;
  border: 1px solid #c7cfcf;
  .searchTit {
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 16px;
  }
  .key_group {
    display: flex;
    .key_group_item {
      display: flex;
      align-items: center;
      margin-right: 24px;
      .key_tit {
        margin-right: 16px;
      }
    }
  }
}
.list {
  padding: 16px;
  .pageBox {
    height: 60px;
    display: flex;
    justify-content: end;
    align-items: center;
  }
}
.el-avatar {
  background: #f2f4f4;
}
</style>
