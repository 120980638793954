
 const session = {
    setSession : (value)=>{
        sessionStorage.setItem('session',JSON.stringify(value))
    },
    getSession : () =>{
        return sessionStorage.getItem('session') ? JSON.parse(sessionStorage.getItem('session')) : null
    }

}

export default session
