<template>
  <el-container>
    <el-dialog
      :title="title"
      :visible.sync="addDeviceGoods"
      width="50%"
      @close="close"
      append-to-body
      :close-on-click-modal="false"
    >
      <div class="content">
        <el-form ref="formData" :model="formData" label-width="80px">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="公司名称:" v-if="this.title == '新增'">
                <el-select v-model="formData.deviceBusId" placeholder="请选择">
                  <el-option
                    v-for="item in deviceCompanyList"
                    :key="item.id"
                    :label="item.deviceBusiness"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="公司名称:" v-if="this.title == '编辑'">
                <el-input v-model="bankAccount" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="设备名称:">
                <el-input v-model="formData.deviceName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="设备品牌:">
                <el-input v-model="formData.deviceBrand"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="设备类型:">
                <el-select v-model="formData.deviceType" placeholder="请选择">
                  <el-option
                    v-for="item in deviceTypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="设备指令:">
                <el-select v-model="formData.deviceInstruct" placeholder="请选择">
                  <el-option
                    v-for="item in deviceInstructList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.code"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="设备颜色:">
                <el-input v-model="formData.deviceColor"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="设备价值:">
                <div class="formItem">
                  <el-input v-model="formData.eamount"></el-input>
                  <p>元</p>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="使用费用:">
                <el-select v-model="formData.usageCost" placeholder="请选择">
                  <el-option v-for="item in costTypes" :key="item.value" :label="item.label" :value="item.label">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="货号:">
                <el-input v-model="formData.model"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="型号:">
                <el-input v-model="formData.articleNo"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="证书:">
                <el-input v-model="formData.certificate"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDeviceGoods = false">取 消</el-button>
        <el-button type="primary" @click="getAddInsuranceInfo()">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>
<script>
import { getDeviceBusinessId } from '@/api/device.js'
export default {
  props: ['addDeviceGoods', 'addDeviceInfo', 'deviceGoodsDataInfo', 'title', 'editDeviceGoodsData', 'companyList'],
  data() {
    return {
      deviceCompanyList: [],
      bankAccount: '',
      formData: {
        id: '',
        deviceBusId: '',
        deviceName: '',
        deviceBrand: '',
        deviceColor: '',
        deviceType: '',
        deviceInstruct: '',
        eamount: '',
        usageCost: '',
        model: '',
        articleNo: '',
        certificate: ''
      },
      costTypes: [
        {
          value: '选项1',
          label: '原价提供'
        },
        {
          value: '选项2',
          label: '免费提供'
        }
      ],
      deviceTypeList:[{
        value:0,
        label:'电磁阀'
      },{
        value:1,
        label:'机械手'
      }],
      deviceInstructList:[{
        value:0,
        label:'电磁阀关闭指令',
        code:'CLOSEVALVE'
      },{
        value:1,
        label:'机械手关闭指令',
        code:'CLOSEMACHHAND'
      },{
        value:2,
        label:'机械手打开指令',
        code:'OPENMACHHAND'
      }]
    }
  },
  watch: {
    companyList() {
      this.deviceCompanyList = this.companyList
    },
    async deviceGoodsDataInfo() {
      this.formData = this.deviceGoodsDataInfo
      if (this.title == '编辑') {
        let res = await getDeviceBusinessId(this.formData.deviceBusId)
        this.bankAccount = res.data.bankAccount
      }
      this.formData.id = this.deviceGoodsDataInfo.id
    },
    title() {
      if (this.title == '新增') {
        this.formData.deviceBusId = ''
        this.formData.deviceName = ''
        this.formData.deviceBrand = ''
        this.formData.deviceColor = ''
        this.formData.eamount = ''
        this.formData.usageCost = ''
        this.formData.model = ''
        this.formData.articleNo = ''
        this.formData.certificate = ''
        this.formData.deviceType = ''
        this.formData.deviceInstruct = ''
      }
    }
  },
  methods: {
    close() {
      this.$emit('closeAddDevice')
    },
    // 提交表单
    async getAddInsuranceInfo() {
      if (this.title == '新增') {
        this.addDeviceInfo(this.formData)
        this.addDeviceGoods = false
      } else if (this.title == '编辑') {
        this.editDeviceGoodsData(this.formData)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.el-select {
  width: 100%;
}
.el-date-editor.el-input {
  width: 100%;
}
.formItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    width: 40px;
    padding: 0;
    padding-left: 8px;
    margin: 0;
  }
}
</style>
