<template>
  <el-container>
    <el-header class="header">
      <div class="leftTit">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item class="l_tit" :to="{ path: '/pfileList' }">物业档案列表</el-breadcrumb-item>
          <el-breadcrumb-item class="l_tit">{{propertyRecord.villageName}}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="rightBtn">
        <el-button type="primary" @click="openContract">查看合同</el-button>
        <!-- <el-button type="danger" @click="OpenRescind">解约</el-button> -->
      </div>
    </el-header>
    <!-- 打开查看合同弹框 -->
    <lookContract :lookContract="lookContractShow" @closeLookContract="showLookContract"></lookContract>
    <!-- 打开解约弹框 -->
    <rescind :rescind="rescindShow" @closeRescind="showRescind()"></rescind>
    <!-- 打开营业执照弹框 -->
    <businessLicense :businessLicense="businessLicenseShow" @closeBusinessLicense="showBusinessLicense"></businessLicense>
    <el-main>
      <!-- 基本信息 -->
      <div class="baseInfo">
        <div class="baseInfo_tit">
          <div>基本信息</div>
        </div>
        <el-row class="baseInfo_group">
          <el-col :span="4">
            <div class="grid-content bg-purple">
              <div class="grid_vlaue">小区名称：</div>
              <div class="grid_key">{{propertyRecord.villageName}}</div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple">
              <div class="grid_vlaue">所属地区：</div>
              <div class="grid_key">{{propertyRecord.propertyArea}}</div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple">
              <div class="grid_vlaue">详细地址：</div>
              <div class="grid_key">{{propertyRecord.propertyAddress}}</div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple">
              <div class="grid_vlaue">物业名称：</div>
              <div class="grid_key">{{propertyRecord.propertyName}}</div>
            </div>
          </el-col>
        </el-row>
        <el-row class="baseInfo_group">
          <el-col :span="4">
            <div class="grid-content bg-purple">
              <div class="grid_vlaue">负责人：</div>
              <div class="grid_key">{{propertyRecord.contactPerson}}</div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple">
              <div class="grid_vlaue">联系电话：</div>
              <div class="grid_key">{{propertyRecord.propertyPhone}}</div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple">
              <div class="grid_vlaue">营业执照：</div>
              <div class="grid_key">
                <el-link type="primary" @click="OpenBusinessLicense">点击查看</el-link>
              </div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple">
              <div class="grid_vlaue">信用代码：</div>
              <div class="grid_key">{{propertyRecord.businessLicenseId}}</div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple">
              <div class="grid_vlaue">法人姓名：</div>
              <div class="grid_key">{{propertyRecord.legalRepresentative}}</div>
            </div>
          </el-col>
        </el-row>
        <el-row class="baseInfo_group">
          <el-col :span="4">
            <div class="grid-content bg-purple">
              <div class="grid_vlaue">建设时间：</div>
              <div class="grid_key">{{propertyRecord.constructionTime}}</div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple">
              <div class="grid_vlaue">占地面积：</div>
              <div class="grid_key">{{propertyRecord.areaCovered}}</div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple">
              <div class="grid_vlaue">总楼栋：</div>
              <div class="grid_key">{{propertyRecord.generalBuilding}}</div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple">
              <div class="grid_vlaue">总户数：</div>
              <div class="grid_key">{{propertyRecord.totalHouseholds}}</div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple">
              <div class="grid_vlaue">开发商：</div>
              <div class="grid_key">{{propertyRecord.developers}}</div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple">
              <div class="grid_vlaue">行政用房：</div>
              <div class="grid_key">{{propertyRecord.adminHousing}}</div>
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- 合作信息 -->
      <div class="cooperationInfo">
        <div class="cooperationInfo_tit">合同信息</div>
        <el-row class="cooperationInfo_group">
          <el-col :span="4">
            <div class="grid-content bg-purple">
              <div class="grid_vlaue">合同编号：</div>
              <div class="grid_key">{{contractData.contractNo}}</div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple">
              <div class="grid_vlaue">合同期限：</div>
              <div class="grid_key">{{contractData.term}}</div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple">
              <div class="grid_vlaue">开始日期：</div>
              <div class="grid_key">{{contractData.startTime}}</div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple">
              <div class="grid_vlaue">到期日期：</div>
              <div class="grid_key">{{contractData.endTime}}</div>
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- 列表 -->
      <div class="list">
        <div class="list_titBox">
          <div class="l_tit">房屋管理</div>
          <div class="l_dec">共计{{roomTotal}}户</div>
        </div>
        <el-table ref="multipleTable" :data="tableData.rows" tooltip-effect="dark" style="width: 100%">
          <el-table-column type="selection" width="55">
          </el-table-column>
          <el-table-column prop="id" label="ID" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="floorName" label="楼号" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="unitName" label="单元" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="layerName" label="楼层" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="roomName" label="房号" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="builArea" label="建筑面积(m2)" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="houseStatus" label="户型" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="accessStatus" label="接入状态" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <p v-if="scope.row.accessStatus == 0 || scope.row.accessStatus == null" :style='{color:color}'>
                未接入
              </p>
              <p v-if="scope.row.accessStatus == 1">
                已接入
              </p>
            </template>
          </el-table-column>
        </el-table>
        <div class="pageBox">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-sizes="[5,10,20]" layout="total, sizes, prev, pager, next, jumper" :total="tableData.total">
          </el-pagination>
        </div>
      </div>
    </el-main>
  </el-container>
</template>
<script>
import LookContract from '@/components/property/lookContract'
import Rescind from '@/components/property/rescind'
import BusinessLicense from '@/components/property/businessLicense'
import { mapGetters } from 'vuex'
import { queryContractByID, queryHouseList } from '@/api/property.js'

export default {
  props: ['rescind', 'lookContract', 'businessLicense'],
  components: {
    lookContract: LookContract,
    rescind: Rescind,
    businessLicense: BusinessLicense
  },
  data () {
    return {
      lookContractShow: false,
      rescindShow: false,
      businessLicenseShow: false,
      query: {
        page: 1,
        pageSize: 10,
      },
      tableData: [],
      roomTotal: '',
      contractData: {} //合同信息
    }
  },
  computed: {
    ...mapGetters(['propertyRecord'])
  },
  created () {
    this.init()
  },
  methods: {
    //初始化表格
    init () {
      this.getContract()
      this.getHouseList()
    },
    async getContract () {
      let result = await queryContractByID(this.propertyRecord.id)
      if (result.success) {
        this.contractData = result.data
      }
    },
    async getHouseList () {
      const params = {
        ...this.query,
        id: this.propertyRecord.id
      }
      let result = await queryHouseList(params)
      if (result.success) {
        this.tableData = result.data
        this.roomTotal = result.data.total
      }
    },
    //分页-size
    handleSizeChange (val) {
      this.query.pageSize = val
      this.init()
    },
    //分页-page
    handleCurrentChange (val) {
      this.query.page = val
      this.init()
    },
    showEditProperty () {
      this.editPropertyShow = false
    },
    OpenEditProperty () {
      this.editPropertyShow = true
    },
    showLookContract () {
      this.lookContractShow = false
    },
    openContract () {
      this.lookContractShow = true
    },
    showrescind () {
      this.rescindShow = false
    },
    OpenRescind () {
      this.rescindShow = true
    },
    showBusinessLicense () {
      this.businessLicenseShow = false
    },
    OpenBusinessLicense () {
      this.businessLicenseShow = true
    }
  }
}
</script>
<style lang="less" scoped>
.header {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  line-height: 60px;
  display: flex;
  justify-content: space-between;
  .leftTit {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .l_tit {
      font-size: 16px;
      font-weight: 500;
    }
  }
}
.baseInfo {
  margin: 16px;
  padding: 16px;
  background: #ffffff;
  border: 1px solid #c7cfcf;
  .baseInfo_tit {
    font-weight: 500;
    padding-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .baseInfo_group {
    padding: 8px 0;
  }
  .grid-content {
    display: flex;
  }
}
.cooperationInfo {
  margin: 16px;
  padding: 16px;
  background: #ffffff;
  border: 1px solid #c7cfcf;
  .cooperationInfo_tit {
    font-weight: 500;
    padding-bottom: 16px;
  }
  .cooperationInfo_group {
    padding: 8px 0;
  }
  .grid-content {
    display: flex;
  }
}
.list {
  padding: 16px;
  .list_titBox {
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .l_tit {
      font-size: 16px;
      font-weight: 500;
    }
  }
  .pageBox {
    height: 60px;
    display: flex;
    justify-content: end;
    align-items: center;
  }
}
</style>
