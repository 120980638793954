<template>
  <el-container>
    <el-header class="header">
      <div class="h_txt">保险公司</div>
      <el-button type="primary" @click="openAddCompany">新增公司</el-button>
    </el-header>
    <el-main>
      <!-- 数据统计 -->
      <div class="listData">
        <el-row :gutter="8">
          <el-col :span="4">
            <div class="grid-content bg-purple" style="background: #e9fbfb">
              <div class="grid_inner">
                <div class="data_number">{{ insuranceCompanyTotal }}</div>
                <div>保险公司</div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- 精准查询 -->
      <div class="searchBox">
        <div class="searchTit">精准查询</div>
        <div class="key_group">
          <div class="key_group_item">
            <div class="key_tit">物业公司</div>
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </div>
          <div class="key_group_item">
            <div class="key_tit">所属地区</div>
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </div>
          <div class="key_group_item">
            <div class="key_tit">小区</div>
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </div>
          <div class="key_group_item">
            <div class="key_tit">房号</div>
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </div>
          <el-button type="primary">查询</el-button>
        </div>
      </div>
      <!-- 列表 -->
      <div class="list">
        <el-table :data="tableData.rows" style="width: 100%">
          <el-table-column prop="id" label="ID" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="companyName" label="公司名称" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="companyWeb" label="公司官网" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="companyPhone" label="官方电话" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="area" label="所属地区" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="dockName" label="对接人姓名" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="telephone" label="对接人电话" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="startTime" label="合作开始时间" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <p>
                {{ formatDate(scope.row.startTime) }}
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="创建时间" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <p>
                {{ formatDate(scope.row.createTime) }}
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="updateTime" label="更新时间" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <p>
                {{ formatDate(scope.row.updateTime) }}
              </p>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="120">
            <template slot-scope="scope">
              <el-button @click.native.prevent="openEditInsurCompany(scope.row)" type="text" size="small">
                编辑
              </el-button>
              <el-button @click.native.prevent="openDelInsurCompany(scope.row)" type="text" size="small">
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="pageBox">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-sizes="[5, 10, 20]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="tableData.total"
          >
          </el-pagination>
        </div>
      </div>
      <!-- 新增/编辑 -->
      <addInsurance
        :addInsurance="addInsuranceShow"
        :title="title"
        @closeAddInsurance="showAddInsurance"
        :addInsuranceInfo="addInsuranceInfo"
        :insuranceCompanyDataInfo="insuranceCompanyDataInfo"
        :editInsuranceCompanyData="editInsuranceCompanyData"
      ></addInsurance>
    </el-main>
  </el-container>
</template>
<script>
import AddInsurance from '@/components/insurance/addInsurance'
import {
  insuranceCompanyPageList,
  addInsuranceCompany,
  delInsuranceCompany,
  updateInsuranceCompany
} from '@/api/insurance.js'
import { formatDate } from '@/utils/index.js'
export default {
  components: {
    addInsurance: AddInsurance
  },
  data() {
    return {
      addInsuranceShow: false,
      insuranceCompanyDataInfo: '',
      formatDate: formatDate,
      tableData: [],
      insuranceCompanyTotal: '',
      query: {
        page: 1,
        pageSize: 10
      },
      title: '',
      options: [],
      value: ''
    }
  },
  created() {
    this.init()
  },
  methods: {
    //初始化表格
    async init() {
      let result = await insuranceCompanyPageList(this.query)
      if (result.success) {
        this.tableData = result.data
        this.insuranceCompanyTotal = result.data.total
      }
    },
    //分页-size
    handleSizeChange(val) {
      this.query.pageSize = val
      this.init()
    },
    //分页-page
    handleCurrentChange(val) {
      this.query.page = val
      this.init()
    },
    openAddCompany() {
      this.addInsuranceShow = true
      this.title = '新增'
    },
    // 新增接口
    async addInsuranceInfo(data) {
      let res = await addInsuranceCompany(data)
      if (res.success) {
        this.$message({
          type: 'success',
          message: '新增成功!'
        })
        this.init()
      } else {
        this.$message({
          type: 'error',
          message: '新增失败!'
        })
      }
    },
    showAddInsurance() {
      this.addInsuranceShow = false
    },
    // 编辑
    openEditInsurCompany(items) {
      this.addInsuranceShow = true
      this.insuranceCompanyDataInfo = items
      this.title = '编辑'
    },
    // 编辑接口
    async editInsuranceCompanyData(data) {
      let res = await updateInsuranceCompany(data, data.id)
      if (res.success) {
        this.$message({
          type: 'success',
          message: '编辑成功!'
        })
        let result = await insuranceCompanyPageList(this.query)
        if (result.success) {
          this.tableData = result.data
        }
        this.addInsuranceShow = false
      } else {
        this.$message({
          type: 'error',
          message: '编辑失败!'
        })
      }
    },
    // 删除
    async openDelInsurCompany(items) {
      this.$confirm('此操作将永久删除该分类, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      })
        .then(async () => {
          let res = await delInsuranceCompany(items.id)
          if (res.success) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.init()
          } else {
            this.$message({
              type: 'error',
              message: '删除失败'
            })
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    }
  }
}
</script>
<style lang="less" scoped>
.header {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .h_txt {
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    line-height: 60px;
  }
}
.listData {
  padding: 16px;
  .grid-content {
    height: 95px;
    display: flex;
    align-items: center;
    justify-content: center;
    .grid_inner {
      text-align: center;
      .data_number {
        font-size: 32px;
        font-weight: 500;
        padding-bottom: 8px;
      }
    }
  }
}
.searchBox {
  margin: 0 16px;
  padding: 16px;
  background: #ffffff;
  border: 1px solid #c7cfcf;
  .searchTit {
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 16px;
  }
  .key_group {
    display: flex;
    .key_group_item {
      display: flex;
      align-items: center;
      margin-right: 24px;
      .key_tit {
        margin-right: 16px;
      }
    }
  }
}
.list {
  padding: 16px;
  .pageBox {
    height: 60px;
    display: flex;
    justify-content: end;
    align-items: center;
  }
}
</style>
