<template>
  <el-container>
    <el-main>
      <el-row>
        <!-- 列表 -->
        <div class="minBox">
          <div class="rightBtn">
            <div class="leftTit">引导页管理</div>
            <el-button type="primary" @click="OpenAddAppOperateImg">新增引导页</el-button>
          </div>
          <el-col class="list" :span="24">
            <el-table :data="tableData" style="width: 100%">
              <el-table-column prop="id" label="ID" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="appType" label="APP类型" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <p>
                    {{
                      scope.row.appType == '0'
                        ? '用户中心'
                        : scope.row.appType == '1'
                        ? '物业中心'
                        : scope.row.appType == '2'
                        ? '运营中心'
                        : '其它'
                    }}
                  </p>
                </template>
              </el-table-column>
              <el-table-column prop="imgType" label="图片说明" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="imgUrl" label="图片" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <el-image style="width: 100px; height: 100px" :src="scope.row.imgUrl"> </el-image>
                </template>
              </el-table-column>
              <el-table-column prop="baColor" label="图片环境颜色" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="createTime" label="创建时间" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column fixed="right" label="操作" width="120">
                <template slot-scope="scope">
                  <el-button @click.native.prevent="uploadOperateImg(scope.row)" type="text" size="small">
                    编辑
                  </el-button>
                  <el-button @click.native.prevent="openDeleteOperateImg(scope.row)" type="text" size="small">
                    删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </div>
      </el-row>
      <appOerateImg
        :addOperateImg="addOperateImgShow"
        :title="title"
        @closeAddOperateImg="showAddOperateImg"
        :addOperateImgFormData="addOperateImgFormData"
        :appOperateDataInfo="appOperateDataInfo"
        :editOperateInfo="editOperateInfo"
      ></appOerateImg>
    </el-main>
  </el-container>
</template>
<script>
import { allAppOperateImg, addAppOperateImg, deleteAppOperateImg, uploadOperateImg } from '@/api/appOperate.js'
import AppOerateImg from '@/components/appOperate/appOperateImg'
export default {
  components: {
    AppOerateImg: AppOerateImg
  },
  data() {
    return {
      addOperateImgShow: false,
      appOperateDataInfo: {},
      tableData: [],
      title: '',
      query: {
        page: 1,
        pageSize: 10
      }
    }
  },
  mounted() {
    this.getallAppOperateImg()
  },
  methods: {
    //获取
    async getallAppOperateImg() {
      let result = await allAppOperateImg(this.query)
      if (result.success) {
        this.tableData = result.data.rows
      }
    },
    // 新增
    async OpenAddAppOperateImg() {
      this.addOperateImgShow = true
      this.title = '新增图片'
    },
    showAddOperateImg() {
      this.addOperateImgShow = false
    },
    // 新增接口
    async addOperateImgFormData(data) {
      let res = await addAppOperateImg(data)
      if (res.success) {
        this.$message({
          type: 'success',
          message: '新增成功!'
        })
      } else {
        this.$message({
          type: 'error',
          message: '新增失败!'
        })
      }
      this.getallAppOperateImg()
    },
    // 去编辑
    uploadOperateImg(row) {
      this.addOperateImgShow = true
      this.title = '编辑图片'
      this.appOperateDataInfo = row
    },
    // 编辑分类接口
    async editOperateInfo(data) {
      let res = await uploadOperateImg(data.id, data)
      if (res.success) {
        this.$message({
          type: 'success',
          message: '编辑成功!'
        })
        this.addOperateImgShow = false
        this.getallAppOperateImg()
      } else {
        this.$message({
          type: 'error',
          message: '编辑失败!'
        })
      }
    },
    // 删除
    openDeleteOperateImg(row) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      })
        .then(async () => {
          let res = await deleteAppOperateImg(row.id)
          if (res.success) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.getallAppOperateImg()
          } else {
            this.$message({
              type: 'error',
              message: '删除失败'
            })
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    }
  }
}
</script>
<style lang="less" scoped>
.listData {
  padding: 16px;
  .grid-content {
    height: 95px;
    display: flex;
    align-items: center;
    justify-content: center;
    .grid_inner {
      text-align: center;
      .data_number {
        font-size: 32px;
        font-weight: 500;
        padding-bottom: 8px;
      }
    }
  }
}
.searchBox {
  margin: 0 16px;
  padding: 16px;
  background: #ffffff;
  border: 1px solid #c7cfcf;
  .searchTit {
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 16px;
  }
  .key_group {
    display: flex;
    .key_group_item {
      display: flex;
      align-items: center;
      margin-right: 24px;
      .key_tit {
        margin-right: 16px;
      }
    }
  }
}
.active {
  color: #09bebe;
}
.rightBtn {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 500;
}
.leftBox {
  border-right: #c7cfcf 0.5px solid;
  height: calc(100vh - 76px);
  .left_titBox {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .l_t_txt {
      font-size: 16px;
      font-weight: 500;
    }
    .l_t_btn {
      font-size: 14px;
      color: #09bebe;
      cursor: pointer;
    }
  }
  .left_contentBox {
    .l_c_items {
      padding: 16px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .l_c_i_dele {
        color: #be0909;
        display: none;
      }
      .l_c_i_edit {
        color: #09bebe;
        display: none;
        margin-right: 16px;
      }
      .l_c_i_btn {
        display: flex;
        justify-content: space-between;
      }
    }
    .l_c_items:hover {
      background: #f2f3f4;
      .l_c_i_dele {
        display: block;
      }
      .l_c_i_edit {
        display: block;
      }
    }
  }
}
.list {
  padding: 16px;
  .pageBox {
    height: 60px;
    display: flex;
    justify-content: end;
    align-items: center;
  }
}
</style>
