<template>
  <el-container>
    <el-main>
      <el-row>
        <el-col :span="4" class="leftBox">
          <div class="left_titBox">
            <div class="l_t_txt">菏康联盟</div>
            <div class="l_t_btn" @click="OpenAddGoodsClass">添加</div>
          </div>
          <div class="left_contentBox">
            <el-tree :data="navDataList" :props="treesProps" @node-click="handleNodeClick">
              <span class="custom-tree-node" slot-scope="{ node, data }">
                <span>{{ node.label }}</span>
                <span>
                  <!-- <el-button type="text" size="mini" @click="() => openEditGoodsClass(data)"> 编辑 </el-button> -->
                  <el-button type="text" size="mini" @click="() => openDeleteGoodsClass(data)"> 删除 </el-button>
                </span>
              </span>
            </el-tree>
          </div>
        </el-col>
        <!-- 列表 -->
        <div class="rightBox">
          <div class="rightBtn">
            <div class="leftTit">商品列表</div>
            <el-button type="primary" @click="OpenAddGoods">新增商品</el-button>
          </div>
          <el-col class="list" :span="20">
            <el-table :data="tableData.rows" style="width: 100%">
              <el-table-column prop="id" label="ID" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column label="分类名称" :show-overflow-tooltip="true">
                <template>
                  <p>
                    {{ className }}
                  </p>
                </template>
              </el-table-column>
              <el-table-column prop="title" label="标题" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="imgUrl" label="图片" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <el-image style="width: 100px; height: 100px" :src="scope.row.imgUrl"> </el-image>
                </template>
              </el-table-column>
              <el-table-column label="类型" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <p>
                    {{ scope.row.type == 0 ? '京东联盟' : '' }}
                  </p>
                </template>
              </el-table-column>
              <el-table-column prop="sold" label="好评" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="createTime" label="发布时间" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <p>
                    {{ formatDate(scope.row.createTime) }}
                  </p>
                </template>
              </el-table-column>
              <el-table-column fixed="right" label="操作" width="120">
                <template slot-scope="scope">
                  <el-button @click.native.prevent="openGoodsDataInfo(scope.row)" type="text" size="small">
                    编辑
                  </el-button>
                  <el-button @click.native.prevent="deleteRow(scope.row)" type="text" size="small"> 删除 </el-button>
                </template>
              </el-table-column>
            </el-table>
            <div class="pageBox">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :page-sizes="[5, 10, 20]"
                layout="total, sizes, prev, pager, next, jumper"
                :total="tableData.total"
              >
              </el-pagination>
            </div>
          </el-col>
        </div>
      </el-row>
      <!-- 添加分类 -->
      <addGoodsClass
        :title="title"
        :addGoodsClass="addGoodsClassShow"
        @closeAddGoodsClass="showAddGoodsClass"
        :addGoodsClassInfo="addGoodsClassInfo"
        :navDataList="navDataList"
        :goodsClassDataInfo="goodsClassDataInfo"
        :editGoodsClass="editGoodsClass"
      ></addGoodsClass>
      <!-- 添加知识 -->
      <addGoods
        :addGoods="addGoodsShow"
        :title="title"
        @closeAddGoods="showAddGoods"
        :addGoodsInfo="addGoodsInfo"
        :navDataList="navDataList"
        :goodsDataInfo="goodsDataInfo"
        :editGoodsData="editGoodsData"
      ></addGoods>
    </el-main>
  </el-container>
</template>
<script>
import {
  getTreeFindAll,
  addGoodsClass,
  putEditGoodsClass,
  deleteGoodsClass,
  getClassToGoodsledgeData,
  addGoods,
  editGoods,
  deleteGoods
} from '@/api/hkUnion.js'
import AddGoodsClass from '@/components/unionGoods/addGoodsClass'
import AddGoods from '@/components/unionGoods/addGoods'
export default {
  components: {
    AddGoodsClass: AddGoodsClass,
    AddGoods: AddGoods
  },
  data() {
    return {
      addGoodsClassShow: false,
      addGoodsShow: false,
      isActive: 0,
      input: '',
      navDataList: [],
      treesProps: {
        label: 'title',
        children: 'children'
      },
      tableData: [],
      query: {
        page: 1,
        pageSize: 10
      },
      classId: '',
      className: '',
      displayTerminal: '',
      goodsDataInfo: '', // 编辑知识
      title: '',
      goodsClassDataInfo: '' //编辑分类
    }
  },
  mounted() {
    this.getGoodsClassList()
  },
  methods: {
    //获取分类
    async getGoodsClassList() {
      let result = await getTreeFindAll()
      if (result.success) {
        this.navDataList = result.data
        let res = await getClassToGoodsledgeData(this.navDataList[0].id, this.query)
        if (res.success) {
          this.tableData = res.data
        }
      }
    },
    // 获取分类下知识
    async handleNodeClick(data) {
      let res = await getClassToGoodsledgeData(data.id, this.query)
      if (res.success) {
        this.tableData = res.data
        this.classId = data.id
        this.className = data.title
      }
    },
    async getGoodsDataList() {
      let res = await getClassToGoodsledgeData(this.classId, this.query)
      if (res.success) {
        this.tableData = res.data
      }
    },
    //分页-size
    handleSizeChange(val) {
      this.query.pageSize = val
      this.getGoodsDataList()
    },
    //分页-page
    handleCurrentChange(val) {
      this.query.page = val
      this.getGoodsDataList()
    },
    // 新增分类
    OpenAddGoodsClass() {
      this.addGoodsClassShow = true
      this.title = '新增分类'
    },
    showAddGoodsClass() {
      this.addGoodsClassShow = false
    },
    // 新增分类接口
    async addGoodsClassInfo(data) {
      let res = await addGoodsClass(data)
      if (res.success) {
        this.$message({
          type: 'success',
          message: '新增分类成功!'
        })
        this.getGoodsDataList()
        this.addGoodsClassShow = false
      } else {
        this.$message({
          type: 'error',
          message: '新增分类失败!'
        })
      }
      this.getGoodsClassList()
    },
    // 编辑分类
    async openEditGoodsClass(items) {
      console.log(items)
      this.addGoodsClassShow = true
      this.goodsClassDataInfo = items
      this.title = '编辑分类'
    },
    // 编辑分类接口
    async editGoodsClass(data) {
      let res = await putEditGoodsClass(data, data.id)
      if (res.success) {
        this.$message({
          type: 'success',
          message: '编辑知识成功!'
        })
        let result = await getGoodsledgeData()
        if (result.success) {
          this.navDataList = result.data
        }
        this.addGoodsClassShow = false
      } else {
        this.$message({
          type: 'error',
          message: '编辑知识失败!'
        })
      }
    },
    // 删除分类
    async openDeleteGoodsClass(items) {
      console.log(items)
      this.$confirm('此操作将永久删除该分类, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      })
        .then(async () => {
          let res = await deleteGoodsClass(items.id)
          if (res.success) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.getGoodsClassList()
          } else {
            this.$message({
              type: 'error',
              message: '删除失败'
            })
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 新增知识
    async OpenAddGoods() {
      this.addGoodsShow = true
      this.title = '新增知识'
      let result = await getGoodsClassThree()
      if (result.success) {
        this.navDataList = result.data
      }
    },
    showAddGoods() {
      this.addGoodsShow = false
    },
    // 新增知识接口
    async addGoodsInfo(data) {
      let res = await addGoods(data)
      if (res.success) {
        this.$message({
          type: 'success',
          message: '新增知识成功!'
        })
        this.getGoodsDataList()
      } else {
        this.$message({
          type: 'error',
          message: '新增知识失败!'
        })
      }
    },
    // 编辑知识
    openGoodsDataInfo(row) {
      this.goodsDataInfo = row
      this.addGoodsShow = true
      this.title = '编辑知识'
    },
    // 编辑知识接口
    async editGoodsData(data) {
      let res = await editGoods(data, data.id)
      if (res.success) {
        this.$message({
          type: 'success',
          message: '编辑知识成功!'
        })
        this.getGoodsDataList()
        this.addGoodsShow = false
      } else {
        this.$message({
          type: 'error',
          message: '编辑知识失败!'
        })
      }
    },
    // 删除知识
    deleteRow(row) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      })
        .then(async () => {
          let res = await deleteGoods(row.id)
          if (res.success) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.getGoodsDataList()
          } else {
            this.$message({
              type: 'error',
              message: '删除失败'
            })
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    }
  }
}
</script>
<style lang="less" scoped>
.listData {
  padding: 16px;
  .grid-content {
    height: 95px;
    display: flex;
    align-items: center;
    justify-content: center;
    .grid_inner {
      text-align: center;
      .data_number {
        font-size: 32px;
        font-weight: 500;
        padding-bottom: 8px;
      }
    }
  }
}
.searchBox {
  margin: 0 16px;
  padding: 16px;
  background: #ffffff;
  border: 1px solid #c7cfcf;
  .searchTit {
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 16px;
  }
  .key_group {
    display: flex;
    .key_group_item {
      display: flex;
      align-items: center;
      margin-right: 24px;
      .key_tit {
        margin-right: 16px;
      }
    }
  }
}
.active {
  color: #09bebe;
}
.rightBtn {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 500;
}
.leftBox {
  border-right: #c7cfcf 0.5px solid;
  height: calc(100vh - 76px);
  .left_titBox {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .l_t_txt {
      font-size: 16px;
      font-weight: 500;
    }
    .l_t_btn {
      font-size: 14px;
      color: #09bebe;
      cursor: pointer;
    }
  }
  .left_contentBox {
    .l_c_items {
      padding: 16px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .l_c_i_dele {
        color: #be0909;
        display: none;
      }
      .l_c_i_edit {
        color: #09bebe;
        display: none;
        margin-right: 16px;
      }
      .l_c_i_btn {
        display: flex;
        justify-content: space-between;
      }
      .l_c_i_txt {
        display: flex;
        p {
          padding: 0;
          margin: 0;
        }
      }
    }
    .l_c_items:hover {
      background: #f2f3f4;
      .l_c_i_dele {
        display: block;
      }
      .l_c_i_edit {
        display: block;
      }
    }
  }
}
.list {
  padding: 16px;
  .pageBox {
    height: 60px;
    display: flex;
    justify-content: end;
    align-items: center;
  }
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
</style>
