<template>
  <div>首页</div>
</template>
<script>
export default {

}
</script>
<style lang="less" scoped>
</style>
