<template>
  <el-container>
    <el-dialog
      :title="title"
      :visible.sync="addNotice"
      width="50%"
      @close="close"
      @opened="getEditor()"
      append-to-body
      :close-on-click-modal="false"
    >
      <div class="content">
        <el-form ref="formData" :model="formData" label-width="80px">
          <el-form-item label="通知标题:">
            <el-input v-model="formData.noticeTitle"></el-input>
          </el-form-item>
          <el-form-item label="所属分类:">
            <el-select v-model="formData.classId" placeholder="请选择" class="f_sekect">
              <el-option v-for="item in classNoticeList" :key="item.id" :label="item.className" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="通知内容:">
            <el-input :rows="10" type="textarea" v-model="formData.noticeContent" placeholder="富文本"></el-input>
          </el-form-item> -->
          <el-form-item label="通知内容:">
            <div id="div1"></div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeNotice()">取 消</el-button>
        <el-button type="primary" @click="getAddNoticeInfo()">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>
<script>
import E from 'wangeditor'
let editor
export default {
  props: ['addNotice', 'addNoticeInfo', 'navDataList', 'noticeDataInfo', 'title', 'editNoticeData'],
  data() {
    return {
      formData: {
        noticeTitle: '',
        classId: '',
        noticeContent: ''
      },
      classNoticeList: [],
      addKnowShow: this.addNotice
    }
  },
  watch: {
    navDataList() {
      this.classNoticeList = this.navDataList
    },
    noticeDataInfo() {
      this.formData = this.noticeDataInfo
      editor.txt.html(this.formData.noticeContent)
    },
    title() {
      if (this.title == '新增通知') {
        this.formData.noticeTitle = ''
        this.formData.classId = ''
        editor.txt.html((this.formData.noticeContent = ''))
      }
    }
  },
  mounted() {},
  methods: {
    close() {
      this.$emit('closeAddNotice')
    },
    getEditor() {
      editor = new E('#div1')
      editor.create()
    },
    closeNotice() {
      if (this.title == '新增通知') {
        this.addNotice = false
        this.formData.noticeTitle = ''
        this.formData.classId = ''
        editor.txt.html((this.formData.noticeContent = ''))
      }else{
        this.addNotice = false
      }
    },
    // 提交表单
    async getAddNoticeInfo() {
      const content = editor.txt.html()
      console.log(content)
      this.formData.noticeContent = content
      if (this.title == '新增通知') {
        this.addNoticeInfo(this.formData)
        this.addNotice = false
        this.formData.noticeTitle = ''
        this.formData.classId = ''
        editor.txt.html((this.formData.noticeContent = ''))
      } else if (this.title == '编辑通知') {
        this.editNoticeData(this.formData)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.el-select {
  width: 100%;
}
</style>
