<template>
  <el-container>
    <el-main>
      <el-row>
        <el-col :span="4" class="leftBox">
          <div class="left_titBox">
            <div class="l_t_txt">菏康饮食</div>
            <div class="l_t_btn" @click="OpenAddFoodsClass">添加</div>
          </div>
          <div class="left_contentBox">
            <el-tree :data="navDataList" :props="treesProps" @node-click="handleNodeClick">
              <span class="custom-tree-node" slot-scope="{ node, data }">
                <span>{{ node.label }}</span>
                <span>
                  <!-- <el-button type="text" size="mini" @click="() => openEditFoodsClass(data)"> 编辑 </el-button> -->
                  <el-button type="text" size="mini" @click="() => openDeleteFoodsClass(data)"> 删除 </el-button>
                </span>
              </span>
            </el-tree>
          </div>
        </el-col>
        <!-- 列表 -->
        <div class="rightBox">
          <div class="rightBtn">
            <div class="leftTit">饮食列表</div>
            <el-button type="primary" @click="OpenAddFoods">新增菜品</el-button>
          </div>
          <el-col class="list" :span="20">
            <el-table :data="tableData.rows" style="width: 100%">
              <el-table-column prop="id" label="ID" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column label="分类名称" :show-overflow-tooltip="true">
                <template>
                  <p>
                    {{ className }}
                  </p>
                </template>
              </el-table-column>
              <el-table-column prop="title" label="标题" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="imgUrl" label="图片" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <el-image style="width: 100px; height: 100px" :src="scope.row.imgUrl"> </el-image>
                </template>
              </el-table-column>
              <el-table-column prop="pageviews" label="好评" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="createTime" label="发布时间" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <p>
                    {{ formatDate(scope.row.createTime) }}
                  </p>
                </template>
              </el-table-column>
              <el-table-column fixed="right" label="操作" width="120">
                <template slot-scope="scope">
                  <el-button @click.native.prevent="openFoodsDataInfo(scope.row)" type="text" size="small">
                    编辑
                  </el-button>
                  <el-button @click.native.prevent="deleteRow(scope.row)" type="text" size="small"> 删除 </el-button>
                </template>
              </el-table-column>
            </el-table>
            <div class="pageBox">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :page-sizes="[5, 10, 20]"
                layout="total, sizes, prev, pager, next, jumper"
                :total="tableData.total"
              >
              </el-pagination>
            </div>
          </el-col>
        </div>
      </el-row>
      <!-- 添加分类 -->
      <addFoodsClass
        :title="title"
        :addFoodsClass="addFoodsClassShow"
        @closeAddFoodsClass="showAddFoodsClass"
        :addFoodsClassInfo="addFoodsClassInfo"
        :foodsClassDataInfo="foodsClassDataInfo"
        :editFoodsClass="editFoodsClass"
      ></addFoodsClass>
      <!-- 添加知识 -->
      <addFoods
        :addFoods="addFoodsShow"
        :title="title"
        @closeAddFoods="showAddFoods"
        :addFoodsInfo="addFoodsInfo"
        :navDataList="navDataList"
        :foodsDataInfo="foodsDataInfo"
        :editFoodsData="editFoodsData"
      ></addFoods>
    </el-main>
  </el-container>
</template>
<script>
import {
  queryHkFoodsItemsList,
  getHkFoodsByItemsPageFindAll,
  addFoodsClass,
  putEditFoodsClass,
  deleteFoodsClass,
  addFoods,
  editFoods,
  deleteFoods
} from '@/api/hkFoods.js'
import AddFoodsClass from '@/components/hkFoods/addFoodsClass'
import AddFoods from '@/components/hkFoods/addFoods'
export default {
  components: {
    AddFoodsClass: AddFoodsClass,
    AddFoods: AddFoods
  },
  data() {
    return {
      addFoodsClassShow: false,
      addFoodsShow: false,
      isActive: 0,
      input: '',
      navDataList: [],
      treesProps: {
        label: 'title',
        children: 'children'
      },
      tableData: [],
      query: {
        page: 1,
        pageSize: 10
      },
      classId: '',
      className: '',
      displayTerminal: '',
      foodsDataInfo: '', // 编辑知识
      title: '',
      foodsClassDataInfo: '' //编辑分类
    }
  },
  mounted() {
    this.getFoodsClassList()
  },
  methods: {
    //获取分类
    async getFoodsClassList() {
      let result = await queryHkFoodsItemsList()
      if (result.success) {
        this.navDataList = result.data
        let res = await getHkFoodsByItemsPageFindAll(this.navDataList[0].id, this.query)
        if (res.success) {
          this.classId = this.navDataList[0].id
          this.className = this.navDataList[0].title
          this.tableData = res.data
          console.log('res', res)
        }
      }
    },
    // 获取分类下知识
    async handleNodeClick(data) {
      let res = await getHkFoodsByItemsPageFindAll(data.id, this.query)
      if (res.success) {
        this.tableData = res.data
        this.classId = data.id
        this.className = data.title
      }
    },
    async getFoodsDataList() {
      let res = await getHkFoodsByItemsPageFindAll(this.classId, this.query)
      if (res.success) {
        this.tableData = res.data
      }
    },
    //分页-size
    handleSizeChange(val) {
      this.query.pageSize = val
      this.getFoodsDataList()
    },
    //分页-page
    handleCurrentChange(val) {
      this.query.page = val
      this.getFoodsDataList()
    },
    // 新增分类
    OpenAddFoodsClass() {
      this.addFoodsClassShow = true
      this.title = '新增分类'
    },
    showAddFoodsClass() {
      this.addFoodsClassShow = false
    },
    // 新增分类接口
    async addFoodsClassInfo(data) {
      let res = await addFoodsClass(data)
      if (res.success) {
        this.$message({
          type: 'success',
          message: '新增分类成功!'
        })
        this.getFoodsDataList()
        this.addFoodsClassShow = false
      } else {
        this.$message({
          type: 'error',
          message: '新增分类失败!'
        })
      }
      this.getFoodsClassList()
    },
    // 编辑分类
    async openEditFoodsClass(items) {
      console.log(items)
      this.addFoodsClassShow = true
      this.foodsClassDataInfo = items
      this.title = '编辑分类'
    },
    // 编辑分类接口
    async editFoodsClass(data) {
      let res = await putEditFoodsClass(data, data.id)
      if (res.success) {
        this.$message({
          type: 'success',
          message: '编辑知识成功!'
        })
        let result = await getFoodsledgeData()
        if (result.success) {
          this.navDataList = result.data
        }
        this.addFoodsClassShow = false
      } else {
        this.$message({
          type: 'error',
          message: '编辑知识失败!'
        })
      }
    },
    // 删除分类
    async openDeleteFoodsClass(items) {
      this.$confirm('此操作将永久删除该分类, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      })
        .then(async () => {
          let res = await deleteFoodsClass(items.id)
          if (res.success) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.getFoodsClassList()
          } else {
            this.$message({
              type: 'error',
              message: '删除失败'
            })
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 新增知识
    async OpenAddFoods() {
      this.addFoodsShow = true
      this.title = '新增知识'
      let result = await getFoodsClassThree()
      if (result.success) {
        this.navDataList = result.data
      }
    },
    showAddFoods() {
      this.addFoodsShow = false
    },
    // 新增知识接口
    async addFoodsInfo(data) {
      let res = await addFoods(data)
      if (res.success) {
        this.$message({
          type: 'success',
          message: '新增知识成功!'
        })
        this.getFoodsDataList()
      } else {
        this.$message({
          type: 'error',
          message: '新增知识失败!'
        })
      }
    },
    // 编辑知识
    openFoodsDataInfo(row) {
      console.log('aaa', row)
      this.foodsDataInfo = row
      this.addFoodsShow = true
      this.title = '编辑知识'
    },
    // 编辑知识接口
    async editFoodsData(data) {
      let res = await editFoods(data, data.id)
      if (res.success) {
        this.$message({
          type: 'success',
          message: '编辑知识成功!'
        })
        this.getFoodsDataList()
        this.addFoodsShow = false
      } else {
        this.$message({
          type: 'error',
          message: '编辑知识失败!'
        })
      }
    },
    // 删除知识
    deleteRow(row) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      })
        .then(async () => {
          let res = await deleteFoods(row.id)
          if (res.success) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.getFoodsDataList()
          } else {
            this.$message({
              type: 'error',
              message: '删除失败'
            })
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    }
  }
}
</script>
<style lang="less" scoped>
.listData {
  padding: 16px;
  .grid-content {
    height: 95px;
    display: flex;
    align-items: center;
    justify-content: center;
    .grid_inner {
      text-align: center;
      .data_number {
        font-size: 32px;
        font-weight: 500;
        padding-bottom: 8px;
      }
    }
  }
}
.searchBox {
  margin: 0 16px;
  padding: 16px;
  background: #ffffff;
  border: 1px solid #c7cfcf;
  .searchTit {
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 16px;
  }
  .key_group {
    display: flex;
    .key_group_item {
      display: flex;
      align-items: center;
      margin-right: 24px;
      .key_tit {
        margin-right: 16px;
      }
    }
  }
}
.active {
  color: #09bebe;
}
.rightBtn {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 500;
}
.leftBox {
  border-right: #c7cfcf 0.5px solid;
  height: calc(100vh - 76px);
  .left_titBox {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .l_t_txt {
      font-size: 16px;
      font-weight: 500;
    }
    .l_t_btn {
      font-size: 14px;
      color: #09bebe;
      cursor: pointer;
    }
  }
  .left_contentBox {
    .l_c_items {
      padding: 16px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .l_c_i_dele {
        color: #be0909;
        display: none;
      }
      .l_c_i_edit {
        color: #09bebe;
        display: none;
        margin-right: 16px;
      }
      .l_c_i_btn {
        display: flex;
        justify-content: space-between;
      }
      .l_c_i_txt {
        display: flex;
        p {
          padding: 0;
          margin: 0;
        }
      }
    }
    .l_c_items:hover {
      background: #f2f3f4;
      .l_c_i_dele {
        display: block;
      }
      .l_c_i_edit {
        display: block;
      }
    }
  }
}
.list {
  padding: 16px;
  .pageBox {
    height: 60px;
    display: flex;
    justify-content: end;
    align-items: center;
  }
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
</style>
