<template>
  <el-container>
    <el-main>
      <el-row>
        <el-col :span="4" class="leftBox">
          <div class="left_titBox">
            <div class="l_t_txt">设备列表</div>
          </div>
          <div class="left_contentBox">
            <div class="l_c_items" v-for="(items, v) in companyList" :key="v">
              <div class="l_c_i_txt" :class="{ active: isActive == v }" @click="getInsuranceGoodsList(items, v)">
                {{ items.deviceBusiness }}
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="20" class="rightBox">
          <div class="rightBtn">
            <div class="leftTit">设备列表</div>
            <el-button type="primary" @click="openAddGoods()">新增设备</el-button>
          </div>
          <!-- 列表 -->
          <div class="list">
            <el-table :data="tableData.rows" style="width: 100%">
              <el-table-column prop="id" label="ID" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="deviceName" label="设备名称" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="deviceBrand" label="设备品牌" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="deviceType" label="设备类型 " :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <p>
                    {{ scope.row.deviceType == 0 ? "电磁阀" : "机械手" }}
                  </p>
                </template>
              </el-table-column>
              <el-table-column prop="deviceInstruct" label="设备指令" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="deviceColor" label="设备颜色" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="eamount" label="设备价值" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="usageCost" label="使用费用" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="model" label="货号" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="articleNo" label="型号" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="certificate" label="证书" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="createTime" label="创建时间" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <p>
                    {{ formatDate(scope.row.createTime) }}
                  </p>
                </template>
              </el-table-column>
              <el-table-column prop="updateTime" label="更新时间" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <p>
                    {{ formatDate(scope.row.updateTime) }}
                  </p>
                </template>
              </el-table-column>
              <el-table-column fixed="right" label="操作" width="120">
                <template slot-scope="scope">
                  <el-button @click.native.prevent="editInsuranceGoods(scope.row)" type="text" size="small">
                    编辑
                  </el-button>
                  <el-button @click.native.prevent="openDelDeviceGoods(scope.row)" type="text" size="small">
                    删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <div class="pageBox">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :page-sizes="[5, 10, 20]"
                layout="total, sizes, prev, pager, next, jumper"
                :total="tableData.total"
              >
              </el-pagination>
            </div>
          </div>
        </el-col>
      </el-row>

      <!-- 新增/编辑 -->
      <addDeviceGoods
        :addDeviceGoods="addDeviceShow"
        :title="title"
        :companyList="companyList"
        @closeAddDevice="showAddDevice"
        :addDeviceInfo="addDeviceInfo"
        :deviceGoodsDataInfo="deviceGoodsDataInfo"
        :editDeviceGoodsData="editDeviceGoodsData"
      ></addDeviceGoods>
    </el-main>
  </el-container>
</template>
<script>
import { deviceCompanyList, deviceGoodsById, addDeviceGoods, updateDeviceGoods, delDeviceGoods } from '@/api/device.js'
import AddDeviceGoods from '@/components/device/addDeviceGoods'
import { formatDate } from '@/utils/index.js'
export default {
  components: {
    addDeviceGoods: AddDeviceGoods
  },
  data() {
    return {
      isActive: 0,
      addDeviceShow: false,
      deviceGoodsDataInfo: '',
      formatDate: formatDate,
      query: {
        page: 1,
        pageSize: 10
      },
      options: [],
      value: '',
      tableData: [],
      deviceGoodsTotal: '',
      title: '',
      companyList: [],
      deviceCompanyId: ''
    }
  },
  created() {
    this.getCompanyList()
  },
  methods: {
    // 查询公司列表
    async getCompanyList() {
      let res = await deviceCompanyList(this.query)
      if (res.success) {
        this.companyList = res.data
        this.deviceCompanyId = res.data[0].id
        this.init()
      } else {
        this.$message({
          type: 'error',
          message: '查询失败!'
        })
      }
    },
    //初始化表格
    async init() {
      let result = await deviceGoodsById(this.query, this.deviceCompanyId)
      console.log(result)
      if (result.success) {
        this.tableData = result.data
        this.deviceGoodsTotal = result.data.total
      }
    },
    //分页-size
    handleSizeChange(val) {
      this.query.pageSize = val
      this.getCompanyList()
    },
    //分页-page
    handleCurrentChange(val) {
      this.query.page = val
      this.init()
    },
    // 获取公司下套餐
    getInsuranceGoodsList(items, v) {
      this.isActive = v
      this.deviceCompanyId = items.id
      this.init()
    },
    // 新增
    async openAddGoods() {
      this.addDeviceShow = true
      this.title = '新增'
    },
    // 新增接口
    async addDeviceInfo(data) {
      let res = await addDeviceGoods(data)
      if (res.success) {
        this.$message({
          type: 'success',
          message: '新增成功!'
        })
        this.init()
      } else {
        this.$message({
          type: 'error',
          message: '新增失败!'
        })
      }
    },
    showAddDevice() {
      this.addDeviceShow = false
    },
    // 编辑
    editInsuranceGoods(items) {
      this.addDeviceShow = true
      this.deviceGoodsDataInfo = items
      this.title = '编辑'
    },
    // 编辑接口
    async editDeviceGoodsData(data) {
      let res = await updateDeviceGoods(data, data.id)
      if (res.success) {
        this.$message({
          type: 'success',
          message: '编辑成功!'
        })
        this.init()
        this.addDeviceShow = false
      } else {
        this.$message({
          type: 'error',
          message: '编辑失败!'
        })
      }
    },
    // 删除
    async openDelDeviceGoods(items) {
      this.$confirm('此操作将永久删除该分类, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      })
        .then(async () => {
          let res = await delDeviceGoods(items.id)
          if (res.success) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.init()
          } else {
            this.$message({
              type: 'error',
              message: '删除失败'
            })
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    }
  }
}
</script>
<style lang="less" scoped>
.header {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .h_txt {
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    line-height: 60px;
  }
}
.active {
  color: #09bebe;
}
.leftBox {
  border-right: #c7cfcf 0.5px solid;
  height: calc(100vh - 76px);
  .left_titBox {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .l_t_txt {
      font-size: 16px;
      font-weight: 500;
    }
    .l_t_btn {
      font-size: 14px;
      color: #09bebe;
      cursor: pointer;
    }
  }
  .left_contentBox {
    .l_c_items {
      padding: 16px;
      cursor: pointer;
    }
    .l_c_items:hover {
      background: #f2f3f4;
      .l_c_i_dele {
        display: block;
      }
      .l_c_i_edit {
        display: block;
      }
    }
  }
}
.rightBtn {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 500;
}
.listData {
  padding: 16px;
  .grid-content {
    height: 95px;
    display: flex;
    align-items: center;
    justify-content: center;
    .grid_inner {
      text-align: center;
      .data_number {
        font-size: 32px;
        font-weight: 500;
        padding-bottom: 8px;
      }
    }
  }
}
.searchBox {
  margin: 0 16px;
  padding: 16px;
  background: #ffffff;
  border: 1px solid #c7cfcf;
  .searchTit {
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 16px;
  }
  .key_group {
    display: flex;
    .key_group_item {
      display: flex;
      align-items: center;
      margin-right: 24px;
      .key_tit {
        margin-right: 16px;
      }
    }
  }
}
.list {
  padding: 16px;
  .pageBox {
    height: 60px;
    display: flex;
    justify-content: end;
    align-items: center;
  }
}
</style>
