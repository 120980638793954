<template>
  <el-container>
    <el-main>
      <el-row>
        <el-col :span="4" class="leftBox">
          <div class="left_titBox">
            <div class="l_t_txt">保险公司</div>
          </div>
          <div class="left_contentBox">
            <div class="l_c_items" v-for="(items, v) in companyList" :key="v">
              <div class="l_c_i_txt" :class="{ active: isActive == v }" @click="getInsuranceGoodsList(items, v)">
                {{ items.companyName }}
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="20" class="rightBox">
          <div class="rightBtn">
            <div class="leftTit">套餐列表</div>
            <el-button type="primary" @click="openAddGoods()">新增套餐</el-button>
          </div>
          <!-- 列表 -->
          <div class="list">
            <el-table :data="tableData.rows" style="width: 100%">
              <el-table-column prop="id" label="ID" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="insuranceGoodsName" label="保险名称" :show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column prop="insuranceMoney" label="保险单价" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <p>{{ scope.row.insuranceMoney }} 元</p>
                </template>
              </el-table-column>
              <el-table-column prop="insuranceCycle" label="保单周期" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <p>{{ scope.row.insuranceCycle }} 年</p>
                </template>
              </el-table-column>
              <el-table-column prop="insureTotal" label="总理赔额" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <p>{{ scope.row.insureTotal }} 万元</p>
                </template>
              </el-table-column>
              <el-table-column prop="insuranceImg" label="套餐截图" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <el-avatar shape="square" :src="scope.row.insuranceImg"></el-avatar>
                </template>
              </el-table-column>
              <el-table-column prop="insuranceInfo" label="套餐详情" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="showStatus" label="是否在申请页面显示" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <p>
                    {{ scope.row.showStatus == '1' ? '显示' : '不显示' }}
                  </p>
                </template>
              </el-table-column>
              <el-table-column prop="createTime" label="创建时间" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <p>
                    {{ formatDate(scope.row.createTime) }}
                  </p>
                </template>
              </el-table-column>
              <el-table-column prop="updateTime" label="更新时间" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <p>
                    {{ formatDate(scope.row.updateTime) }}
                  </p>
                </template>
              </el-table-column>
              <el-table-column fixed="right" label="操作" width="120">
                <template slot-scope="scope">
                  <el-button @click.native.prevent="editInsuranceGoods(scope.row)" type="text" size="small">
                    编辑
                  </el-button>
                  <el-button @click.native.prevent="openDelInsuranceGoods(scope.row)" type="text" size="small">
                    删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <div class="pageBox">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :page-sizes="[5, 10, 20]"
                layout="total, sizes, prev, pager, next, jumper"
                :total="tableData.total"
              >
              </el-pagination>
            </div>
          </div>
        </el-col>
      </el-row>

      <!-- 新增/编辑 -->
      <addInsuranceGoods
        :addInsuranceGoods="addInsuranceShow"
        :title="title"
        :companyList="companyList"
        @closeAddInsurance="showAddInsurance"
        :addInsuranceInfo="addInsuranceInfo"
        :insuranceGoodsDataInfo="insuranceGoodsDataInfo"
        :editInsuranceGoodsData="editInsuranceGoodsData"
      ></addInsuranceGoods>
    </el-main>
  </el-container>
</template>
<script>
import {
  insuranceCompanyList,
  insuranceGoodsList,
  insuranceGoodsById,
  addInsuranceGoods,
  delInsuranceGoods,
  updateInsuranceGoods
} from '@/api/insurance.js'
import AddInsuranceGoods from '@/components/insurance/addInsuranceGoods'
import { formatDate } from '@/utils/index.js'
export default {
  components: {
    addInsuranceGoods: AddInsuranceGoods
  },
  data() {
    return {
      isActive: 0,
      addInsuranceShow: false,
      insuranceGoodsDataInfo: '',
      formatDate: formatDate,
      query: {
        page: 1,
        pageSize: 10
      },
      options: [],
      value: '',
      tableData: [],
      insuranceGoodsTotal: '',
      title: '',
      companyList: [],
      insuranceCompanyId: ''
    }
  },
  created() {
    this.getCompanyList()
  },
  methods: {
    // 查询保险公司列表
    async getCompanyList() {
      let res = await insuranceCompanyList(this.query)
      if (res.success) {
        this.companyList = res.data
        this.insuranceCompanyId = res.data[0].id
        this.init()
      } else {
        this.$message({
          type: 'error',
          message: '查询失败!'
        })
      }
    },
    //初始化表格
    async init() {
      let result = await insuranceGoodsById(this.query, this.insuranceCompanyId)
      if (result.success) {
        this.tableData = result.data
        this.insuranceGoodsTotal = result.data.total
      }
    },
    //分页-size
    handleSizeChange(val) {
      this.query.pageSize = val
      this.getCompanyList()
    },
    //分页-page
    handleCurrentChange(val) {
      this.query.page = val
      this.init()
    },
    // 获取公司下套餐
    getInsuranceGoodsList(items, v) {
      this.isActive = v
      this.insuranceCompanyId = items.id
      this.init()
    },
    // 新增
    async openAddGoods() {
      this.addInsuranceShow = true
      this.title = '新增'
    },
    // 新增接口
    async addInsuranceInfo(data) {
      let res = await addInsuranceGoods(data)
      if (res.success) {
        this.$message({
          type: 'success',
          message: '新增成功!'
        })
        this.init()
      } else {
        this.$message({
          type: 'error',
          message: '新增失败!'
        })
      }
    },
    showAddInsurance() {
      this.addInsuranceShow = false
    },
    // 编辑
    editInsuranceGoods(items) {
      this.addInsuranceShow = true
      this.insuranceGoodsDataInfo = items
      this.title = '编辑'
    },
    // 编辑接口
    async editInsuranceGoodsData(data) {
      let res = await updateInsuranceGoods(data, data.id)
      if (res.success) {
        this.$message({
          type: 'success',
          message: '编辑成功!'
        })
        this.init()
        this.addInsuranceShow = false
      } else {
        this.$message({
          type: 'error',
          message: '编辑失败!'
        })
      }
    },
    // 删除
    async openDelInsuranceGoods(items) {
      this.$confirm('此操作将永久删除该分类, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      })
        .then(async () => {
          let res = await delInsuranceGoods(items.id)
          if (res.success) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.init()
          } else {
            this.$message({
              type: 'error',
              message: '删除失败'
            })
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    }
  }
}
</script>
<style lang="less" scoped>
.header {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .h_txt {
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    line-height: 60px;
  }
}
.active {
  color: #09bebe;
}
.leftBox {
  border-right: #c7cfcf 0.5px solid;
  height: calc(100vh - 76px);
  .left_titBox {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .l_t_txt {
      font-size: 16px;
      font-weight: 500;
    }
    .l_t_btn {
      font-size: 14px;
      color: #09bebe;
      cursor: pointer;
    }
  }
  .left_contentBox {
    .l_c_items {
      padding: 16px;
      cursor: pointer;
    }
    .l_c_items:hover {
      background: #f2f3f4;
      .l_c_i_dele {
        display: block;
      }
      .l_c_i_edit {
        display: block;
      }
    }
  }
}
.rightBtn {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 500;
}
.listData {
  padding: 16px;
  .grid-content {
    height: 95px;
    display: flex;
    align-items: center;
    justify-content: center;
    .grid_inner {
      text-align: center;
      .data_number {
        font-size: 32px;
        font-weight: 500;
        padding-bottom: 8px;
      }
    }
  }
}
.searchBox {
  margin: 0 16px;
  padding: 16px;
  background: #ffffff;
  border: 1px solid #c7cfcf;
  .searchTit {
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 16px;
  }
  .key_group {
    display: flex;
    .key_group_item {
      display: flex;
      align-items: center;
      margin-right: 24px;
      .key_tit {
        margin-right: 16px;
      }
    }
  }
}
.list {
  padding: 16px;
  .pageBox {
    height: 60px;
    display: flex;
    justify-content: end;
    align-items: center;
  }
}
</style>
