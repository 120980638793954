import request from '@/utils/request'

// 菏康集团 - 查询全部列表
export function queryHkGroupList(data) {
    return request({
        url: '/sys/hkGroup/findAll',
        method: 'get',
        params:data
    })
}
// 菏康集团 - 查询全部列表 不分页
export function queryHkGroupAllList() {
    return request({
        url: '/sys/hkGroup/findAllList',
        method: 'get'
    })
}
// 菏康集团 - 新增公司
export function addHkCompany(data) {
    console.log(data)
    return request({
        url: '/sys/hkGroup',
        method: 'POST',
        data:data
    })
}
// 菏康集团 - 根据id查询
export function getHkCompany(id) {
    return request({
        url: '/sys/hkGroup/' + id,
        method: 'get',
    })
}
// 菏康集团 - 删除
export function delHkCompany(id) {
    return request({
        url: '/sys/hkGroup/' + id,
        method: 'delete',
    })
}

// 菏康集团 - 更新
export function updateHkCompany(data) {
    return request({
        url: '/sys/hkGroup/'+ data.id,
        method: 'put',
        data:data

    })
}