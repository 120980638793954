<template>
  <el-container>
    <el-header class="header">
      <div class="leftTit">待安装列表</div>
    </el-header>
    <el-main>
      <!-- 数据统计 -->
      <div class="listData">
        <el-row :gutter="8">
          <el-col :span="4">
            <div class="grid-content bg-purple" style="background: #e9fbfb">
              <div class="grid_inner">
                <div class="data_number">{{ propertyRescindTotal }}</div>
                <div>待安装总数</div>
              </div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple" style="background: #e9fbf2">
              <div class="grid_inner">
                <div class="data_number">{{ propertyRescindTotal }}</div>
                <div>云安居</div>
              </div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple" style="background: #fbe9e9">
              <div class="grid_inner">
                <div class="data_number">{{ propertyRescindTotal }}</div>
                <div>运营</div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- 列表 -->
      <div class="list">
        <el-row class="list_btnBox" v-if="listBoxStyle == true">
          <div class="lb_txt">已选择{{ this.multipleSelection.length }}项</div>
          <el-button type="primary" size="small" @click="openDrawerShow">批量操作</el-button>
          <el-button type="danger" size="small" @click="batchDelRow">删除</el-button>
        </el-row>
        <el-table
          ref="multipleTable"
          :data="tableData.rows"
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column prop="id" label="ID" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="uid" label="UID(与云安居通信唯一标识)" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="userKey" label="userKey(与云安居通信唯一标识)" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="userAddress" label="用户地址" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="userName" label="用户姓名" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="userPhone" label="用户手机" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="source" label="来源" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="createTime" label="申请时间" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column fixed="right" label="操作" width="120">
            <template slot-scope="scope">
              <el-button @click.native.prevent="openDetailsRow(scope.row)" type="text" size="small"> 编辑 </el-button>
              <el-button @click.native.prevent="openDelRow(scope.row)" type="text" size="small"> 删除 </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="pageBox">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-sizes="[5, 10, 20]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="tableData.total"
          >
          </el-pagination>
        </div>
      </div>
    </el-main>
    <!-- 审核 -->
    <el-drawer :visible.sync="drawer" :with-header="false" :wrapper-closable="false">
      <div class="drawer_titBox">
        <div>审核</div>
        <div class="dt_close" @click="handleClose"><i class="el-icon-close"></i></div>
      </div>
      <div class="drawer_content">
        <el-form
          label-position="top"
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="统一地区">
            <el-cascader
              ref="cascader"
              v-model="address"
              :options="proCityAreaList"
              :props="proCityAreaTreeProps"
              @change="handleChange"
              placeholder="请选择统一地区"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="绑定社区" prop="communityId">
            <el-select v-model="ruleForm.communityId" placeholder="请选择绑定社区">
              <el-option
                v-for="(items, index) in communityList"
                :key="index"
                :label="items.villageName"
                :value="items.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-row :gutter="8">
            <el-col :span="12">
              <el-form-item label="服务类型" prop="serviceTypeId">
                <el-select v-model="ruleForm.serviceTypeId" placeholder="请选择服务类型">
                  <el-option
                    v-for="(items, index) in serviceTypeList"
                    :key="index"
                    :label="items.label"
                    :value="items.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="服务套餐" prop="servicePackageId">
                <el-select v-model="ruleForm.servicePackageId" placeholder="请选择服务套餐">
                  <el-option
                    v-for="(items, index) in servicePackageList"
                    :key="index"
                    :label="items.serviceName"
                    :value="items.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="dataListBox">
            <div class="dl_tit">智能识别</div>
            <div class="dl_item" v-for="(item, index) in multipleSelection" :key="index">
              <div class="dli_titBox">
                <div class="dli_txt">{{ index + 1 }}、{{ item.userAddress }}</div>
                <div class="dt_close" @click="remove(index)"><i class="el-icon-delete"></i></div>
              </div>
              <el-row :gutter="8">
                <el-col :span="6">
                  <el-form-item prop="region">
                    <el-input v-model="item.floor" placeholder="楼号">
                      <template slot="append">号楼</template>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item prop="region">
                    <el-input v-model="item.unit" placeholder="单元">
                      <template slot="append">单元</template>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item prop="region">
                    <el-input v-model="item.layer" placeholder="楼层">
                      <template slot="append">层</template>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item prop="region">
                    <el-input v-model="item.room" placeholder="房号">
                      <template slot="append">室</template>
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
          </div>
          <el-form-item>
            <el-button type="primary" @click="submitForm">立即创建</el-button>
            <el-button @click="resetForm('ruleForm')">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-drawer>
    <!-- 编辑 -->
    <el-dialog title="编辑" :visible.sync="dialogVisible" width="30%" :before-close="dialogHandleClose">
      <div class="dialogForm">
        <el-form :model="dialogForm" label-width="80px" label-position="top">
          <el-form-item label="UID:">
            <el-input v-model="dialogForm.uid"></el-input>
          </el-form-item>
          <el-form-item label="用户地址">
            <el-input v-model="dialogForm.userAddress"></el-input>
          </el-form-item>
          <el-form-item label="用户姓名">
            <el-input v-model="dialogForm.userName"></el-input>
          </el-form-item>
          <el-form-item label="用户手机">
            <el-input v-model="dialogForm.userPhone"></el-input>
          </el-form-item>
          <el-form-item label="来源">
            <el-input v-model="dialogForm.source"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogSubmitForm">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>
<script>
import { pageList, delCooSecurity, updateCooSecurity, cooSecurityInstall } from '@/api/coo.js'
import { proCityAreaFindAll } from '@/api/public.js'
import { getTocityServiceGoods } from '@/api/serviceGoods.js'
import { getToAreaPropertyList } from '@/api/property.js'
export default {
  data() {
    return {
      tableData: [],
      propertyRescindTotal: 0,
      query: {
        page: 1,
        pageSize: 10
      },
      listBoxStyle: false,
      multipleSelection: [],
      drawer: false,
      address: null,
      ruleForm: {
        communityId: '',
        serviceTypeId: '',
        servicePackageId: '',
        data: []
      },
      rules: {
        communityId: [{ required: true, message: '请选择绑定社区', trigger: 'change' }],
        serviceTypeId: [{ required: true, message: '请选择服务类型', trigger: 'change' }],
        servicePackageId: [{ required: true, message: '请选择服务套餐', trigger: 'change' }]
      },
      options: [],
      dialogVisible: false,
      dialogForm: {},
      proCityAreaList: [],
      proCityAreaTreeProps: {
        emitPath: false,
        value: 'code',
        label: 'name',
        children: 'children'
      },
      serviceCityCode: null,
      serviceAreaCode: null,
      communityList: [],
      servicePackageList: [],
      serviceTypeList: [
        {
          label: '普通服务',
          value: '0'
        },
        {
          label: '政府补助',
          value: '1'
        }
      ]
    }
  },
  created() {
    this.initPage()
  },
  methods: {
    // 列表
    async initPage() {
      let result = await pageList(this.query)
      this.tableData = result.data
      this.propertyRescindTotal = result.data.total
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
      this.listBoxStyle = true
      if (this.multipleSelection.length < 1) {
        this.listBoxStyle = false
      }
    },
    //分页-size
    handleSizeChange(val) {
      this.query.pageSize = val
      this.initPage()
    },
    //分页-page
    handleCurrentChange(val) {
      this.query.page = val
      this.initPage()
    },
    // 选择地区
    handleChange() {
      let nodesObj = this.$refs['cascader'].getCheckedNodes()
      this.serviceCityCode = nodesObj[0].path[1]
      this.serviceAreaCode = nodesObj[0].path[2]
      this.getAreaPropertyList()
      this.getServiceGoodsList()
    },
    // 选择社区
    async getAreaPropertyList() {
      let res = await getToAreaPropertyList(this.serviceAreaCode)
      if (res.success) {
        this.communityList = res.data
      }
    },
    // 显示根据城市查询的服务列表
    async getServiceGoodsList() {
      let res = await getTocityServiceGoods(this.serviceCityCode)
      if (res.success) {
        this.servicePackageList = res.data
      }
    },
    // 显示弹框
    async openDrawerShow() {
      this.drawer = true
      let res = await proCityAreaFindAll()
      if (res.success) {
        this.proCityAreaList = res.data
      } else {
        this.$message.error('地区查询失败！')
      }
      // 识别地址
      // 根据正则拆分地址
      var regex =
        '(?<province>[^省]+省|[^自治区]+自治区|.+市)(?<city>[^自治州]+自治州|.+区划|[^市]+市|.+区)?(?<county>[^市]+市|[^县]+县|[^旗]+旗|.+区)?(?<town>[^区]+区|.+镇)?(?<floor>[^号楼]+号楼)?(?<unit>[^单元]+单元|.+门)?(?<layer>[^层]+层)?(?<room>.*)'
      for (let i = 0; i < this.multipleSelection.length; i++) {
        const address = this.multipleSelection[i].userAddress
        // 用正则表达式分割详情地址
        var result = address.match(regex)
        // 构造正则表达式
        this.$set(this.multipleSelection[i], 'floor', result.groups.floor.slice(0, -2))
        this.$set(this.multipleSelection[i], 'unit', result.groups.unit.match(/\d+/g)[0])
        this.$set(this.multipleSelection[i], 'layer', result.groups.layer.match(/\d+/g)[0])
        this.$set(this.multipleSelection[i], 'room', result.groups.room.match(/\d+/g)[0])
      }
    },
    openDetailsRow(row) {
      console.log("ddssss",row)
      this.dialogForm = row
      this.dialogVisible = true
    },
    // 批量删除
    batchDelRow() {
      this.$confirm('确认删除？')
        .then(async (_) => {
          for (let i = 0; i < this.multipleSelection.length; i++) {
            let res = await delCooSecurity(this.multipleSelection[i].id)
            if (res.success) {
              this.$message.success('删除成功')
            } else {
              this.$message.error('删除失败，请重试！')
            }
          }
          this.initPage()
        })
        .catch((_) => {})
    },

    // 删除
    openDelRow(row) {
      this.$confirm('确认删除？')
        .then(async (_) => {
          let res = await delCooSecurity(row.id)
          if (res.success) {
            this.$message.success('删除成功')
            this.initPage()
          } else {
            this.$message.error('删除失败，请重试！')
          }
        })
        .catch((_) => {})
    },
    // 提交dialog
    async dialogSubmitForm() {
      let res = await updateCooSecurity(this.dialogForm)
      if (res.success) {
        this.$message.success('编辑成功')
        this.initPage()
        this.dialogVisible = false
      } else {
        this.$message.error('编辑失败，请重试！')
      }
    },
    // 关闭dialog
    dialogHandleClose() {
      this.dialogVisible = false
    },
    // 关闭抽屉
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          this.drawer = false
        })
        .catch((_) => {})
    },
    // 提交
    async submitForm() {
      let data = []
      for (let i = 0; i < this.multipleSelection.length; i++) {
        let item = {
          id: this.multipleSelection[i].id,
          uid: this.multipleSelection[i].uid,
          floor: this.multipleSelection[i].floor,
          unit: this.multipleSelection[i].unit,
          layer: this.multipleSelection[i].layer,
          room: this.multipleSelection[i].room,
          name: this.multipleSelection[i].userName,
          phone: this.multipleSelection[i].userPhone,
          source: this.multipleSelection[i].source,
          userKey: this.multipleSelection[i].userKey
        }
        data.push(item)
      }
      let ruleForm = {
        communityId: this.ruleForm.communityId,
        serviceTypeId: this.ruleForm.serviceTypeId,
        servicePackageId: this.ruleForm.servicePackageId,
        data: JSON.stringify(data)
      }
      console.log("----",ruleForm)
      let res = await cooSecurityInstall(ruleForm)
      if (res.success) {
        this.$message.success('编辑成功')
        this.drawer = false
        this.initPage()
      } else {
        this.$message.error('编辑失败，请重试！')
      }
    },
    // 重置
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    // 删除multipleSelection某条数据
    remove(index) {
      this.$confirm('确认删除？')
        .then((_) => {
          this.multipleSelection.splice(index, 1)
        })
        .catch((_) => {})
    }
  }
}
</script>
<style lang="less" scoped>
.header {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  line-height: 60px;
  display: flex;
  justify-content: space-between;
}
.list {
  padding: 16px;
  .pageBox {
    height: 60px;
    display: flex;
    justify-content: end;
    align-items: center;
  }
}
.listData {
  padding: 16px;
  .grid-content {
    height: 95px;
    display: flex;
    align-items: center;
    justify-content: center;
    .grid_inner {
      text-align: center;
      .data_number {
        font-size: 32px;
        font-weight: 500;
        padding-bottom: 8px;
      }
    }
  }
}
.list_btnBox {
  display: flex;
  align-items: center;
  background: #f2f3f4;
  padding: 8px 16px;
  .lb_txt {
    padding-right: 16px;
  }
}
// 抽屉弹框
.drawer_content {
  padding: 0 24px;
  /deep/ .el-form-item__label {
    padding: 0 0 8px 0;
    line-height: 1;
  }
  /deep/ .el-form-item {
    margin-bottom: 16px;
  }
  /deep/ .el-cascader,
  /deep/ .el-select {
    width: 100%;
  }
  .formItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.drawer_titBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  font-size: 18px;
  font-weight: bold;
  .dt_close {
    padding: 8px;
  }
  .dt_close:hover {
    color: red;
    cursor: pointer;
  }
}
.dl_tit {
  font-weight: bold;
  padding-bottom: 16px;
}
.dl_item {
  .dli_titBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 8px;
    .dli_txt {
      opacity: 0.5;
    }
  }
  .dt_close {
    color: red;
    cursor: pointer;
  }
  /deep/ .el-input-group__append {
    padding: 0 4px;
  }
}
.dialogForm {
  /deep/ .el-form-item__label {
    padding: 0 0 8px 0;
    line-height: 1;
  }
  /deep/ .el-form-item {
    margin-bottom: 16px;
  }
  /deep/ .el-cascader,
  /deep/ .el-select {
    width: 100%;
  }
}
</style>
