<template>
  <el-container>
    <el-header class="header">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>物业待审核</el-breadcrumb-item>
        <el-breadcrumb-item>{{propertyRecord.villageName}}</el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>
    <el-main>
      <!-- 列表 -->
      <div class="list">
        <el-row :gutter="8">
          <el-col :span="4">
            <div class="grid-content bg-purple">
              <span class="tit">小区名称:</span>
              <span>{{propertyRecord.villageName}}</span>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple">
              <span class="tit">所属地区：:</span>
              <span>{{propertyRecord.propertyArea}}</span>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple">
              <span class="tit">详细地址:</span>
              <span>{{propertyRecord.propertyAddress}}</span>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple">
              <span class="tit">物业名称：:</span>
              <span>{{propertyRecord.propertyName}}</span>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple">
              <span class="tit">负责人姓名:</span>
              <span>{{propertyRecord.contactPerson}}</span>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple">
              <span class="tit">联系电话:</span>
              <span>{{propertyRecord.propertyPhone}}</span>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple">
              <span class="tit">营业执照:</span>
              <el-link type="primary" @click="OpenBusinessLicense">点击查看</el-link>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple">
              <span class="tit">信用代码:</span>
              <span>{{propertyRecord.businessLicenseId}}</span>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple">
              <span class="tit">法人姓名:</span>
              <span>{{propertyRecord.legalRepresentative}}</span>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple">
              <span class="tit">建设时间:</span>
              <span>{{propertyRecord.constructionTime}}</span>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple">
              <span class="tit">占地面积:</span>
              <span>{{propertyRecord.areaCovered}}</span>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple">
              <span class="tit">总楼栋:</span>
              <span>{{propertyRecord.generalBuilding}}栋</span>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple">
              <span class="tit">总户数:</span>
              <span>{{propertyRecord.totalHouseholds}}</span>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple">
              <span class="tit">开发商:</span>
              <span>{{propertyRecord.developers}}</span>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple">
              <span class="tit">行政用房:</span>
              <span>{{propertyRecord.adminHousing}}</span>
            </div>
          </el-col>
        </el-row>
        <div>
          <el-button type="danger" @click="OpenRefuseAudit">拒绝</el-button>
          <el-button type="primary" @click="OpenEditAudit">签约</el-button>
        </div>
        <!-- 打开审核弹框 -->
        <editAudit :editAudit="editAuditShow" @closeEditAudit="showEditAudit" :propertyIdInfo="propertyIdInfo"></editAudit>
        <!-- 打开拒绝弹框 -->
        <refuseAudit :refuseAudit="refuseAuditShow" @closeRefuseAudit="showRefuseAudit"></refuseAudit>
        <!-- 打开营业执照弹框 -->
        <businessLicense :businessLicense="businessLicenseShow" @closeBusinessLicense="showBusinessLicense"></businessLicense>
      </div>
    </el-main>
  </el-container>
</template>
<script>
import EditAudit from '@/components/property/editAudit'
import RefuseAudit from '@/components/property/refuseAudit'
import BusinessLicense from '@/components/property/businessLicense'
import { mapGetters } from 'vuex'
export default {
  props: ['editAudit'],
  components: {
    editAudit: EditAudit,
    refuseAudit: RefuseAudit,
    businessLicense: BusinessLicense
  },
  data () {
    return {
      editAuditShow: false,
      refuseAuditShow: false,
      businessLicenseShow: false,
      propertyIdInfo: ''
    }
  },
  computed: {
    ...mapGetters(['propertyRecord'])
  },
  mounted () {
    this.propertyIdInfo = this.propertyRecord
  },
  methods: {
    showEditAudit () {
      this.editAuditShow = false
    },
    OpenEditAudit () {
      this.editAuditShow = true
    },
    showRefuseAudit () {
      this.refuseAuditShow = false
    },
    OpenRefuseAudit () {
      this.refuseAuditShow = true
    },
    OpenBusinessLicense () {
      this.businessLicenseShow = true
    },
    showBusinessLicense () {
      this.businessLicenseShow = false
    },
  }
}
</script>
<style lang="less" scoped>
.header {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  line-height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.list {
  padding: 16px;
  .grid-content {
    padding-bottom: 16px;
  }
  .tit {
    padding-right: 16px;
  }
}
</style>
