import request from '@/utils/request'

// 第三方合作管理 - 查询全部申请列表
export function pageList(data) {
    return request({
        url: '/sys/cooSecurityInstall/findPageAll',
        method: 'get',
        params: data
    })
}
// 根据source分页查询申请列表
export function sourcePageList(data, source) {
    return request({
        url: '/sys/source/' + source,
        method: 'get',
        params: data
    })
}

// 删除
export function delCooSecurity(id) {
    return request({
        url: '/sys/cooSecurityInstall/' + id,
        method: 'delete',
    })
}

// 更新
export function updateCooSecurity(data) {
    return request({
        url: '/sys/cooSecurityInstall/' + data.id,
        method: 'put',
        data: data

    })
}

// 批量编辑
export function cooSecurityInstall(data) {
    return request({
        url: '/sys/cooSecurityInstall/operation',
        method: 'POST',
        data: data
    })
}