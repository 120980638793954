<template>
  <el-container>
    <el-header class="header">坐席预警</el-header>
    <el-main>
      <!-- 数据统计 -->
      <div class="listData">
        <el-row :gutter="8">
          <el-col :span="4">
            <div class="grid-content bg-purple" style="background: #e9fbfb">
              <div class="grid_inner">
                <div class="data_number">{{ propertyTotal }}</div>
                <div>总小区数</div>
              </div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple" style="background: #e9f2fb">
              <div class="grid_inner">
                <div class="data_number">{{ remindOnTotal }}</div>
                <div>总开启提醒</div>
              </div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple" style="background: #e9fbf2">
              <div class="grid_inner">
                <div class="data_number">{{ loginStatusOnTotal }}</div>
                <div>在线小区</div>
              </div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple" style="background: #fbe9e9">
              <div class="grid_inner">
                <div class="data_number">{{ loginStatusOffTotal }}</div>
                <div>离线小区</div>
              </div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple" style="background: #fbe9e9">
              <div class="grid_inner">
                <div class="data_number">{{ remindOffTotal }}</div>
                <div>未开启提醒</div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- 列表 -->
      <el-tabs style="padding: 20px" v-model="activeName">
        <el-tab-pane label="在线列表" name="first">
          <!-- 列表 -->
          <div class="list">
            <el-table :data="tableData.rows" style="width: 100%">
              <el-table-column prop="id" label="ID" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="propertyName" label="所属物业" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="villageName" label="所属小区" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="account" label="登录账号" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="name" label="负责人" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="mobile" label="联系电话" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="loginStatusTime" label="上次登录时间" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <p>
                    {{ formatDate(scope.row.loginStatusTime) }}
                  </p>
                </template>
              </el-table-column>
              <el-table-column prop="loginOutTime" label="上次退出时间" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <p>
                    {{ formatDate(scope.row.loginOutTime) }}
                  </p>
                </template>
              </el-table-column>
              <el-table-column prop="loginStatus" label="状态" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <p v-if="scope.row.loginStatus == 0 || scope.row.loginStatus == null" :style="{ color: redColor }">
                    离线
                  </p>
                  <p v-if="scope.row.loginStatus == 1" :style="{ color: greenColor }">在线</p>
                </template>
              </el-table-column>
              <el-table-column fixed="right" label="操作" width="120">
                <template slot-scope="scope">
                  <el-button @click.native.prevent="callbackRow(scope.$index, tableData)" type="text" size="small">
                    回拨
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <div class="pageBox">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :page-sizes="[5, 10, 20]"
                layout="total, sizes, prev, pager, next, jumper"
                :total="tableData.total"
              >
              </el-pagination>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="离线列表" name="second">
          <!-- 列表 -->
          <div class="list">
            <el-table :data="tableData2.rows" style="width: 100%">
              <el-table-column prop="id" label="ID" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="propertyName" label="所属物业" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="villageName" label="所属小区" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="account" label="登录账号" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="name" label="负责人" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="mobile" label="联系电话" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="loginStatusTime" label="上次登录时间" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <p>
                    {{ formatDate(scope.row.loginStatusTime) }}
                  </p>
                </template>
              </el-table-column>
              <el-table-column prop="loginOutTime" label="上次退出时间" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <p>
                    {{ formatDate(scope.row.loginOutTime) }}
                  </p>
                </template>
              </el-table-column>
              <el-table-column prop="loginStatus" label="状态" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <p v-if="scope.row.loginStatus == 0 || scope.row.loginStatus == null" :style="{ color: redColor }">
                    离线
                  </p>
                  <p v-if="scope.row.loginStatus == 1" :style="{ color: greenColor }">在线</p>
                </template>
              </el-table-column>
              <el-table-column fixed="right" label="操作" width="120">
                <template slot-scope="scope">
                  <el-button @click.native.prevent="callbackRow(scope.$index, tableData)" type="text" size="small">
                    回拨
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <div class="pageBox">
              <el-pagination
                @size-change="handleSizeChange2"
                @current-change="handleCurrentChange2"
                :page-sizes="[5, 10, 20]"
                layout="total, sizes, prev, pager, next, jumper"
                :total="tableData2.total"
              >
              </el-pagination>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="未开启提醒" name="offRemind">
          <!-- 列表 -->
          <div class="list">
            <el-table :data="tableData3.rows" style="width: 100%">
              <el-table-column prop="id" label="ID" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="propertyName" label="所属物业" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="villageName" label="所属小区" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="account" label="登录账号" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="name" label="负责人" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="mobile" label="联系电话" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="loginStatusTime" label="上次登录时间" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <p>
                    {{ formatDate(scope.row.loginStatusTime) }}
                  </p>
                </template>
              </el-table-column>
              <el-table-column prop="loginOutTime" label="上次退出时间" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <p>
                    {{ formatDate(scope.row.loginOutTime) }}
                  </p>
                </template>
              </el-table-column>
              <el-table-column prop="loginStatus" label="状态" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <p v-if="scope.row.loginStatus == 0 || scope.row.loginStatus == null" :style="{ color: redColor }">
                    离线
                  </p>
                  <p v-if="scope.row.loginStatus == 1" :style="{ color: greenColor }">在线</p>
                </template>
              </el-table-column>
              <el-table-column fixed="right" label="操作" width="120">
                <template slot-scope="scope">
                  <el-button @click.native.prevent="callbackRow(scope.$index, tableData)" type="text" size="small">
                    回拨
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <div class="pageBox">
              <el-pagination
                @size-change="handleSizeChange3"
                @current-change="handleCurrentChange3"
                :page-sizes="[5, 10, 20]"
                layout="total, sizes, prev, pager, next, jumper"
                :total="tableData3.total"
              >
              </el-pagination>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
      <!-- 隐私电话弹框 -->
      <privacyCall :privacyCall="privacyCallShow" @closePrivacyCall="showPrivacyCall"></privacyCall>
    </el-main>
  </el-container>
</template>
<script>
import PrivacyCall from '@/components/public/privacyCall'
import { queryRemindList, queryRemindLoginStatusList } from '@/api/warning.js'
import { queryProperty } from '@/api/property.js'
export default {
  props: ['SeatDetails', 'PrivacyCall'],
  components: {
    privacyCall: PrivacyCall
  },
  data() {
    return {
      activeName: 'first',
      privacyCallShow: false,
      seatDetailsShow: false,
      tableData: [],
      tableData2: [],
      tableData3: [],
      query: {
        page: 1,
        pageSize: 10
      },
      redColor: 'red',
      greenColor: 'green',
      remindOnTotal: 0,
      remindOffTotal: 0,
      loginStatusOnTotal: 0,
      loginStatusOffTotal: 0,
      // 物业总数
      propertyTotal: 0
    }
  },
  created() {
    this.getDataInit()
    this.getRemindLoginStatusList()
  },
  methods: {
    //数据统计
    async getDataInit() {
      // 总开启服务数
      const remind = '1'
      let result = await queryRemindList(remind, this.query)
      if (result.success) {
        this.remindOnTotal = result.data.total
      }
      // 未开启服务数
      const offRemind = '0'
      let result2 = await queryRemindList(offRemind, this.query)
      if (result.success) {
        this.tableData3 = result2.data
        this.remindOffTotal = result2.data.total
      }
      // 总物业数
      let result3 = await queryProperty(this.query)
      if (result.success) {
        this.propertyTotal = result3.data.total
      }
    },
    //已开通服务中 在线/离线状态
    async getRemindLoginStatusList() {
      const loginStatusOn = '1'
      let result = await queryRemindLoginStatusList(loginStatusOn, this.query)
      if (result.success) {
        this.tableData = result.data
        this.loginStatusOnTotal = result.data.total
      }
      const loginStatusOff = '0'
      let result2 = await queryRemindLoginStatusList(loginStatusOff, this.query)
      if (result.success) {
        this.tableData2 = result2.data
        this.loginStatusOffTotal = result2.data.total
      }
    },
    //分页-size
    handleSizeChange(val) {
      this.query.pageSize = val
      this.getRemindLoginStatusList()
    },
    //分页-page
    handleCurrentChange(val) {
      this.query.page = val
      this.getRemindLoginStatusList()
    },
    //分页-size
    handleSizeChange2(val) {
      this.query2.pageSize = val
      this.getRemindLoginStatusList()
    },
    //分页-page
    handleCurrentChange2(val) {
      this.query2.page = val
      this.getRemindLoginStatusList()
    },
    //分页-size
    handleSizeChange3(val) {
      this.query2.pageSize = val
      this.getDataInit()
    },
    //分页-page
    handleCurrentChange3(val) {
      this.query2.page = val
      this.getDataInit()
    },
    callbackRow(index) {
      console.log(index)
      this.privacyCallShow = true
    },
    showPrivacyCall() {
      this.privacyCallShow = false
    }
  }
}
</script>
<style lang="less" scoped>
.header {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  line-height: 60px;
}
.listData {
  padding: 16px;
  .grid-content {
    height: 95px;
    display: flex;
    align-items: center;
    justify-content: center;
    .grid_inner {
      text-align: center;
      .data_number {
        font-size: 32px;
        font-weight: 500;
        padding-bottom: 8px;
      }
    }
  }
}
.searchBox {
  margin: 0 16px;
  padding: 16px;
  background: #ffffff;
  border: 1px solid #c7cfcf;
  .searchTit {
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 16px;
  }
  .key_group {
    display: flex;
    .key_group_item {
      display: flex;
      align-items: center;
      margin-right: 24px;
      .key_tit {
        margin-right: 16px;
      }
    }
  }
}
.list {
  padding: 16px;
  .pageBox {
    height: 60px;
    display: flex;
    justify-content: end;
    align-items: center;
  }
}
</style>
