<template>
  <el-container>
    <el-dialog title="拒绝原因" :visible.sync="refuse" width="30%" @close="close" :close-on-click-modal="false">
      <div class="content">
        <div class="input_item">
          <div class="item_key">
            <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="textarea">
            </el-input>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="refuse = false">取 消</el-button>
        <el-button type="primary" @click="refuse = false">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>
<script>
export default {
  props: ['refuse'],
  data () {
    return {
      textarea: ''
    }
  },
  methods: {
    close () {
      this.$emit('closeRefuse')
    }
  }
}
</script>
<style lang="less" scoped>
.input_item {
  padding: 8px 0;
  .item_vlaue {
    padding-bottom: 8px;
  }
}
</style>
