<template>
  <el-container>
    <el-header class="header">押金收入列表</el-header>
    <el-main>
      <!-- 数据统计 -->
      <div class="listData">
        <el-row :gutter="8">
          <el-col :span="4">
            <div class="grid-content bg-purple" style="background: #e9fbfb">
              <div class="grid_inner">
                <div class="data_number">{{ userpayTotal }}</div>
                <div>总订单数(条)</div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- 列表 -->
      <div class="list">
        <el-table :data="tableData.rows" style="width: 100%">
          <el-table-column prop="id" label="ID" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="propertyName" label="所属物业" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="propertyArea" label="所属地区" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="villageName" label="所属小区" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="propertyAddress" label="小区地址" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="roomName" label="房号" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <p>
                {{ scope.row.floorName }}号楼 {{ scope.row.unitName }}单元 {{ scope.row.layerName }}层
                {{ scope.row.roomName }}室
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="ownerName" label="业主姓名" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="ownerPhone" label="联系电话" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="openMethod" label="开通方式" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <p>
                {{ scope.row.openMethod == 0 ? '自主开通' : '物业开通' }}
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="depositName" label="缴费名称" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="packageAmount" label="实付金额" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="paymentMethod" label="支付状态" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <p v-if="scope.row.paymentMethod == 0" :style="{ color: color }">未支付</p>
              <p v-if="scope.row.paymentMethod == 1">已支付</p>
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="申请时间" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <p>
                {{ formatDate(scope.row.createTime) }}
              </p>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="120">
            <template slot-scope="scope">
              <el-button @click.native.prevent="callbackRow(scope.$index, tableData)" type="text" size="small">
                电话
              </el-button>
              <el-button @click.native.prevent="seeRow(scope.$index, tableData)" type="text" size="small">
                查看
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="pageBox">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-sizes="[5, 10, 20]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="tableData.total"
          >
          </el-pagination>
        </div>
      </div>
    </el-main>
  </el-container>
</template>
<script>
import { queryDepositIncomeList } from '@/api/moneyMan.js'
export default {
  data() {
    return {
      query: {
        page: 1,
        pageSize: 10
      },
      tableData: [],
      userpayTotal: 0
    }
  },
  created() {
    this.init()
  },
  methods: {
    //初始化表格
    async init() {
      let result = await queryDepositIncomeList(this.query)
      if (result.success) {
        this.tableData = result.data
        this.userpayTotal = result.data.total
      }
    },
    //分页-size
    handleSizeChange(val) {
      this.query.pageSize = val
      this.init()
    },
    //分页-page
    handleCurrentChange(val) {
      this.query.page = val
      this.init()
    }
  }
}
</script>
<style lang="less" scoped>
.header {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  line-height: 60px;
}
.listData {
  padding: 16px;
  .grid-content {
    height: 95px;
    display: flex;
    align-items: center;
    justify-content: center;
    .grid_inner {
      text-align: center;
      .data_number {
        font-size: 32px;
        font-weight: 500;
        padding-bottom: 8px;
      }
    }
  }
}
.searchBox {
  margin: 0 16px;
  padding: 16px;
  background: #ffffff;
  border: 1px solid #c7cfcf;
  .searchTit {
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 16px;
  }
  .key_group {
    display: flex;
    .key_group_item {
      display: flex;
      align-items: center;
      margin-right: 24px;
      .key_tit {
        margin-right: 16px;
      }
    }
  }
}
.list {
  padding: 16px;
  .pageBox {
    height: 60px;
    display: flex;
    justify-content: end;
    align-items: center;
  }
}
</style>
