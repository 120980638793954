import request from '@/utils/request'

// APP运营 - 首页轮播图 - 保存
export function addAppOperateImg (data) {
  return request({
    url: `/sys/appImg`,
    method: 'post',
    data: data,
  })
}
// APP运营 - 首页轮播图 - 删除
export function deleteAppOperateImg (id) {
  return request({
    url: `/sys/appImg/` + id,
    method: 'delete',
  })
}
// APP运营 - 首页轮播图 - 列表
export function allAppOperateImg (data) {
  return request({
    url: `/sys/appImg/findAll`,
    method: 'post',
    data:data
  })
}
// APP运营 - 首页轮播图 - 编辑
export function uploadOperateImg (id,data) {
  return request({
    url: `/sys/appImg/` + id,
    method: 'put',
    data:data
  })
}
// 首页配置 - 查询全部
export function appOperationPageFindAll (data) {
  return request({
    url: `/sys/userAppOperation/pageFindAll`,
    method: 'get',
    params:data
  })
}
// 首页配置 - 新增
export function addAppOperateConfig (data) {
  return request({
    url: '/sys/userAppOperation',
    method: 'post',
    data: data,
  })
}
// 首页配置 - 更新
export function putAppOperateConfig (data) {
  return request({
    url: '/sys/userAppOperation/'+data.id,
    method: 'put',
    data: data,
  })
}
// 首页配置 - 删除
export function delAppOperateConfig (id) {
  return request({
    url: '/sys/userAppOperation/'+id,
    method: 'delete',
  })
}
// 服务商品列表 - 查询全部
export function getServiceGoodsFindAll () {
  return request({
    url: `/sp/serviceGoods/findAll`,
    method: 'get',
  })
}
// 服务分类列表 - 查询全部
export function getServiceItemsFindAll () {
  return request({
    url: `/sp/serviceItems/findAll`,
    method: 'get',
  })
}