<template>
  <el-container>
    <el-main>
      <el-row>
        <el-col :span="4" class="leftBox">
          <div class="left_titBox">
            <div class="l_t_txt">分类</div>
          </div>
          <div class="left_contentBox">
            <div class="l_c_items" v-for="(items, v) in navDataList" :key="v">
              <div class="l_c_i_txt" :class="{ active: isActive == v }" @click="getCashCouponClassToList(items, v)">
                {{ items.couponClass }}
              </div>
            </div>
          </div>
        </el-col>
        <!-- 列表 -->
        <div class="rightBox">
          <div class="rightBtn">
            <div class="leftTit">代金券</div>
            <div>
              <el-button type="primary" @click="OpenAddCashCoupon">单个新增</el-button>
              <el-button type="primary" @click="OpenAddCashCoupon2">批量新增</el-button>
            </div>
          </div>
          <el-col class="list" :span="20">
            <el-table :data="tableData.rows" style="width: 100%">
              <el-table-column prop="id" label="ID" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column label="分类名称" :show-overflow-tooltip="true">
                <template>
                  <p>{{ couponClass }}</p>
                </template>
              </el-table-column>
              <el-table-column prop="couponName" label="名称" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="couponAmount" label="金额" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="couponNumber" label="编号" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="writeOff" label="状态" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <p>
                    {{ scope.row.writeOff == 0 ? '可用' : '已用' }}
                  </p>
                </template>
              </el-table-column>
              <el-table-column fixed="right" label="操作" width="120">
                <template slot-scope="scope">
                  <el-button @click.native.prevent="editCashCouponDataInfo(scope.row)" type="text" size="small">
                    编辑
                  </el-button>
                  <el-button @click.native.prevent="deleteRow(scope.row)" type="text" size="small"> 删除 </el-button>
                </template>
              </el-table-column>
            </el-table>
            <div class="pageBox">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :page-sizes="[5, 10, 20]"
                layout="total, sizes, prev, pager, next, jumper"
                :total="tableData.total"
              >
              </el-pagination>
            </div>
          </el-col>
        </div>
      </el-row>
      <!-- 添加 -->
      <addCashCoupon
        :addCashCoupon="addCashCouponShow"
        :title="title"
        @closeDlg="addCashCouponShow = false"
        :addCashCouponInfo="addCashCouponInfo"
        :navDataList="navDataList"
        :cashCouponDataInfo="cashCouponDataInfo"
        :editCashCouponData="editCashCouponData"
      ></addCashCoupon>
      <AddCashBatch
        :isShow="addShow"
        :addCashCouponInfoBatch="addCashCouponInfoBatch"
        @closeDlg="addShow = false"
        :addCashCouponInfo="addCashCouponInfoBatch"
      ></AddCashBatch>
    </el-main>
  </el-container>
</template>
<script>
import {
  queryCashCouponAll,
  queryCashCouponClassToList,
  addCashCoupon,
  editCashCoupon,
  deleteCashCoupon,
  addCashCouponBatch
} from '@/api/activity.js'
import AddCashCoupon from '@/components/activity/addCashCoupon'
import AddCashBatch from '@/components/activity/addCashBatch'

export default {
  components: {
    AddCashCoupon: AddCashCoupon,
    AddCashBatch
  },
  data() {
    return {
      addCashCouponShow: false,
      addShow: false,
      isActive: 0,
      input: '',
      navDataList: [],
      tableData: [],
      query: {
        page: 1,
        pageSize: 10
      },
      couponClass: '',
      cashCouponDataInfo: '',
      title: '',
      knowClassDataInfo: ''
    }
  },
  mounted() {
    this.getCashCouponAll()
  },
  methods: {
    //获取分类
    async getCashCouponAll() {
      let result = await queryCashCouponAll(this.query)
      if (result.success) {
        this.navDataList = result.data.rows
        this.couponClass = this.navDataList[0].couponClass
        let res = await queryCashCouponClassToList(this.navDataList[0].couponClass, this.query)
        if (res.success) {
          this.tableData = res.data
        }
        console.log(res)
      }
    },
    // 获取分类下内容
    getCashCouponClassToList(items, v) {
      this.isActive = v
      this.couponClass = items.couponClass
      this.getCash()
    },
    async getCash() {
      let res = await queryCashCouponClassToList(this.couponClass, this.query)
      if (res.success) {
        this.tableData = res.data
      }
    },
    //分页-size
    handleSizeChange(val) {
      this.query.pageSize = val
      this.getCash()
    },
    //分页-page
    handleCurrentChange(val) {
      this.query.page = val
      this.getCash()
    },
    // 去新增
    OpenAddCashCoupon() {
      this.addCashCouponShow = true
      this.title = '新增'
    },
    // 去批量新增
    OpenAddCashCoupon2() {
      this.addShow = true
    },
    // 批量新增接口
    async addCashCouponInfo(data) {
      let res = await addCashCoupon(data)
      if (res.success) {
        this.$message({
          type: 'success',
          message: '批量新增成功!'
        })
        this.addShow = false
        this.getCash()
      } else {
        this.$message({
          type: 'error',
          message: '批量新增失败!'
        })
      }
    },
    // 新增接口
    async addCashCouponInfoBatch(data) {
      console.log(':sfdsafjfkoldskl')
      let res = await addCashCouponBatch(data)
      if (res.success) {
        this.$message({
          type: 'success',
          message: '新增成功!'
        })
        this.addCashCouponShow = false
        this.getCash()
      } else {
        this.$message({
          type: 'error',
          message: '新增失败!'
        })
      }
    },
    // 去编辑
    editCashCouponDataInfo(row) {
      this.cashCouponDataInfo = row
      this.addCashCouponShow = true
      this.title = '编辑'
    },
    // 编辑接口
    async editCashCouponData(data) {
      let res = await editCashCoupon(data.id, data)
      if (res.success) {
        this.$message({
          type: 'success',
          message: '编辑成功!'
        })
        this.addCashCouponShow = false
        this.getCash()
      } else {
        this.$message({
          type: 'error',
          message: '编辑失败!'
        })
      }
    },
    // 删除
    deleteRow(row) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      })
        .then(async () => {
          let res = await deleteCashCoupon(row.id)
          if (res.success) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.getCash()
          } else {
            this.$message({
              type: 'error',
              message: '删除失败'
            })
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    }
  }
}
</script>
<style lang="less" scoped>
.listData {
  padding: 16px;
  .grid-content {
    height: 95px;
    display: flex;
    align-items: center;
    justify-content: center;
    .grid_inner {
      text-align: center;
      .data_number {
        font-size: 32px;
        font-weight: 500;
        padding-bottom: 8px;
      }
    }
  }
}
.searchBox {
  margin: 0 16px;
  padding: 16px;
  background: #ffffff;
  border: 1px solid #c7cfcf;
  .searchTit {
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 16px;
  }
  .key_group {
    display: flex;
    .key_group_item {
      display: flex;
      align-items: center;
      margin-right: 24px;
      .key_tit {
        margin-right: 16px;
      }
    }
  }
}
.active {
  color: #09bebe;
}
.rightBtn {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 500;
}
.leftBox {
  border-right: #c7cfcf 0.5px solid;
  height: calc(100vh - 76px);
  .left_titBox {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .l_t_txt {
      font-size: 16px;
      font-weight: 500;
    }
    .l_t_btn {
      font-size: 14px;
      color: #09bebe;
      cursor: pointer;
    }
  }
  .left_contentBox {
    .l_c_items {
      padding: 16px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .l_c_i_dele {
        color: #be0909;
        display: none;
      }
      .l_c_i_edit {
        color: #09bebe;
        display: none;
        margin-right: 16px;
      }
      .l_c_i_btn {
        display: flex;
        justify-content: space-between;
      }
    }
    .l_c_items:hover {
      background: #f2f3f4;
      .l_c_i_dele {
        display: block;
      }
      .l_c_i_edit {
        display: block;
      }
    }
  }
}
.list {
  padding: 16px;
  .pageBox {
    height: 60px;
    display: flex;
    justify-content: end;
    align-items: center;
  }
}
</style>
