<template>
  <el-container>
    <el-dialog
      title="退款/售后详情"
      :visible.sync="refundOrder"
      width="40%"
      @close="close"
      append-to-body
      :close-on-click-modal="false"
    >
      <div class="content">
        <el-row class="content_topBox">
          <el-col :span="6" class="ct_items"> 提交申请 </el-col>
          <el-col :span="6" class="ct_items"> 菏康受理 </el-col>
          <el-col :span="6" class="ct_items"> 商家受理 </el-col>
          <el-col :span="6" class="ct_items"> 已完成 </el-col>
        </el-row>
        <div class="content_innerBox" v-if="refundInfo.serviceType == 0">
          <div class="serviceType">确认退款</div>
          <div>
            <p>
              <span>退款金额：</span>
              <span>¥{{ refundInfo.refundAmount }}元</span>
            </p>
            <p>
              退款状态：{{
                refundInfo.refundPayStatus == 1 ? '退款成功' : refundInfo.refundPayStatus == 2 ? '退款失败' : '待退款'
              }}
            </p>
            <el-button type="primary" @click="openRefundById()" size="small" v-if="refundInfo.refundPayStatus != 1"
              >退款</el-button
            >
          </div>
        </div>
        <div class="content_innerBox" v-if="refundInfo?.spBusId">
          <div class="serviceType">商家受理</div>
          <div>
            <p>
              <span>受理结果：</span>
              <span>{{
                refundInfo.spBusResult == null
                  ? '待受理'
                  : refundInfo.spBusResult == 0
                  ? '未通过'
                  : refundInfo.spBusResult == 1
                  ? '已通过'
                  : ''
              }}</span>
            </p>
            <p>备注:{{ refundInfo.spBusResultNotes }}</p>
          </div>
        </div>
        <div>
          <div class="serviceType">菏康受理</div>
          <div v-if="refundInfo.hkResult != null">
            <p>
              <span>受理结果：</span>
              <span style="padding-right: 24px">{{
                refundInfo.hkResult == null
                  ? '待受理'
                  : refundInfo.hkResult == 0
                  ? '未通过'
                  : refundInfo.hkResult == 1
                  ? '已通过'
                  : ''
              }}</span>
            </p>
            <p v-if="refundInfo.serviceType == 0">
              <span v-if="refundInfo.totalAmount != null">退款金额：¥{{ refundInfo.totalAmount }}元</span>
            </p>
            <p v-if="refundInfo.serviceType == 1">
              <span v-if="refundInfo.subsidyAmount != null">补贴金额：¥{{ refundInfo.subsidyAmount }}元</span>
              <span> 补贴金额仅在下次购买时抵扣</span>
            </p>
            <p>备注：{{ refundInfo.hkResultNotes }}</p>
          </div>
          <div v-if="refundInfo.hkResult == null" class="ci_items">
            <el-form :model="refundFormData" label-width="80px">
              <el-form-item label="审核结果" label-width="100px">
                <el-select v-model="refundFormData.hkResult" placeholder="请选择">
                  <el-option v-for="item in resultJson" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label="退款金额(元)"
                label-width="100px"
                v-if="refundInfo.serviceType == 0"
                class="refundAmound"
              >
                <el-input :disabled="true" v-model="refundInfo.totalAmount"></el-input>
              </el-form-item>
              <el-form-item label="补贴比例" v-if="refundInfo.serviceType == 1" label-width="100px">
                <el-select v-model="scale" placeholder="请选择">
                  <el-option v-for="item in resultScaleJson" :key="item.label" :label="item.label" :value="item.label">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="备注" label-width="100px">
                <el-input type="textarea" v-model="refundFormData.hkResultNotes"></el-input>
              </el-form-item>
              <el-form-item label-width="100px">
                <el-button type="primary" @click="onSubmit()" size="small">审核</el-button>
                <el-button size="small">取消</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div>
          <div class="serviceType">申请类型：{{ refundInfo.serviceType == 0 ? '退款' : '售后' }}</div>
          <div>
            <p>订单编号：{{ refundInfo.userOrderId }}</p>
            <p>实付金额：¥{{ refundInfo.totalAmount }}元</p>
            <p>补差金额：¥{{ refundInfo.repairAmount }}元</p>
            <p>退款编号：{{ refundInfo.id }}</p>
            <p>申请时间：{{ refundInfo.createTime }}</p>
            <p>申请原因：{{ refundInfo.serviceCause }}</p>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="refundOrder = false">关闭</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>
<script>
import {
  queryUserOrdersRefundById,
  queryOrderRefundById,
  sysConfirmRemittanceByRefundId
} from '@/api/serviceProvider.js'
export default {
  props: ['refundOrder', 'refundId'],
  data() {
    return {
      refundId: null,
      refundInfo: {},
      refundFormData: {
        hkResult: null,
        hkResultNotes: null,
        subsidyAmount: null
      },
      scale: null, //补贴比例
      resultJson: [
        {
          label: '同意',
          value: 1
        },
        {
          label: '拒绝',
          value: 0
        }
      ],
      resultScaleJson: [
        {
          label: '10%'
        },
        {
          label: '15%'
        },
        {
          label: '20%'
        },
        {
          label: '25%'
        },
        {
          label: '30%'
        },
        {
          label: '35%'
        },
        {
          label: '40%'
        }
      ]
    }
  },
  watch: {
    refundId() {
      this.refundId = this.refundId
      console.log(this.refundId)
      this.getRefundDetails()
    }
  },
  mounted() {},
  methods: {
    // 查询退款/售后详情
    async getRefundDetails() {
      let res = await queryUserOrdersRefundById(this.refundId)
      if (res.success) {
        this.refundInfo = res.data
      }
      console.log('d', this.refundInfo)
    },
    close() {
      this.$emit('closeRefundOrder')
    },
    // 提交审核
    async onSubmit() {
      let res = await queryOrderRefundById(this.refundFormData, this.refundInfo.id, this.scale)
      console.log('dddd', res)
      if (res.success) {
        this.$message({
          message: '审核成功！',
          type: 'success'
        })
        this.getRefundDetails()
      } else {
        this.$message.error('审核失败，请退出重试！')
      }
    },
    // 确认退款
    async openRefundById() {
      this.$confirm('请确认审核流程已完成, 是否退款?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          let id = this.refundInfo.id
          let res = await sysConfirmRemittanceByRefundId(id)
          if (res.success) {
            this.getRefundDetails()
            this.$message({
              type: 'success',
              message: '退款成功!'
            })
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消退款'
          })
        })
    }
  }
}
</script>
<style lang="less" scoped>
.itemsBox {
  padding-top: 24px;
}
.el-select {
  width: 100%;
}
.content_topBox {
  padding: 24px;
  background-color: #09bebe;
  .ct_items {
    border-top-color: rgba(255, 255, 255, 0.4);
    border-top-width: 4px;
    border-top-style: solid;
    text-align: center;
    padding-top: 8px;
    color: #fff;
  }
}
.serviceType {
  font-size: 16px;
  font-weight: bold;
  padding-top: 24px;
}
.ci_items {
  padding-top: 24px;
}
</style>
