import request from '@/utils/request'

// 树形获取全部分类信息
export function getTreeFindAll() {
    return request({
        url: '/sys/hkUnionGoodsItems/treeFindAll',
        method: 'get',
    })
}
// 新增分类
export function addGoodsClass(data) {
    return request({
        url: '/sys/hkUnionGoodsItems',
        method: 'post',
        data: data
    })
}
// 编辑分类
export function putEditGoodsClass(data, id) {
    return request({
        url: '/sys/hkUnionGoodsItems/' + id,
        method: 'put',
        data: data
    })
}
// 删除分类
export function deleteGoodsClass(id) {
    return request({
        url: '/sys/hkUnionGoodsItems/' + id,
        method: 'delete',
    })
}
// 根据分类获取列表
export function getClassToGoodsledgeData(id, data) {
    return request({
        url: '/sys/hkUnionGoods/fourList/' + id,
        method: 'get',
        params: data
    })
}
// 新增
export function addGoods(data) {
    return request({
        url: '/sys/hkUnionGoods',
        method: 'post',
        data: data
    })
}
// 编辑
export function editGoods(data, id) {
    return request({
        url: '/sys/hkUnionGoods/' + id,
        method: 'put',
        data: data
    })
}
// 删除
export function deleteGoods(id) {
    return request({
        url: '/sys/hkUnionGoods/' + id,
        method: 'delete'
    })
}