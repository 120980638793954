<template>
  <el-container>
    <el-dialog
      :title="title"
      :visible.sync="addSpSet"
      width="40%"
      @close="close"
      append-to-body
      :close-on-click-modal="false"
    >
      <div class="content">
        <div class="input_item" :model="formData" ref="formData">
          <div class="itemsBox">
            <div class="item_vlaue">详情</div>
            <div class="item_key">
              <el-input type="textarea" v-model="formData.setJson" placeholder="请输入内容"></el-input>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addSpSet = false">取 消</el-button>
        <el-button type="primary" @click="getAddSpSet()">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>
<script>
export default {
  props: ['addSpSet', 'title', 'spSetInfo', 'openAddSpSet', 'openEditSpSet'],
  data() {
    return {
      title: '',
      formData: {
        id: '',
        setJson: ''
      }
    }
  },
  watch: {
    spSetInfo() {
      this.formData = this.spSetInfo
      console.log('sss', this.formData)
    },
    title() {
      this.title = this.title
    }
  },
  methods: {
    close() {
      this.$emit('closeAddSpSet')
    },
    async getAddSpSet() {
      if (this.title == '新增') {
        this.openAddSpSet(this.formData)
        this.addSpSet = false
      } else if (this.title == '编辑') {
        console.log('dd', this.formData)
        this.openEditSpSet(this.formData)
        this.addSpSet = false
      }
    }
  }
}
</script>
<style lang="less" scoped>
.itemsBox {
  padding-top: 24px;
}
.input_item {
  padding: 8px 0;
  .item_vlaue {
    padding-bottom: 8px;
  }
  .select {
    width: 100%;
  }
}
</style>
