<template>
  <el-container>
    <el-dialog
      :title="title"
      :visible.sync="addMemberCard"
      width="20%"
      @close="close"
      append-to-body
      :close-on-click-modal="false"
    >
      <div class="content">
        <div class="input_item" :model="formData" ref="formData">
          <div>
            <div class="item_vlaue">卡名</div>
            <div class="item_key">
              <el-input v-model="formData.cardName" placeholder="请输入内容"></el-input>
            </div>
          </div>
          <div class="itemsBox">
            <div class="item_vlaue">售价</div>
            <div class="item_key">
              <el-input v-model="formData.orderPrice" placeholder="请输入内容"></el-input>
            </div>
          </div>
          <div class="itemsBox">
            <div class="item_vlaue">描述</div>
            <div class="item_key">
              <el-input type="textarea" v-model="formData.cardDec" placeholder="请输入内容"></el-input>
            </div>
          </div>
          <div class="itemsBox">
            <div class="item_vlaue">折扣</div>
            <div class="item_key">
              <el-input type="textarea" v-model="formData.cardDiscount" placeholder="请输入内容"></el-input>
            </div>
          </div>
          <div class="itemsBox">
            <div class="item_vlaue">卡详情</div>
            <div class="item_key">
              <el-input type="textarea" v-model="formData.cardContent" placeholder="请输入内容"></el-input>
            </div>
          </div>
          <div class="itemsBox">
            <div class="item_vlaue">协议</div>
            <div class="item_key">
              <el-input type="textarea" v-model="formData.agreement" placeholder="请输入内容"></el-input>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addMemberCard = false">取 消</el-button>
        <el-button type="primary" @click="getAddKnowClassInfo()">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>
<script>
export default {
  props: ['addMemberCard', 'addMemberCardInfo', 'title', 'memberCardDataInfo', 'editMemberCard'],
  data() {
    return {
      formData: {
        id: '',
        cardName: '',
        orderPrice: '',
        cardDec: '',
        cardDiscount: '',
        cardContent: '',
        agreement: ''
      },
      addMemberCardShow: this.addMemberCard
    }
  },
  watch: {
    memberCardDataInfo() {
      this.formData.cardName = this.memberCardDataInfo.cardName
      this.formData.orderPrice = this.memberCardDataInfo.orderPrice
      this.formData.cardDec = this.memberCardDataInfo.cardDec
      this.formData.cardDiscount = this.memberCardDataInfo.cardDiscount
      this.formData.cardContent = this.memberCardDataInfo.cardContent
      this.formData.agreement = this.memberCardDataInfo.agreement
      this.formData.id = this.memberCardDataInfo.id
    }
  },
  methods: {
    close() {
      this.$emit('closeAddMemberCard')
      this.formData.cardName = ''
      this.formData.cardDec = ''
      this.formData.cardDiscount = ''
      this.formData.cardContent = {}
    },
    async getAddKnowClassInfo() {
      if (this.title == '新增会员卡') {
        this.addMemberCardInfo(this.formData)
        this.addMemberCard = false
        this.formData.cardName = ''
        this.formData.cardDec = ''
        this.formData.cardDiscount = ''
        this.formData.cardContent = {}
      } else if (this.title == '编辑会员卡') {
        this.editMemberCard(this.formData)
        this.addMemberCard = false
      }
    }
  }
}
</script>
<style lang="less" scoped>
.itemsBox {
  padding-top: 24px;
}
.input_item {
  padding: 8px 0;
  .item_vlaue {
    padding-bottom: 8px;
  }
  .select {
    width: 100%;
  }
}
</style>
