<template>
  <el-container>
    <el-main>
      <el-row>
        <!-- 列表 -->
        <div class="minBox">
          <div class="rightBtn">
            <div class="leftTit">设备押金</div>
            <el-button type="primary" @click="OpenAddDeposit">新增设备押金</el-button>
          </div>
          <el-col class="list" :span="24">
            <el-table :data="tableData" style="width: 100%">
              <el-table-column prop="id" label="ID" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="depositName" label="名称" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="depositMoney" label="金额" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="createTime" label="创建时间" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column fixed="right" label="操作" width="120">
                <template slot-scope="scope">
                  <el-button @click.native.prevent="openEditDeposit(scope.row)" type="text" size="small">
                    编辑
                  </el-button>
                  <el-button @click.native.prevent="openDeleteGoods(scope.row)" type="text" size="small">
                    删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </div>
      </el-row>
      <!-- 添加-->
      <addDeposit
        :addDeposit="addDepositShow"
        :title="title"
        @closeAddDeposit="showAddDeposit"
        :addDepositInfo="addDepositInfo"
        :depositDataInfo="depositDataInfo"
        :editDepositData="editDepositData"
      ></addDeposit>
    </el-main>
  </el-container>
</template>
<script>
import { queryDepositAll, addDeposit, editDeposit, deleteDeposit } from '@/api/deposit.js'
import AddDeposit from '@/components/deposit/addDeposit'
export default {
  components: {
    AddDeposit: AddDeposit
  },
  data() {
    return {
      addDepositShow: false,
      tableData: [],
      depositDataInfo: '',
      title: '',
      noticeClassDataInfo: '',
      query: {
        page: 1,
        pageSize: 10
      }
    }
  },
  mounted() {
    this.getDepositAll()
  },
  methods: {
    //获取列表信息
    async getDepositAll() {
      let result = await queryDepositAll(this.query)
      if (result.success) {
        this.tableData = result.data
      }
      console.log(result)
    },
    // 去新增
    async OpenAddDeposit() {
      this.addDepositShow = true
      this.title = '新增'
    },
    showAddDeposit() {
      this.addDepositShow = false
    },
    // 新增接口
    async addDepositInfo(data) {
      let res = await addDeposit(data)
      if (res.success) {
        this.$message({
          type: 'success',
          message: '新增成功!'
        })
      } else {
        this.$message({
          type: 'error',
          message: '新增失败!'
        })
      }
      this.getDepositAll()
    },
    // 去编辑
    openEditDeposit(row) {
      console.log(row)
      this.depositDataInfo = row
      this.addDepositShow = true
      this.title = '编辑'
    },
    // 编辑接口
    async editDepositData(data) {
      let res = await editDeposit(data.id, data)
      if (res.success) {
        this.$message({
          type: 'success',
          message: '编辑成功!'
        })
        this.getDepositAll()
      } else {
        this.$message({
          type: 'error',
          message: '编辑失败!'
        })
      }
    },
    // 删除知识
    openDeleteGoods(row) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      })
        .then(async () => {
          let res = await deleteDeposit(row.id)
          if (res.success) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.getDepositAll()
          } else {
            this.$message({
              type: 'error',
              message: '删除失败'
            })
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    }
  }
}
</script>
<style lang="less" scoped>
.listData {
  padding: 16px;
  .grid-content {
    height: 95px;
    display: flex;
    align-items: center;
    justify-content: center;
    .grid_inner {
      text-align: center;
      .data_number {
        font-size: 32px;
        font-weight: 500;
        padding-bottom: 8px;
      }
    }
  }
}
.searchBox {
  margin: 0 16px;
  padding: 16px;
  background: #ffffff;
  border: 1px solid #c7cfcf;
  .searchTit {
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 16px;
  }
  .key_group {
    display: flex;
    .key_group_item {
      display: flex;
      align-items: center;
      margin-right: 24px;
      .key_tit {
        margin-right: 16px;
      }
    }
  }
}
.active {
  color: #09bebe;
}
.rightBtn {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 500;
}
.leftBox {
  border-right: #c7cfcf 0.5px solid;
  height: calc(100vh - 76px);
  .left_titBox {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .l_t_txt {
      font-size: 16px;
      font-weight: 500;
    }
    .l_t_btn {
      font-size: 14px;
      color: #09bebe;
      cursor: pointer;
    }
  }
  .left_contentBox {
    .l_c_items {
      padding: 16px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .l_c_i_dele {
        color: #be0909;
        display: none;
      }
      .l_c_i_edit {
        color: #09bebe;
        display: none;
        margin-right: 16px;
      }
      .l_c_i_btn {
        display: flex;
        justify-content: space-between;
      }
    }
    .l_c_items:hover {
      background: #f2f3f4;
      .l_c_i_dele {
        display: block;
      }
      .l_c_i_edit {
        display: block;
      }
    }
  }
}
.list {
  padding: 16px;
  .pageBox {
    height: 60px;
    display: flex;
    justify-content: end;
    align-items: center;
  }
}
</style>
