import request from '@/utils/request'

// 居民管理 - 接入进度
export function queryProcess (data) {
  console.log(data)
  return request({
    url: '/sys/userReview/list',
    method: 'POST',
    data: data
  })
}

// 居民管理 - 档案列表
export function queryProList (data) {
  console.log(data)
  return request({
    url: '/sys/userProfile',
    method: 'POST',
    data: data
  })
}

// 居民管理 - 查询所有服务总人数
export function queryMemberAllTotal (params) {
  console.log(params)
  return request({
    url: `/sys/userFamily`,
    method: 'get',
    params: params

  })
}

// 居民管理 - 本月新增
export function queryUserThisMonthList (data) {
  return request({
    url: `/sys/userProfile/thisMonth`,
    method: 'POST',
    data: data
  })
}
// 居民管理 - 家庭成员列表
export function queryMemberById (id, params) {
  console.log(params)
  return request({
    url: `/sys/userFamily/list/${id}`,
    method: 'get',
    params: params

  })
}

// 居民管理 - 家庭成员列表
export function confirmById (No) {
  return request({
    url: `/sys/userFamily/acrdNo/${No}`,
    method: 'POST',
  })
}


// 居民管理 - 查询成员信息
export function queryDtById (id) {
  return request({
    url: `/sys/userFamily/${id}`,
    method: 'get',
  })
}