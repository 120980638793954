<template>
  <el-container>
    <el-header class="header">设备开通</el-header>
    <el-main>
      <div>暂缓</div>
    </el-main>
  </el-container>
</template>
<script>
import Rescind from '@/components/archives/rescind'
import PrivacyCall from '@/components/public/privacyCall'
export default {
  props: ['rescind', 'PrivacyCall'],
  components: {
    rescind: Rescind,
    privacyCall: PrivacyCall
  },
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>
<style lang="less" scoped>
.header {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  line-height: 60px;
}
</style>
