<template>
  <el-container>
    <el-header class="header">
      <div class="leftTit">解约记录</div>
    </el-header>
    <el-main>
      <!-- 列表 -->
      <div class="list">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column prop="id" label="ID">
          </el-table-column>
          <el-table-column prop="name" label="所属物业">
          </el-table-column>
          <el-table-column prop="address" label="所属地区">
          </el-table-column>
          <el-table-column prop="date" label="小区名称">
          </el-table-column>
          <el-table-column prop="name" label="小区地址">
          </el-table-column>
          <el-table-column prop="address" label="登陆账号">
          </el-table-column>
          <el-table-column prop="address" label="负责人">
          </el-table-column>
          <el-table-column prop="address" label="联系电话">
          </el-table-column>
          <el-table-column prop="address" label="占地面积(m2)">
          </el-table-column>
          <el-table-column prop="address" label="总楼栋">
          </el-table-column>
          <el-table-column prop="address" label="总户数">
          </el-table-column>
          <el-table-column prop="address" label="开发商">
          </el-table-column>
          <el-table-column prop="address" label="终止原因">
          </el-table-column>
          <el-table-column prop="address" label="解约时间">
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="120">
            <template slot-scope="scope">
              <el-button @click.native.prevent="openDetailsRow(scope.$index, tableData)" type="text" size="small">
                查看
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-main>
  </el-container>
</template>
<script>
export default {
  data () {
    return {
      tableData: [],
    }
  },
  created () {
    this.init()
  },
  methods: {
    openDetailsRow (index) {
      console.log(index)
      this.$router.push({ path: '/pfileListDetails' })
    }
  }
}
</script>
<style lang="less" scoped>
.header {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  line-height: 60px;
  display: flex;
  justify-content: space-between;
}
.list {
  padding: 16px;
}
</style>
