import request from '@/utils/request'

// 活动管理 - 代金券全部分类
export function queryCashCouponAll (params) {
  console.log(params)
  return request({
    url: '/sys/activity/cashCoupon/couponClassList',
    method: 'GET',
    params: params
  })
}
// 活动管理 - 根据代金券分类查询内容
export function queryCashCouponClassToList (couponClass,params) {
  console.log(params)
  return request({
    url: '/sys/activity/cashCoupon/list/' + couponClass,
    method: 'GET',
    params: params
  })
}
// 活动管理 - 单个新增代金券
export function addCashCoupon (data) {
  console.log(data)
  return request({
    url: '/sys/activity/cashCoupon',
    method: 'POST',
    data: data
  })
}
// 活动管理 - 批量新增代金券
export function addCashCouponBatch (data) {
  console.log(data)
  return request({
    url: '/sys/activity/cashCoupon/batchSave',
    method: 'POST',
    data: data
  })
}
// 活动管理 - 编辑代金券
export function editCashCoupon (id,data) {
  console.log(data)
  return request({
    url: '/sys/activity/cashCoupon/' + id,
    method: 'PUT',
    data: data
  })
}
// 活动管理 - 删除代金券
export function deleteCashCoupon (id) {
  return request({
    url: '/sys/activity/cashCoupon/'+id,
    method: 'DELETE'
  })
}