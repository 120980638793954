<template>
  <el-container>
    <el-header class="header">
      <div class="h_txt">公司列表</div>
      <el-button type="primary" @click="openaddHkCompany()">新增公司</el-button>
    </el-header>
    <el-main>
      <!-- 数据统计 -->
      <div class="listData">
        <el-row :gutter="8">
          <el-col :span="4">
            <div class="grid-content bg-purple" style="background: #e9fbfb">
              <div class="grid_inner">
                <div class="data_number">{{ hkCompanyTotal }}</div>
                <div>公司数量</div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- 精准查询 -->
      <div class="searchBox">
        <div class="searchTit">精准查询</div>
        <div class="key_group">
          <div class="key_group_item">
            <div class="key_tit">物业公司</div>
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </div>
          <div class="key_group_item">
            <div class="key_tit">所属地区</div>
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </div>
          <div class="key_group_item">
            <div class="key_tit">小区</div>
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </div>
          <div class="key_group_item">
            <div class="key_tit">房号</div>
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </div>
          <el-button type="primary">查询</el-button>
        </div>
      </div>
      <!-- 列表 -->
      <div class="list">
        <el-table :data="tableData.rows" style="width: 100%">
          <el-table-column prop="id" label="ID" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="companyName" label="公司名称" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="corporation" label="法人" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="superintendent" label="负责人" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="superintendentPhone" label="负责人电话" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="area" label="所属地区" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="createTime" label="创建时间" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <p>
                {{ formatDate(scope.row.createTime) }}
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="updateTime" label="更新时间" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <p>
                {{ formatDate(scope.row.updateTime) }}
              </p>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="120">
            <template slot-scope="scope">
              <el-button @click.native.prevent="editHkCompany(scope.row)" type="text" size="small"> 编辑 </el-button>
              <el-button @click.native.prevent="openDelHkCompany(scope.row)" type="text" size="small"> 删除 </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="pageBox">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-sizes="[5, 10, 20]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="tableData.total"
          >
          </el-pagination>
        </div>
      </div>
      <!-- 新增/编辑 -->
      <addHkCompany
        :addHkCompany="addHkCompanyShow"
        :title="title"
        @closeAddHkCompany="showAddHkCompany"
        :addHkCompanyInfo="addHkCompanyInfo"
        :hkCompanyDataInfo="hkCompanyDataInfo"
        :editHkCompanyData="editHkCompanyData"
      ></addHkCompany>
    </el-main>
  </el-container>
</template>
<script>
import AddHkCompany from '@/components/hkGroup/addHkCompany'
import { queryHkGroupList, addHkCompany, delHkCompany, updateHkCompany } from '@/api/hkGroup.js'
import { formatDate } from '@/utils/index.js'
export default {
  components: {
    addHkCompany: AddHkCompany
  },
  data() {
    return {
      addHkCompanyShow: false,
      hkCompanyDataInfo: '',
      formatDate: formatDate,
      title: '',
      query: {
        page: 1,
        pageSize: 10
      },
      tableData: [],
      hkCompanyTotal: '',
      options: [],
      value: ''
    }
  },
  created() {
    this.init()
  },
  methods: {
    //初始化表格
    async init() {
      let result = await queryHkGroupList(this.query)
      if (result.success) {
        this.tableData = result.data
        this.hkCompanyTotal = result.data.total
      }
    },
    //分页-size
    handleSizeChange(val) {
      this.query.pageSize = val
      this.init()
    },
    //分页-page
    handleCurrentChange(val) {
      this.query.page = val
      this.init()
    },
    openaddHkCompany() {
      this.addHkCompanyShow = true
      this.title = '新增'
    },
    // 新增接口
    async addHkCompanyInfo(data) {
      let res = await addHkCompany(data)
      if (res.success) {
        this.$message({
          type: 'success',
          message: '新增成功!'
        })
        this.init()
      } else {
        this.$message({
          type: 'error',
          message: '新增失败!'
        })
      }
    },
    showAddHkCompany() {
      this.addHkCompanyShow = false
    },
    // 编辑
    editHkCompany(items) {
      this.addHkCompanyShow = true
      this.hkCompanyDataInfo = items
      this.title = '编辑'
    },
    // 编辑接口
    async editHkCompanyData(data) {
      let res = await updateHkCompany(data, data.id)
      if (res.success) {
        this.$message({
          type: 'success',
          message: '编辑成功!'
        })
        let result = await queryHkGroupList()
        if (result.success) {
          this.tableData = result.data
        }
        this.addHkCompanyShow = false
      } else {
        this.$message({
          type: 'error',
          message: '编辑失败!'
        })
      }
    },
    // 删除
    async openDelHkCompany(items) {
      this.$confirm('此操作将永久删除该分类, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      })
        .then(async () => {
          let res = await delHkCompany(items.id)
          if (res.success) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.init()
          } else {
            this.$message({
              type: 'error',
              message: '删除失败'
            })
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    }
  }
}
</script>
<style lang="less" scoped>
.header {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .h_txt {
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    line-height: 60px;
  }
}
.listData {
  padding: 16px;
  .grid-content {
    height: 95px;
    display: flex;
    align-items: center;
    justify-content: center;
    .grid_inner {
      text-align: center;
      .data_number {
        font-size: 32px;
        font-weight: 500;
        padding-bottom: 8px;
      }
    }
  }
}
.searchBox {
  margin: 0 16px;
  padding: 16px;
  background: #ffffff;
  border: 1px solid #c7cfcf;
  .searchTit {
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 16px;
  }
  .key_group {
    display: flex;
    .key_group_item {
      display: flex;
      align-items: center;
      margin-right: 24px;
      .key_tit {
        margin-right: 16px;
      }
    }
  }
}
.list {
  padding: 16px;
  .pageBox {
    height: 60px;
    display: flex;
    justify-content: end;
    align-items: center;
  }
}
</style>
