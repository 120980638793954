<template>
  <el-container>
    <el-header class="header">已购保单</el-header>
    <el-main>
      <!-- 数据统计 -->
      <div class="listData">
        <el-row :gutter="8">
          <el-col :span="4">
            <div class="grid-content bg-purple" style="background: #e9fbfb">
              <div class="grid_inner">
                <div class="data_number">{{ userRoomTotal }}</div>
                <div>总户数(户)</div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- 精准查询 -->
      <div class="searchBox">
        <div class="searchTit">精准查询</div>
        <div class="key_group">
          <div class="key_group_item">
            <div class="key_tit">物业公司</div>
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </div>
          <div class="key_group_item">
            <div class="key_tit">所属地区</div>
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </div>
          <div class="key_group_item">
            <div class="key_tit">小区</div>
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </div>
          <div class="key_group_item">
            <div class="key_tit">房号</div>
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </div>
          <el-button type="primary">查询</el-button>
        </div>
      </div>
      <!-- 列表 -->
      <div class="list">
        <el-table :data="tableData.rows" style="width: 100%">
          <el-table-column prop="id" label="ID" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="propertyName" label="所属物业" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="propertyArea" label="所属地区" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="villageName" label="所属小区" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="propertyAddress" label="小区地址" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="floorName" label="房号" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <p>
                {{ scope.row.floorName }}号楼 {{ scope.row.unitName }}单元 {{ scope.row.layerName }}层
                {{ scope.row.roomName }}室
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="insureTitle" label="投保名称" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="insureEnterprise" label="理赔公司" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="policyNo" label="保单编号" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="insureStatus" label="保单状态" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="insureTerm" label="保单期限" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <p>{{ scope.row.insureTerm }} 年</p>
            </template> </el-table-column
          ><el-table-column prop="accessTime" label="投保日期" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <p>
                {{ formatDate(scope.row.createTime) }}
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="dueDate" label="截止日期" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <p>
                {{ formatDate(scope.row.dueDate) }}
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="insureTotal" label="总理赔额" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="insureName" label="投保人" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="insureMoney" label="投保金额" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="policyPdf" label="电子保单" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column fixed="right" label="操作" width="120">
            <template slot-scope="scope">
              <el-button @click.native.prevent="callbackRow(scope.row)" type="text" size="small"> 电话 </el-button>
              <el-button @click.native.prevent="renewalRow(scope.row)" type="text" size="small"> 续保 </el-button>
              <el-button @click.native.prevent="deleteRow(scope.row)" type="text" size="small"> 删除 </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="pageBox">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-sizes="[5, 10, 20]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="tableData.total"
          >
          </el-pagination>
        </div>
      </div>
      <!-- 隐私电话弹框 -->
      <privacyCall :privacyCall="privacyCallShow" @closePrivacyCall="showPrivacyCall"></privacyCall>
      <!-- 投保 -->
      <stayInsurance
        :stayInsuranceRow="stayInsuranceRow"
        :stayInsurance="stayInsuranceShow"
        @closeStayInsurance="showStayInsurance"
        :stayInsuranceInfo="stayInsuranceInfo"
      ></stayInsurance>
    </el-main>
  </el-container>
</template>
<script>
import Rescind from '@/components/archives/rescind'
import PrivacyCall from '@/components/public/privacyCall'
import StayInsurance from '@/components/public/stayInsurance'
import { queryInsureByAll, queryAddUserInsureInfo, queryDeleteUserInsureInfo } from '@/api/insurance.js'
import { formatDate } from '@/utils/index.js'
export default {
  props: ['rescind', 'PrivacyCall'],
  components: {
    rescind: Rescind,
    privacyCall: PrivacyCall,
    stayInsurance: StayInsurance
  },
  data() {
    return {
      rescindShow: false,
      privacyCallShow: false,
      stayInsuranceShow: false,
      formatDate: formatDate,
      query: {
        page: 1,
        pageSize: 10
      },
      options: [
        {
          value: '选项1',
          label: '黄金糕'
        },
        {
          value: '选项2',
          label: '双皮奶'
        },
        {
          value: '选项3',
          label: '蚵仔煎'
        },
        {
          value: '选项4',
          label: '龙须面'
        },
        {
          value: '选项5',
          label: '北京烤鸭'
        }
      ],
      value: '',
      tableData: [],
      // 总户数
      userRoomTotal: 0,
      // 获取需投保信息
      stayInsuranceRow: {}
    }
  },
  created() {
    this.init()
  },
  methods: {
    //初始化表格
    async init() {
      let result = await queryInsureByAll(this.query)
      if (result.success) {
        this.tableData = result.data
        this.userRoomTotal = result.data.total
      }
    },
    //分页-size
    handleSizeChange(val) {
      this.query.pageSize = val
      this.init()
    },
    //分页-page
    handleCurrentChange(val) {
      this.query.page = val
      this.init()
    },
    callbackRow(index) {
      this.privacyCallShow = true
    },
    showPrivacyCall() {
      this.privacyCallShow = false
    },
    renewalRow(row) {
      this.stayInsuranceRow = row
      this.stayInsuranceShow = true
    },
    showStayInsurance() {
      this.stayInsuranceShow = false
    },
    // 提交投保信息
    async stayInsuranceInfo(data) {
      let result = await queryAddUserInsureInfo(data)
      console.log(result)
      if (result.success) {
        this.$message.success('保单提交成功')
        this.init()
      }
    },
    // 删除投保信息
    async deleteRow(row) {
      this.$confirm('此操作将永久删除该投保信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      })
        .then(async () => {
          let id = row.id
          let res = await queryDeleteUserInsureInfo(id)
          if (res.success) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.init()
          } else {
            this.$message({
              type: 'error',
              message: '删除失败'
            })
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    }
  }
}
</script>
<style lang="less" scoped>
.header {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  line-height: 60px;
}
.listData {
  padding: 16px;
  .grid-content {
    height: 95px;
    display: flex;
    align-items: center;
    justify-content: center;
    .grid_inner {
      text-align: center;
      .data_number {
        font-size: 32px;
        font-weight: 500;
        padding-bottom: 8px;
      }
    }
  }
}
.searchBox {
  margin: 0 16px;
  padding: 16px;
  background: #ffffff;
  border: 1px solid #c7cfcf;
  .searchTit {
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 16px;
  }
  .key_group {
    display: flex;
    .key_group_item {
      display: flex;
      align-items: center;
      margin-right: 24px;
      .key_tit {
        margin-right: 16px;
      }
    }
  }
}
.list {
  padding: 16px;
  .pageBox {
    height: 60px;
    display: flex;
    justify-content: end;
    align-items: center;
  }
}
</style>
