<template>
  <el-container>
    <el-main>
      <el-row>
        <!-- 列表 -->
        <div class="minBox">
          <div class="rightBtn">
            <div class="leftTit">用户服务分类</div>
            <el-button type="primary" @click="OpenAddGoods">新增服务</el-button>
          </div>
          <el-col class="list" :span="24">
            <el-table :data="tableData" style="width: 100%">
              <el-table-column prop="id" label="ID" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="serviceName" label="服务名称" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="adText" label="广告标语" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="serviceAmount" label="服务金额" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="originalPrice" label="原价金额" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="serviceContent" label="服务内容" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="effectivePeriod" label="生效期" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="sold" label="已售数量" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="updateTime" label="更新时间" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="createTime" label="创建时间" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column fixed="right" label="操作" width="120">
                <template slot-scope="scope">
                  <el-button @click.native.prevent="openEditGoods(scope.row)" type="text" size="small">
                    编辑
                  </el-button>
                  <el-button @click.native.prevent="openDeleteGoods(scope.row)" type="text" size="small">
                    删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </div>
      </el-row>
      <!-- 添加知识 -->
      <addGoods
        :addGoods="addGoodsShow"
        :title="title"
        @closeAddGoods="showAddGoods"
        :addGoodsFormData="addGoodsFormData"
        :goodsDataInfo="goodsDataInfo"
        :editGoodsFormData="editGoodsFormData"
      ></addGoods>
    </el-main>
  </el-container>
</template>
<script>
import {
  addUserServiceGoods,
  getUserServiceGoodsAll,
  deleteUserServiceGoodsId,
  putUserServiceGoodsId
} from '@/api/serviceGoods.js'
import AddGoods from '@/components/serviceGoods/addGoods'
export default {
  components: {
    AddGoods: AddGoods
  },
  data() {
    return {
      addGoodsShow: false,
      tableData: [],
      goodsDataInfo: '', // 编辑知识
      title: '',
      noticeClassDataInfo: '' //编辑分类
    }
  },
  mounted() {
    this.getUserServiceGoodsAll()
  },
  methods: {
    //获取分类
    async getUserServiceGoodsAll() {
      let result = await getUserServiceGoodsAll()
      if (result.success) {
        this.tableData = result.data
      }
    },
    // 新增知识
    async OpenAddGoods() {
      this.addGoodsShow = true
      this.title = '新增服务'
    },
    showAddGoods() {
      this.addGoodsShow = false
    },
    // 新增知识接口
    async addGoodsFormData(data) {
      let res = await addUserServiceGoods(data)
      if (res.success) {
        this.$message({
          type: 'success',
          message: '新增服务成功!'
        })
      } else {
        this.$message({
          type: 'error',
          message: '新增服务失败!'
        })
      }
      this.getUserServiceGoodsAll()
    },
    // 编辑知识
    openEditGoods(row) {
      this.goodsDataInfo = row
      this.addGoodsShow = true
      this.title = '编辑服务'
    },
    // 编辑知识接口
    async editGoodsFormData(data) {
      let res = await putUserServiceGoodsId(data, data.id)
      if (res.success) {
        this.$message({
          type: 'success',
          message: '编辑服务成功!'
        })
        this.getUserServiceGoodsAll()
      } else {
        this.$message({
          type: 'error',
          message: '编辑通知失败!'
        })
      }
    },
    // 删除知识
    openDeleteGoods(row) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      })
        .then(async () => {
          let res = await deleteUserServiceGoodsId(row.id)
          if (res.success) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.getUserServiceGoodsAll()
          } else {
            this.$message({
              type: 'error',
              message: '删除失败'
            })
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    }
  }
}
</script>
<style lang="less" scoped>
.listData {
  padding: 16px;
  .grid-content {
    height: 95px;
    display: flex;
    align-items: center;
    justify-content: center;
    .grid_inner {
      text-align: center;
      .data_number {
        font-size: 32px;
        font-weight: 500;
        padding-bottom: 8px;
      }
    }
  }
}
.searchBox {
  margin: 0 16px;
  padding: 16px;
  background: #ffffff;
  border: 1px solid #c7cfcf;
  .searchTit {
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 16px;
  }
  .key_group {
    display: flex;
    .key_group_item {
      display: flex;
      align-items: center;
      margin-right: 24px;
      .key_tit {
        margin-right: 16px;
      }
    }
  }
}
.active {
  color: #09bebe;
}
.rightBtn {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 500;
}
.leftBox {
  border-right: #c7cfcf 0.5px solid;
  height: calc(100vh - 76px);
  .left_titBox {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .l_t_txt {
      font-size: 16px;
      font-weight: 500;
    }
    .l_t_btn {
      font-size: 14px;
      color: #09bebe;
      cursor: pointer;
    }
  }
  .left_contentBox {
    .l_c_items {
      padding: 16px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .l_c_i_dele {
        color: #be0909;
        display: none;
      }
      .l_c_i_edit {
        color: #09bebe;
        display: none;
        margin-right: 16px;
      }
      .l_c_i_btn {
        display: flex;
        justify-content: space-between;
      }
    }
    .l_c_items:hover {
      background: #f2f3f4;
      .l_c_i_dele {
        display: block;
      }
      .l_c_i_edit {
        display: block;
      }
    }
  }
}
.list {
  padding: 16px;
  .pageBox {
    height: 60px;
    display: flex;
    justify-content: end;
    align-items: center;
  }
}
</style>
