<template>
  <el-container>
    <el-header class="header">
      <div>会员卡管理</div>
      <div>
        <el-button type="primary" @click="addMemberCard">新增会员卡</el-button>
      </div>
    </el-header>
    <el-main>
      <!-- 列表 -->
      <div class="list">
        <el-table :data="tableData.rows" style="width: 100%">
          <el-table-column prop="id" label="ID" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="cardName" label="卡名" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="orderPrice" label="售价" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="cardDec" label="描述" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="cardDiscount" label="折扣" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="cardContent" label="卡详情" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="agreement" label="协议" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="updateTime" label="更新时间" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="createTime" label="创建时间" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column fixed="right" label="操作" width="120">
            <template slot-scope="scope">
              <el-button @click="openEditMemberCard(scope.row)" type="text" size="small"> 编辑 </el-button>
              <el-button @click="openDeleteMemberCard(scope.row)" type="text" size="small"> 删除 </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="pageBox">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-sizes="[5, 10, 20]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="tableData.total"
          >
          </el-pagination>
        </div>
      </div>
      <!-- 添加 -->
      <addMemberCard
        :title="title"
        :addMemberCard="addMemberCardShow"
        @closeAddMemberCard="showAddMemberCard"
        :addMemberCardInfo="addMemberCardInfo"
        :memberCardDataInfo="memberCardDataInfo"
        :editMemberCard="editMemberCard"
      ></addMemberCard>
    </el-main>
  </el-container>
</template>
<script>
import { queryMemberCardFindAll, addMemberCard, updateMemberCard, delMemberCard } from '@/api/memberManage.js'
import AddMemberCard from '@/components/memberManage/addMemberCard'
export default {
  components: {
    AddMemberCard: AddMemberCard
  },
  data() {
    return {
      addMemberCardShow: false,
      memberCardDataInfo: '',
      query: {
        page: 1,
        pageSize: 10
      },
      tableData: [],
      title: ''
    }
  },
  created() {
    this.init()
  },
  mounted() {},
  methods: {
    //初始化表格
    async init() {
      let result = await queryMemberCardFindAll(this.query)
      if (result.success) {
        this.tableData = result.data
      }
    },
    //分页-size
    handleSizeChange(val) {
      this.query.pageSize = val
      this.init()
    },
    //分页-page
    handleCurrentChange(val) {
      this.query.page = val
      this.init()
    },
    // 新增
    addMemberCard() {
      this.addMemberCardShow = true
      this.title = '新增会员卡'
    },
    showAddMemberCard() {
      this.addMemberCardShow = false
    },
    // 新增接口
    async addMemberCardInfo(data) {
      let res = await addMemberCard(data)
      if (res.success) {
        this.$message({
          type: 'success',
          message: '新增会员卡成功!'
        })
        this.init()
        this.addMemberCardShow = false
      } else {
        this.$message({
          type: 'error',
          message: '新增会员卡失败!'
        })
      }
      this.getKnowClassList()
    },
    // 编辑
    async openEditMemberCard(items) {
      this.addMemberCardShow = true
      this.memberCardDataInfo = items
      this.title = '编辑会员卡'
    },
    // 编辑接口
    async editMemberCard(data) {
      let res = await updateMemberCard(data)
      if (res.success) {
        this.$message({
          type: 'success',
          message: '编辑会员卡成功!'
        })
        this.init()
        this.addMemberCardShow = false
      } else {
        this.$message({
          type: 'error',
          message: '编辑会员卡失败!'
        })
      }
    },
    // 删除
    async openDeleteMemberCard(items) {
      this.$confirm('此操作将永久删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      })
        .then(async () => {
          let res = await delMemberCard(items.id)
          if (res.success) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.init()
          } else {
            this.$message({
              type: 'error',
              message: '删除失败'
            })
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    }
  }
}
</script>
<style lang="less" scoped>
.header {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  line-height: 60px;
  display: flex;
  justify-content: space-between;
}
.searchBox {
  margin: 0 16px;
  padding: 16px;
  background: #ffffff;
  border: 1px solid #c7cfcf;
  .searchTit {
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 16px;
  }
  .key_group {
    display: flex;
    .key_group_item {
      display: flex;
      align-items: center;
      margin-right: 24px;
      .key_tit {
        margin-right: 16px;
      }
    }
  }
}
.list {
  padding: 16px;
  .pageBox {
    height: 60px;
    display: flex;
    justify-content: end;
    align-items: center;
  }
}
.el-avatar {
  background: #f2f4f4;
}
</style>
