<template>
  <el-container>
    <el-dialog
      :title="title"
      :visible.sync="addDeposit"
      width="50%"
      @close="close"
      append-to-body
      :close-on-click-modal="false"
    >
      <div class="content">
        <el-form ref="formData" :model="formData" label-width="80px">
          <el-form-item label="名称:">
            <el-input v-model="formData.depositName" placeholder="名称"></el-input>
          </el-form-item>
          <el-form-item label="金额:">
            <div class="couponAmount">
              <el-input v-model="formData.depositMoney" placeholder="金额"></el-input>
              <p>元</p>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDeposit = false">取 消</el-button>
        <el-button type="primary" @click="getAddDepositInfo()">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>
<script>
export default {
  props: ['addDeposit', 'addDepositInfo', 'navDataList', 'depositDataInfo', 'title', 'editDepositData'],
  data() {
    return {
      formData: {
        depositName: '',
        depositMoney: ''
      },
      addDepositShow: this.addDeposit
    }
  },
  watch: {
    depositDataInfo() {
      this.formData = this.depositDataInfo
    },
    title() {
      if (this.title == '新增') {
        this.formData.depositName = ''
        this.formData.depositMoney = ''
      }
    }
  },
  methods: {
    close() {
      this.$emit('closeAddDeposit')
    },
    // 提交表单
    async getAddDepositInfo() {
      if (this.title == '新增') {
        this.addDepositInfo(this.formData)
        this.addDeposit = false
        this.formData.depositName = ''
        this.formData.depositMoney = ''
      } else if (this.title == '编辑') {
        this.editDepositData(this.formData)
        this.addDeposit = false
      }
    }
  }
}
</script>
<style lang="less" scoped>
.el-select {
  width: 100%;
}
.couponAmount {
  display: flex;
  p {
    padding: 0;
    margin: 0;
    padding-left: 12px;
  }
}
</style>
