<template>
  <el-container>
    <el-dialog title="新增成员" :visible.sync="lookContract" width="30%" @close="close">
      <div class="content">
        pdf合同
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="lookContract = false">取 消</el-button>
        <el-button type="primary" @click="lookContract = false">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>
<script>
export default {
  props: ['lookContract'],
  data () {
    return {
      form: {
        lookContract: ''
      }
    }
  },
  methods: {
    close () {
      this.$emit('closeLookContract')
    }
  }
}
</script>
<style lang="less" scoped>
.input_item {
  padding: 8px 0;
  .item_vlaue {
    padding-bottom: 8px;
  }
}
</style>
