<template>
  <el-container>
    <el-dialog
      :title="title"
      :visible.sync="addOperateImg"
      width="50%"
      @close="close"
      append-to-body
      :close-on-click-modal="false"
    >
      <div class="content">
        <el-form ref="formData" :model="formData" label-width="80px">
          <el-form-item label="APP类型: ">
            <el-input v-model="formData.appType" placeholder="请输入"></el-input>
            <p class="input_dec">0 用户中心 1 物业中心 2 运营中心</p>
          </el-form-item>
          <el-form-item label="图片说明:">
            <el-input v-model="formData.imgType" placeholder="请输入"></el-input>
            <p class="input_dec">startupPage 引导页</p>
          </el-form-item>
          <el-form-item label="图片Url:" class="item_input">
            <el-input v-model="formData.imgUrl" placeholder="请输入"></el-input>
            <el-upload
              class="upload-demo"
              :action="`https://kwangda.com:8092/sys/appImg/upload/${formData.appType} ${new Date().getTime() / 1000}`"
              :before-upload="beforeFileUp"
              :on-success="fileSuced"
              multiple
              :limit="1"
            >
              <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>
          </el-form-item>
          <el-form-item label="环境颜色:" class="item_input">
            <el-input v-model="formData.baColor" placeholder="请输入"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addOperateImg = false">取 消</el-button>
        <el-button type="primary" @click="getAddOperateImg()">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>
<script>
export default {
  props: ['addOperateImg', 'addOperateImgFormData', 'appOperateDataInfo', 'title', 'editOperateInfo'],
  data() {
    return {
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      formData: {
        id: '',
        appType: '',
        imgType: '',
        imgUrl: '',
        baColor: ''
      },
      addOperateImgShow: this.addOperateImg
    }
  },
  watch: {
    appOperateDataInfo() {
      this.formData = this.appOperateDataInfo
    }
  },
  mounted() {},
  methods: {
    close() {
      this.$emit('closeAddOperateImg')
    },
    // 提交表单
    async getAddOperateImg() {
      if (this.title == '新增图片') {
        this.addOperateImgFormData(this.formData)
        this.addOperateImg = false
        this.formData.appType = ''
        this.formData.imgType = ''
        this.formData.imgUrl = ''
        this.formData.baColor = ''
      } else if (this.title == '编辑图片') {
        this.editOperateInfo(this.formData)
        this.addKnowClass = false
      }
    },
    handleRemove(file) {
      // console.log(file)
    },
    async handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    handleDownload(file) {
      // console.log(file)
    },
    beforeFileUp() {
      if (!this.formData.appType) {
        this.$message.error('请先输入APP类型')
        return false
      }
    },
    fileSuced(res) {
      if (res.success) {
        // console.log('文件上传成功：：', res)
        this.formData.imgUrl = res.data
        this.$message.success('上传成功')
      }
    }
  }
}
</script>
<style lang="less" scoped>
.el-input {
  width: 100%;
}
.item_unit {
  display: flex;
  .item_input {
    width: 100%;
  }
  .item_text {
    padding-top: 10px;
  }
}
.input_dec {
  font-size: 12px;
  line-height: 24px;
  padding: 0;
  margin: 0;
}
</style>
