import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import create from './utils/create'
import './plugins/element.js'
import './assets/css/public.css'
import './assets/css/theme/index.css'
import {formatDate} from '@/utils/index.js'
import CallPhoneDlg from './components/common/callPhone/index'

Vue.prototype.formatDate =formatDate
Vue.config.productionTip = false
Vue.prototype.$create = create
Vue.use(CallPhoneDlg)

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
}).$mount('#app')
