<template>
  <el-container>
    <el-dialog title="身份验证" :visible.sync="auth" width="30%" @close="close" :close-on-click-modal="false">
      <div class="content">
        <div class="input_item">
          <div class="item_key">
            <el-input type="textarea" placeholder="请输入成员身份证号" v-model="idNo">
            </el-input>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="auth = false">取 消</el-button>
        <el-button type="primary" @click="ok(idNo)">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>
<script>
export default {
  props: ['auth','ok'],
  data () {
    return {
      idNo: ''
    }
  },
  methods: {
    close () {
      this.$emit('closeAuth')
    },
    openDetails () {
      this.$router.push({ path: '/FMDetails' })
    }
  }
}
</script>
<style lang="less" scoped>
.input_item {
  padding: 8px 0;
  .item_vlaue {
    padding-bottom: 8px;
  }
}
</style>
