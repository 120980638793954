<template>
  <el-container>
    <el-header class="header">
      <div class="leftTit">物业档案列表</div>
    </el-header>
    <el-main>
      <!-- 数据统计 -->
      <div class="listData">
        <el-row :gutter="8">
          <el-col :span="4">
            <div class="grid-content bg-purple" style="background: #e9fbfb">
              <div class="grid_inner">
                <div class="data_number">{{ propertyTotal }}</div>
                <div>总接入物业数</div>
              </div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple" style="background: #e9fbf2">
              <div class="grid_inner">
                <div class="data_number">{{ propertyThisMonthListTotal }}</div>
                <div>本月新增物业数</div>
              </div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple" style="background: #e9f2fb">
              <div class="grid_inner">
                <div class="data_number">{{ roomAllTotal }}</div>
                <div>总房屋数(户)</div>
              </div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple" style="background: #e9fbf2">
              <div class="grid_inner">
                <div class="data_number">{{ roomThisMonthListTotal }}</div>
                <div>本月新增房屋数(户)</div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- 精准查询 -->
      <div class="searchBox">
        <div class="searchTit">精准查询</div>
        <div class="key_group">
          <div class="key_group_item">
            <div class="key_tit">物业公司</div>
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </div>
          <div class="key_group_item">
            <div class="key_tit">所属地区</div>
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </div>
          <div class="key_group_item">
            <div class="key_tit">小区名称</div>
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </div>
          <el-button type="primary">查询</el-button>
        </div>
      </div>
      <!-- 列表 -->
      <div class="list">
        <el-table ref="multipleTable" :data="tableData.rows" tooltip-effect="dark" style="width: 100%">
          <el-table-column prop="id" label="ID" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="propertyName" label="所属物业" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="propertyArea" label="所属地区" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="villageName" label="小区名称" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="propertyAddress" label="小区地址" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="contactPerson" label="负责人" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="propertyPhone" label="联系电话" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="areaCovered" label="占地面积(m2)" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="generalBuilding" label="总楼栋" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="totalHouseholds" label="总户数" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column fixed="right" label="操作" width="120" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <el-button @click.native.prevent="openDetailsRow(scope.row)" type="text" size="small"> 详情 </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="pageBox">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-sizes="[5, 10, 20]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="tableData.total"
          >
          </el-pagination>
        </div>
      </div>
    </el-main>
  </el-container>
</template>
<script>
import { formatDate } from '@/utils/index.js'
import { queryProperty, queryRoomAllList, queryPropertyThisMonthList, queryRoomThisMonthList } from '@/api/property.js'
export default {
  data() {
    return {
      formatDate: formatDate,
      query: {
        page: 1,
        pageSize: 10
      },
      value: '',
      options: [
        {
          value: '选项1',
          label: '黄金糕'
        },
        {
          value: '选项2',
          label: '双皮奶'
        },
        {
          value: '选项3',
          label: '蚵仔煎'
        },
        {
          value: '选项4',
          label: '龙须面'
        },
        {
          value: '选项5',
          label: '北京烤鸭'
        }
      ],
      tableData: [],
      // 物业总数
      propertyTotal: 0,
      roomAllTotal: 0,
      propertyThisMonthListTotal: 0,
      roomThisMonthListTotal: 0
    }
  },
  created() {
    this.init()
    this.getRoomAllList()
    this.getPropertyThisMonthList()
    this.getRoomThisMonthList()
  },
  methods: {
    //初始化表格
    async init() {
      let result = await queryProperty(this.query)
      if (result.success) {
        this.tableData = result.data
        this.propertyTotal = result.data.total
      }
    },
    //分页-size
    handleSizeChange(val) {
      this.query.pageSize = val
      this.init()
    },
    //分页-page
    handleCurrentChange(val) {
      this.query.page = val
      this.init()
    },
    // 所有房屋数量
    async getRoomAllList() {
      let result = await queryRoomAllList()
      if (result.success) {
        this.roomAllTotal = Object.keys(result.data).length
      }
    },
    // 本月新增数量
    async getPropertyThisMonthList() {
      let result = await queryPropertyThisMonthList(this.query)
      if (result.success) {
        this.propertyThisMonthListTotal = result.data.total
      }
    },
    // 本月新增房屋数量
    async getRoomThisMonthList() {
      let result = await queryRoomThisMonthList(this.query)
      if (result.success) {
        this.roomThisMonthListTotal = result.data.total
      }
    },
    openDetailsRow(row) {
      this.$store.commit('SET_PROPERTYRECORD', row)
      this.$router.push({ path: '/pfileListDetails' })
    },
    handleEdit(index, row) {
      console.log(index, row)
    },
    handleDelete(index, row) {
      console.log(index, row)
    }
  }
}
</script>
<style lang="less" scoped>
.header {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  line-height: 60px;
  display: flex;
  justify-content: space-between;
}
.searchBox {
  margin: 0 16px;
  padding: 16px;
  background: #ffffff;
  border: 1px solid #c7cfcf;
  .searchTit {
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 16px;
  }
  .key_group {
    display: flex;
    .key_group_item {
      display: flex;
      align-items: center;
      margin-right: 24px;
      .key_tit {
        margin-right: 16px;
      }
    }
  }
}
.list {
  padding: 16px;
  .pageBox {
    height: 60px;
    display: flex;
    justify-content: end;
    align-items: center;
  }
}
.listData {
  padding: 16px;
  .grid-content {
    height: 95px;
    display: flex;
    align-items: center;
    justify-content: center;
    .grid_inner {
      text-align: center;
      .data_number {
        font-size: 32px;
        font-weight: 500;
        padding-bottom: 8px;
      }
    }
  }
}
</style>
