<template>
  <el-container>
    <el-header class="header">
      <div class="leftTit">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item class="l_tit" :to="{ path: '/notice' }">通知公告</el-breadcrumb-item>
          <el-breadcrumb-item class="l_tit">新增公告</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </el-header>
    <el-main>
      <el-row class="formBox">
        <el-col :span="16">
          <div class="grid-content bg-purple-dark">
            <div>公告标题:</div>
            <div class="f_input">
              <el-input v-model="input" placeholder="请输入内容"></el-input>
            </div>
          </div>
        </el-col>
        <el-col :span="16">
          <div class="grid-content bg-purple-dark">
            <div>所属分类:</div>
            <div class="f_input">
              <el-select v-model="value" placeholder="请选择" class="f_sekect">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>
        </el-col>
        <el-col :span="16">
          <div class="grid-content bg-purple-dark">
            <div>公告内容:</div>
            <div class="f_input">
              <el-input :rows="10" type="textarea" v-model="input" placeholder="富文本"></el-input>
            </div>
          </div>
        </el-col>
        <el-col :span="16">
          <div class="grid-content bg-purple-dark">
            <el-button>取消</el-button>
            <el-button type="primary">发布</el-button>
          </div>
        </el-col>
      </el-row>
    </el-main>
  </el-container>
</template>
<script>
export default {
  data () {
    return {
      input: '',
      options: [{
        value: '选项1',
        label: '黄金糕'
      }, {
        value: '选项2',
        label: '双皮奶'
      }, {
        value: '选项3',
        label: '蚵仔煎'
      }, {
        value: '选项4',
        label: '龙须面'
      }, {
        value: '选项5',
        label: '北京烤鸭'
      }],
      value: ''
    }
  },
  methods: {
  }
}
</script>
<style lang="less" scoped>
.header {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  line-height: 60px;
  display: flex;
  justify-content: space-between;
  .leftTit {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .l_tit {
      font-size: 16px;
      font-weight: 500;
    }
  }
}
.formBox {
  padding: 16px;
  .grid-content {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    .f_input {
      margin-left: 16px;
      width: 80%;
    }
    .f_sekect {
      width: 100%;
    }
  }
}
</style>
