<template>
  <el-container>
    <el-header class="header">
      <div class="leftTit">物业待审核</div>
    </el-header>
    <el-main>
      <!-- 数据统计 -->
      <div class="listData">
        <el-row :gutter="8">
          <el-col :span="4">
            <div class="grid-content bg-purple" style="background: #e9fbfb">
              <div class="grid_inner">
                <div class="data_number">{{ propertyRescindTotal }}</div>
                <div>待接入物业总数</div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- 列表 -->
      <div class="list">
        <el-table :data="tableData.rows" style="width: 100%">
          <el-table-column prop="id" label="ID" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="propertyName" label="所属物业" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="propertyArea" label="所属地区" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="villageName" label="小区名称" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="propertyAddress" label="小区地址" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="contactPerson" label="负责人" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="contactPhone" label="联系电话" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="areaCovered" label="占地面积(m2)" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="generalBuilding" label="总楼栋" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="totalHouseholds" label="总户数" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="developers" label="开发商" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column fixed="right" label="操作" width="120">
            <template slot-scope="scope">
              <el-button @click.native.prevent="openDetailsRow(scope.row)" type="text" size="small"> 审核 </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="pageBox">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-sizes="[5, 10, 20]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="tableData.total"
          >
          </el-pagination>
        </div>
      </div>
    </el-main>
  </el-container>
</template>
<script>
import { queryPropertyRescindList } from '@/api/property.js'
export default {
  data() {
    return {
      tableData: [],
      propertyRescindTotal: 0,
      query: {
        page: 1,
        pageSize: 10
      }
    }
  },
  created() {
    this.initPage()
  },
  methods: {
    async initPage() {
      let result = await queryPropertyRescindList(this.query)
      this.tableData = result.data
      this.propertyRescindTotal = result.data.total
    },
    //分页-size
    handleSizeChange(val) {
      this.query.pageSize = val
      this.initPage()
    },
    //分页-page
    handleCurrentChange(val) {
      this.query.page = val
      this.initPage()
    },
    openDetailsRow(row) {
      this.$store.commit('SET_PROPERTYRECORD', row)
      this.$router.push({ path: '/auditDetails' })
    }
  }
}
</script>
<style lang="less" scoped>
.header {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  line-height: 60px;
  display: flex;
  justify-content: space-between;
}
.list {
  padding: 16px;
  .pageBox {
    height: 60px;
    display: flex;
    justify-content: end;
    align-items: center;
  }
}
.listData {
  padding: 16px;
  .grid-content {
    height: 95px;
    display: flex;
    align-items: center;
    justify-content: center;
    .grid_inner {
      text-align: center;
      .data_number {
        font-size: 32px;
        font-weight: 500;
        padding-bottom: 8px;
      }
    }
  }
}
</style>
