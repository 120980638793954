<template>
  <el-container>
    <el-header class="header">合同管理</el-header>
    <el-main>
      <!-- 列表 -->
      <el-row :gutter="8" class="list">
        <el-col :span="6">
          <div class="grid-content bg-purple-dark">
            <div class="g_left">
              <div class="g_l_tit" @click="openSeeTreaty">企业物业服务协议</div>
              <div class="g_l_dec">更新时间：2022.05.06</div>
            </div>
            <div class="g_right">
              <el-upload class="avatar-uploader" action="https://jsonplaceholder.typicode.com/posts/" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                <img v-if="imageUrl" :src="imageUrl" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content bg-purple-dark">
            <div class="g_left">
              <div class="g_l_tit">企业用户服务协议</div>
              <div class="g_l_dec">更新时间：2022.05.06</div>
            </div>
            <div class="g_right">
              <el-upload class="avatar-uploader" action="https://jsonplaceholder.typicode.com/posts/" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                <img v-if="imageUrl" :src="imageUrl" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content bg-purple-dark">
            <div class="g_left">
              <div class="g_l_tit">个人信息保护政策</div>
              <div class="g_l_dec">更新时间：2022.05.06</div>
            </div>
            <div class="g_right">
              <el-upload class="avatar-uploader" action="https://jsonplaceholder.typicode.com/posts/" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                <img v-if="imageUrl" :src="imageUrl" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content bg-purple-dark">
            <div class="g_left">
              <div class="g_l_tit">企业智慧安防应急预案</div>
              <div class="g_l_dec">更新时间：2022.05.06</div>
            </div>
            <div class="g_right">
              <el-upload class="avatar-uploader" action="https://jsonplaceholder.typicode.com/posts/" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                <img v-if="imageUrl" :src="imageUrl" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content bg-purple-dark">
            <div class="g_left">
              <div class="g_l_tit">企业养老计划实施方案</div>
              <div class="g_l_dec">更新时间：2022.05.06</div>
            </div>
            <div class="g_right">
              <el-upload class="avatar-uploader" action="https://jsonplaceholder.typicode.com/posts/" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                <img v-if="imageUrl" :src="imageUrl" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content bg-purple-dark">
            <div class="g_left">
              <div class="g_l_tit">企业智慧养老关爱管理办法</div>
              <div class="g_l_dec">更新时间：2022.05.06</div>
            </div>
            <div class="g_right">
              <el-upload class="avatar-uploader" action="https://jsonplaceholder.typicode.com/posts/" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                <img v-if="imageUrl" :src="imageUrl" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content bg-purple-dark">
            <div class="g_left">
              <div class="g_l_tit">企业年度利润分红实施方案</div>
              <div class="g_l_dec">更新时间：2022.05.06</div>
            </div>
            <div class="g_right">
              <el-upload class="avatar-uploader" action="https://jsonplaceholder.typicode.com/posts/" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                <img v-if="imageUrl" :src="imageUrl" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
          </div>
        </el-col>
      </el-row>
      <!-- 打开查看协议弹框 -->
      <seeTreaty :seeTreaty="seeTreatyShow" @closeSeeTreaty="showSeeTreaty"></seeTreaty>
    </el-main>
  </el-container>
</template>
<script>
import SeeTreaty from '@/components/personBase/seeTreaty'
export default {
  props: ['seeTreaty'],
  components: {
    seeTreaty: SeeTreaty
  },
  data () {
    return {
      seeTreatyShow: false,
      imageUrl: ''
    }
  },
  methods: {
    showSeeTreaty () {
      this.seeTreatyShow = false
    },
    openSeeTreaty () {
      this.seeTreatyShow = true
    },
    handleAvatarSuccess (res, file) {
      this.imageUrl = URL.createObjectURL(file.raw)
    },
    beforeAvatarUpload (file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    }
  }
}
</script>
<style lang="less" scoped>
.header {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  line-height: 60px;
}
.list {
  padding: 16px;
  .grid-content {
    padding: 16px;
    background: #ffffff;
    border: 1px solid #c7cfcf;
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    .g_left {
      .g_l_tit {
        font-size: 16px;
        font-weight: 500;
        padding-bottom: 16px;
        cursor: pointer; /*鼠标变小手*/
      }
      .g_l_tit:hover {
        color: #09bebe;
      }
      .g_l_dec {
        color: #8c939d;
      }
    }
    .g_right {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
    }
    .avatar-uploader .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
      border-color: #409eff;
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 60px;
      height: 60px;
      line-height: 60px;
      text-align: center;
    }
    .avatar {
      width: 60px;
      height: 60px;
      display: block;
    }
  }
}
</style>
