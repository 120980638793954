<template>
  <el-container>
    <el-dialog
      :title="title"
      :visible.sync="addDevice"
      width="50%"
      @close="close"
      append-to-body
      :close-on-click-modal="false"
    >
      <div class="content">
        <el-form ref="formData" :model="formData" label-width="80px">
          <el-form-item label="设备厂商:">
            <el-input v-model="formData.deviceBusiness"></el-input>
          </el-form-item>
          <el-form-item label="负责人:">
            <el-input v-model="formData.superintendent"></el-input>
          </el-form-item>
          <el-form-item label="负责人电话:">
            <el-input v-model="formData.superintendentPhone"></el-input>
          </el-form-item>
          <el-form-item label="所属地区:">
            <el-input v-model="formData.area"></el-input>
          </el-form-item>
          <el-form-item label="开户行:">
            <el-input v-model="formData.bankName"></el-input>
          </el-form-item>
          <el-form-item label="账户名称:">
            <el-input v-model="formData.bankAccount"></el-input>
          </el-form-item>
          <el-form-item label="银行账号:">
            <el-input v-model="formData.bankNumber"></el-input>
          </el-form-item>
          <el-form-item label="提供设备:">
            <el-input v-model="formData.deviceType"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDevice = false">取 消</el-button>
        <el-button type="primary" @click="getAddDeviceInfo()">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>
<script>
export default {
  props: ['addDevice', 'addDeviceInfo', 'deviceCompanyDataInfo', 'title', 'editDeviceCompanyData'],
  data() {
    return {
      formData: {
        id: '',
        deviceBusiness: '',
        superintendent: '',
        superintendentPhone: '',
        area: '',
        bankName: '',
        bankAccount: '',
        bankNumber: '',
        deviceType: ''
      }
    }
  },
  watch: {
    deviceCompanyDataInfo() {
      this.formData = this.deviceCompanyDataInfo
      this.formData.id = this.deviceCompanyDataInfo.id
    },
    title() {
      if (this.title == '新增') {
        this.formData.deviceBusiness = ''
        this.formData.superintendent = ''
        this.formData.area = ''
        this.formData.superintendentPhone = ''
        this.formData.bankName = ''
        this.formData.bankAccount = ''
        this.formData.deviceType = ''
      }
    }
  },
  methods: {
    close() {
      this.$emit('closeAddDevice')
    },
    // 提交表单
    async getAddDeviceInfo() {
      if (this.title == '新增') {
        this.addDeviceInfo(this.formData)
        this.addDevice = false
      } else if (this.title == '编辑') {
        this.editDeviceCompanyData(this.formData)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.el-select {
  width: 100%;
}
.el-date-editor.el-input {
  width: 100%;
}
</style>
