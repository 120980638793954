<template>
  <el-container>
    <el-dialog
      :title="title"
      :visible.sync="addInsuranceGoods"
      width="50%"
      @close="close"
      @opened="getEditor()"
      append-to-body
      :close-on-click-modal="false"
    >
      <div class="content">
        <el-form ref="formData" :model="formData" label-width="80px">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="公司名称:">
                <el-select v-model="formData.insuranceCompanyId" placeholder="请选择">
                  <el-option
                    v-for="item in insuranceCompanyList"
                    :key="item.id"
                    :label="item.companyName"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="保险名称:">
                <el-input v-model="formData.insuranceGoodsName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="保险单价:">
                <div class="formItem">
                  <el-input v-model="formData.insuranceMoney"></el-input>
                  <p>元</p>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="保单周期:">
                <div class="formItem">
                  <el-input v-model="formData.insuranceCycle"></el-input>
                  <p>年</p>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="总理赔额:">
                <div class="formItem">
                  <el-input v-model="formData.insureTotal"></el-input>
                  <p>万元</p>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="是否显示:">
                <el-radio v-model="formData.showStatus" label="0">不显示</el-radio>
                <el-radio v-model="formData.showStatus" label="1">显示</el-radio>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="套餐截图:" class="item_input">
                <el-input v-model="formData.insuranceImg" placeholder="请输入"></el-input>
                <el-upload
                  class="upload-demo"
                  :action="`https://kwangda.com:8092/sys/appImg/upload/${formData.insuranceGoodsName} ${
                    new Date().getTime() / 1000
                  }`"
                  :before-upload="beforeFileUp"
                  :on-success="fileSuced"
                  multiple
                  :limit="1"
                >
                  <el-button size="small" type="primary">点击上传</el-button>
                </el-upload>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="套餐详情:">
                <!-- <el-input v-model="formData.insuranceInfo"></el-input> -->
                <div id="div1"></div>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addInsuranceGoods = false">取 消</el-button>
        <el-button type="primary" @click="getAddInsuranceInfo()">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>
<script>
import E from 'wangeditor'
let editor
export default {
  props: [
    'addInsuranceGoods',
    'addInsuranceInfo',
    'insuranceGoodsDataInfo',
    'title',
    'editInsuranceGoodsData',
    'companyList'
  ],
  data() {
    return {
      insuranceCompanyList: [],
      formData: {
        id: '',
        insuranceCompanyId: '',
        insuranceGoodsName: '',
        insuranceMoney: '',
        insuranceCycle: '',
        showStatus: '',
        insuranceImg: '',
        insuranceInfo: ''
      }
    }
  },
  watch: {
    companyList() {
      this.insuranceCompanyList = this.companyList
    },
    insuranceGoodsDataInfo() {
      this.formData = this.insuranceGoodsDataInfo
      this.formData.id = this.insuranceGoodsDataInfo.id
      editor.txt.html(this.formData.insuranceInfo)
    },
    title() {
      if (this.title == '新增') {
        this.formData.insuranceCompanyId = ''
        this.formData.insuranceGoodsName = ''
        this.formData.insuranceMoney = ''
        this.formData.insuranceCycle = ''
        this.formData.showStatus = ''
        this.formData.insuranceImg = ''
        editor.txt.html((this.formData.insuranceInfo = ''))
      }
    }
  },
  methods: {
    close() {
      this.$emit('closeAddInsurance')
    },
    getEditor() {
      editor = new E('#div1')
      editor.create()
    },
    beforeFileUp() {
      if (!this.formData.insuranceGoodsName) {
        this.$message.error('请先输入套餐名称')
        return false
      }
    },
    fileSuced(res) {
      if (res.success) {
        console.log('文件上传成功：', res)
        this.formData.insuranceImg = res.data
        this.$message.success('上传成功')
      }
    },
    // 提交表单
    async getAddInsuranceInfo() {
      const content = editor.txt.html()
      this.formData.insuranceInfo = content
      if (this.title == '新增') {
        this.addInsuranceInfo(this.formData)
        this.addInsuranceGoods = false
      } else if (this.title == '编辑') {
        this.editInsuranceGoodsData(this.formData)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.el-select {
  width: 100%;
}
.el-date-editor.el-input {
  width: 100%;
}
.formItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    width: 40px;
    padding: 0;
    padding-left: 8px;
    margin: 0;
  }
}
</style>
