<template>
  <el-container>
    <el-header class="header">居民档案列表</el-header>
    <el-main>
      <!-- 数据统计 -->
      <div class="listData">
        <el-row :gutter="8">
          <el-col :span="4">
            <div class="grid-content bg-purple" style="background: #e9fbfb">
              <div class="grid_inner">
                <div class="data_number">{{ userRoomTotal }}</div>
                <div>总户数(户)</div>
              </div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple" style="background: #e9fbf2">
              <div class="grid_inner">
                <div class="data_number">{{ userAddTotal }}</div>
                <div>本月月新增(户)</div>
              </div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple" style="background: #fbe9e9">
              <div class="grid_inner">
                <div class="data_number">{{ userFamilyTotal }}</div>
                <div>服务人数(户)</div>
              </div>
            </div>
          </el-col>
          <!-- <el-col :span="4">
            <div class="grid-content bg-purple" style="background: #e9f2fb;">
              <div class="grid_inner">
                <div class="data_number">76%</div>
                <div>解约数</div>
              </div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple" style="background: #e9fbf2;">
              <div class="grid_inner">
                <div class="data_number">2,803</div>
                <div>接入失败数(人)</div>
              </div>
            </div>
          </el-col> -->
        </el-row>
      </div>
      <!-- 精准查询 -->
      <div class="searchBox">
        <div class="searchTit">精准查询</div>
        <div class="key_group">
          <div class="key_group_item">
            <div class="key_tit">物业公司</div>
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </div>
          <div class="key_group_item">
            <div class="key_tit">所属地区</div>
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </div>
          <div class="key_group_item">
            <div class="key_tit">小区</div>
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </div>
          <div class="key_group_item">
            <div class="key_tit">房号</div>
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </div>
          <el-button type="primary">查询</el-button>
        </div>
      </div>
      <!-- 列表 -->
      <div class="list">
        <el-table :data="tableData.rows" style="width: 100%">
          <el-table-column prop="id" label="ID" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="propertyName" label="所属物业" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="propertyArea" label="所属地区" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="villageName" label="所属小区" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="propertyAddress" label="小区地址" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="floorName" label="房号" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <p>
                {{ scope.row.floorName }}号楼 {{ scope.row.unitName }}单元 {{ scope.row.layerName }}层
                {{ scope.row.roomName }}室
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="ownerName" label="业主姓名" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="depositStatus" label="设备押金" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <p>
                {{ scope.row.depositStatus == 1 ? '已缴纳' : '未缴纳' }}
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="serviceType" label="服务类型" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="servicePackage" label="服务套餐" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="serviceStatus" label="服务状态" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <p>
                {{ scope.row.serviceStatus == 1 ? '正常' : '异常' }}
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="equipmentStatus" label="设备状态" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <p>
                {{ scope.row.serviceStatus == 1 ? '正常' : '异常' }}
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="接入时间" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <p>
                {{ formatDate(scope.row.createTime) }}
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="dueDate" label="到期时间" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <p>
                {{ formatDate(scope.row.dueDate) }}
              </p>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="120">
            <template slot-scope="scope">
              <el-button @click.native.prevent="callbackRow(scope.$index, tableData)" type="text" size="small">
                电话
              </el-button>
              <el-button @click.native.prevent="seeRow(scope.row)" type="text" size="small"> 查看 </el-button>
              <el-button @click.native.prevent="OpenRescind(scope.$index, tableData)" type="text" size="small">
                解约
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="pageBox">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-sizes="[5, 10, 20]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="tableData.total"
          >
          </el-pagination>
        </div>
      </div>
      <!-- 打开解约弹框 -->
      <rescind :rescind="rescindShow" @closeRescind="showRescind()"></rescind>
      <!-- 隐私电话弹框 -->
      <privacyCall :privacyCall="privacyCallShow" @closePrivacyCall="showPrivacyCall"></privacyCall>
    </el-main>
  </el-container>
</template>
<script>
import Rescind from '@/components/archives/rescind'
import PrivacyCall from '@/components/public/privacyCall'
import { queryProList, queryMemberAllTotal, queryUserThisMonthList } from '@/api/archives.js'
import { formatDate } from '@/utils/index.js'
export default {
  props: ['rescind', 'PrivacyCall'],
  components: {
    rescind: Rescind,
    privacyCall: PrivacyCall
  },
  data() {
    return {
      rescindShow: false,
      privacyCallShow: false,
      formatDate: formatDate,
      query: {
        page: 1,
        pageSize: 10
      },
      options: [
        {
          value: '选项1',
          label: '黄金糕'
        },
        {
          value: '选项2',
          label: '双皮奶'
        },
        {
          value: '选项3',
          label: '蚵仔煎'
        },
        {
          value: '选项4',
          label: '龙须面'
        },
        {
          value: '选项5',
          label: '北京烤鸭'
        }
      ],
      value: '',
      tableData: [],
      // 总户数
      userRoomTotal: 0,
      // 服务总人数
      userFamilyTotal: 0,
      userAddTotal: 0
    }
  },
  created() {
    this.init()
    this.getMemberAllTota()
    this.getUserThisMonthList()
  },
  methods: {
    //初始化表格
    async init() {
      let result = await queryProList(this.query)
      if (result.success) {
        this.tableData = result.data
        this.userRoomTotal = result.data.total
      }
    },
    //分页-size
    handleSizeChange(val) {
      this.query.pageSize = val
      this.init()
    },
    //分页-page
    handleCurrentChange(val) {
      this.query.page = val
      this.init()
    },
    // 本月新增数量
    async getUserThisMonthList() {
      let result = await queryUserThisMonthList(this.query)
      if (result.success) {
        this.userAddTotal = result.data.total
      }
    },
    //查询总人数
    async getMemberAllTota() {
      let result = await queryMemberAllTotal(this.query)
      if (result.success) {
        this.userFamilyTotal = Object.keys(result.data).length
      }
    },
    callbackRow(index) {
      this.privacyCallShow = true
    },
    showPrivacyCall() {
      this.privacyCallShow = false
    },
    showrescind() {
      this.rescindShow = false
    },
    OpenRescind() {
      this.rescindShow = true
    },
    seeRow(row) {
      this.$store.commit('SET_PRORILERECORD', row)
      this.$router.push({ path: '/fileDetails' })
    }
  }
}
</script>
<style lang="less" scoped>
.header {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  line-height: 60px;
}
.listData {
  padding: 16px;
  .grid-content {
    height: 95px;
    display: flex;
    align-items: center;
    justify-content: center;
    .grid_inner {
      text-align: center;
      .data_number {
        font-size: 32px;
        font-weight: 500;
        padding-bottom: 8px;
      }
    }
  }
}
.searchBox {
  margin: 0 16px;
  padding: 16px;
  background: #ffffff;
  border: 1px solid #c7cfcf;
  .searchTit {
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 16px;
  }
  .key_group {
    display: flex;
    .key_group_item {
      display: flex;
      align-items: center;
      margin-right: 24px;
      .key_tit {
        margin-right: 16px;
      }
    }
  }
}
.list {
  padding: 16px;
  .pageBox {
    height: 60px;
    display: flex;
    justify-content: end;
    align-items: center;
  }
}
</style>
