<template>
  <el-container>
    <el-main>
      <el-row>
        <el-col :span="4" class="leftBox">
          <div class="left_titBox">
            <div class="l_t_txt">通知分类</div>
            <div class="l_t_btn" @click="OpenAddNoticeClass">添加</div>
          </div>
          <div class="left_contentBox">
            <div class="l_c_items" v-for="(items, v) in navDataList" :key="v">
              <div class="l_c_i_txt" :class="{ active: isActive == v }" @click="getClassNoticeList(items, v)">
                {{ items.className }}
                (
                <p v-if="items.displayTerminal == 0">用户中心</p>
                <p v-if="items.displayTerminal == 1">物业中心</p>
                <p v-if="items.displayTerminal == 2">运营中心</p>
                <p v-if="items.displayTerminal == null">其他</p>
                )
              </div>
              <div class="l_c_i_btn">
                <div class="l_c_i_edit" @click="openEditNoticeClass(items)">编辑</div>
                <div class="l_c_i_dele" @click="openDeleteNoticeClass(items)">删除</div>
              </div>
            </div>
          </div>
        </el-col>
        <!-- 列表 -->
        <div class="rightBox">
          <div class="rightBtn">
            <div class="leftTit">通知列表</div>
            <el-button type="primary" @click="OpenAddNotice">新增通知</el-button>
          </div>
          <el-col class="list" :span="20">
            <el-table :data="tableData.rows" style="width: 100%">
              <el-table-column prop="id" label="ID" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column label="分类名称" :show-overflow-tooltip="true">
                <template>
                  <p>{{ className }}</p>
                </template>
              </el-table-column>
              <el-table-column label="显示终端" :show-overflow-tooltip="true">
                <template>
                  <p v-if="displayTerminal == 0">用户中心</p>
                  <p v-if="displayTerminal == 1">物业中心</p>
                  <p v-if="displayTerminal == 2">运营中心</p>
                  <p v-if="displayTerminal == null">其他</p>
                </template>
              </el-table-column>
              <el-table-column prop="noticeTitle" label="标题" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="createTime" label="发布时间" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column fixed="right" label="操作" width="120">
                <template slot-scope="scope">
                  <el-button @click.native.prevent="openNoticeDataInfo(scope.row)" type="text" size="small">
                    编辑
                  </el-button>
                  <el-button @click.native.prevent="deleteRow(scope.row)" type="text" size="small"> 删除 </el-button>
                </template>
              </el-table-column>
            </el-table>
            <div class="pageBox">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :page-sizes="[5, 10, 20]"
                layout="total, sizes, prev, pager, next, jumper"
                :total="tableData.total"
              >
              </el-pagination>
            </div>
          </el-col>
        </div>
      </el-row>
      <!-- 添加分类 -->
      <addNoticeClass
        :title="title"
        :addNoticeClass="addNoticeClassShow"
        @closeAddNoticeClass="showAddNoticeClass"
        :addNoticeClassInfo="addNoticeClassInfo"
        :noticeClassDataInfo="noticeClassDataInfo"
        :editNoticeClass="editNoticeClass"
      ></addNoticeClass>
      <!-- 添加知识 -->
      <addNotice
        :addNotice="addNoticeShow"
        :title="title"
        @closeAddNotice="showAddNotice"
        :addNoticeInfo="addNoticeInfo"
        :navDataList="navDataList"
        :noticeDataInfo="noticeDataInfo"
        :editNoticeData="editNoticeData"
      ></addNotice>
    </el-main>
  </el-container>
</template>
<script>
import {
  getNoticeClassData,
  getClassToNoticeData,
  deleteNoticeClass,
  addNoticeClass,
  addNotice,
  deleteNotice,
  editNotice,
  putNoticeClass
} from '@/api/public.js'
import AddNoticeClass from '@/components/notice/addNoticeClass'
import AddNotice from '@/components/notice/addNotice'
export default {
  components: {
    AddNoticeClass: AddNoticeClass,
    AddNotice: AddNotice
  },
  data() {
    return {
      addNoticeClassShow: false,
      addNoticeShow: false,
      isActive: 0,
      navDataList: [],
      tableData: [],
      query: {
        page: 1,
        pageSize: 10
      },
      classId: '',
      className: '',
      displayTerminal: '',
      noticeDataInfo: '', // 编辑知识
      title: '',
      noticeClassDataInfo: '' //编辑分类
    }
  },
  mounted() {
    this.getNoticeClassList()
  },
  methods: {
    //获取分类
    async getNoticeClassList() {
      let result = await getNoticeClassData()
      if (result.success) {
        this.navDataList = result.data
        this.className = this.navDataList[0].className
        this.displayTerminal = this.navDataList[0].displayTerminal
        let res = await getClassToNoticeData(this.navDataList[0].id, this.query)
        if (res.success) {
          this.tableData = res.data
        }
      }
    },
    // 获取分类下知识
    getClassNoticeList(items, v) {
      this.isActive = v
      this.classId = items.id
      this.className = items.className
      this.displayTerminal = items.displayTerminal
      this.getNoticeDataList()
    },
    async getNoticeDataList() {
      let res = await getClassToNoticeData(this.classId, this.query)
      if (res.success) {
        this.tableData = res.data
      }
    },
    //分页-size
    handleSizeChange(val) {
      this.query.pageSize = val
      this.getNoticeDataList()
    },
    //分页-page
    handleCurrentChange(val) {
      this.query.page = val
      this.getNoticeDataList()
    },
    // 新增分类
    OpenAddNoticeClass() {
      this.addNoticeClassShow = true
      this.title = '新增分类'
    },
    showAddNoticeClass() {
      this.addNoticeClassShow = false
    },
    // 新增分类接口
    async addNoticeClassInfo(data) {
      let res = await addNoticeClass(data)
      if (res.success) {
        this.$message({
          type: 'success',
          message: '新增分类成功!'
        })
        this.addNoticeClassShow = false
        this.getNoticeDataList()
      } else {
        this.$message({
          type: 'error',
          message: '新增分类失败!'
        })
      }
      this.getNoticeClassList()
    },
    // 编辑分类
    async openEditNoticeClass(items) {
      this.addNoticeClassShow = true
      this.noticeClassDataInfo = items
      this.title = '编辑分类'
    },
    // 编辑分类接口
    async editNoticeClass(data) {
      console.log(data)
      let res = await putNoticeClass(data, data.id)
      if (res.success) {
        this.$message({
          type: 'success',
          message: '编辑知识成功!'
        })
        this.addNoticeClassShow = false
        let result = await getNoticeClassData()
        if (result.success) {
          this.navDataList = result.data
        }
      } else {
        this.$message({
          type: 'error',
          message: '编辑知识失败!'
        })
      }
    },
    // 删除分类
    async openDeleteNoticeClass(items) {
      this.$confirm('此操作将永久删除该分类, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      })
        .then(async () => {
          let res = await deleteNoticeClass(items.id)
          if (res.success) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.getNoticeClassList()
          } else {
            this.$message({
              type: 'error',
              message: '删除失败'
            })
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 新增知识
    async OpenAddNotice() {
      this.addNoticeShow = true
      this.title = '新增通知'
      let result = await getNoticeClassData()
      if (result.success) {
        this.navDataList = result.data
      }
    },
    showAddNotice() {
      this.addNoticeShow = false
    },
    // 新增知识接口
    async addNoticeInfo(data) {
      let res = await addNotice(data)
      if (res.success) {
        this.$message({
          type: 'success',
          message: '新增通知成功!'
        })
        this.addNoticeShow = false
      } else {
        this.$message({
          type: 'error',
          message: '新增通知失败!'
        })
      }
      this.getNoticeDataList()
    },
    // 编辑知识
    openNoticeDataInfo(row) {
      this.noticeDataInfo = row
      this.addNoticeShow = true
      this.title = '编辑通知'
    },
    // 编辑知识接口
    async editNoticeData(data) {
      let res = await editNotice(data, data.id)
      if (res.success) {
        this.$message({
          type: 'success',
          message: '编辑通知成功!'
        })
        this.addNoticeShow = false
        this.getNoticeDataList()
      } else {
        this.$message({
          type: 'error',
          message: '编辑通知失败!'
        })
      }
    },
    // 删除知识
    deleteRow(row) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      })
        .then(async () => {
          let res = await deleteNotice(row.id)
          if (res.success) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.getNoticeDataList()
          } else {
            this.$message({
              type: 'error',
              message: '删除失败'
            })
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    }
  }
}
</script>
<style lang="less" scoped>
.listData {
  padding: 16px;
  .grid-content {
    height: 95px;
    display: flex;
    align-items: center;
    justify-content: center;
    .grid_inner {
      text-align: center;
      .data_number {
        font-size: 32px;
        font-weight: 500;
        padding-bottom: 8px;
      }
    }
  }
}
.searchBox {
  margin: 0 16px;
  padding: 16px;
  background: #ffffff;
  border: 1px solid #c7cfcf;
  .searchTit {
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 16px;
  }
  .key_group {
    display: flex;
    .key_group_item {
      display: flex;
      align-items: center;
      margin-right: 24px;
      .key_tit {
        margin-right: 16px;
      }
    }
  }
}
.active {
  color: #09bebe;
}
.rightBtn {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 500;
}
.leftBox {
  border-right: #c7cfcf 0.5px solid;
  height: calc(100vh - 76px);
  .left_titBox {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .l_t_txt {
      font-size: 16px;
      font-weight: 500;
    }
    .l_t_btn {
      font-size: 14px;
      color: #09bebe;
      cursor: pointer;
    }
  }
  .left_contentBox {
    .l_c_items {
      padding: 16px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .l_c_i_dele {
        color: #be0909;
        display: none;
      }
      .l_c_i_edit {
        color: #09bebe;
        display: none;
        margin-right: 16px;
      }
      .l_c_i_btn {
        display: flex;
        justify-content: space-between;
      }
      .l_c_i_txt {
        display: flex;
        p {
          padding: 0;
          margin: 0;
        }
      }
    }
    .l_c_items:hover {
      background: #f2f3f4;
      .l_c_i_dele {
        display: block;
      }
      .l_c_i_edit {
        display: block;
      }
    }
  }
}
.list {
  padding: 16px;
  .pageBox {
    height: 60px;
    display: flex;
    justify-content: end;
    align-items: center;
  }
}
</style>
