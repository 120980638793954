<template>
  <el-container>
    <el-header class="header">服务商周期账单</el-header>
    <el-main>
      <!-- 列表 -->
      <el-tabs style="padding: 20px" v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="待打款" name="first">
          <div class="list">
            <el-table :data="tableData.rows" style="width: 100%">
              <el-table-column prop="id" label="ID" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="spName" label="商家名称" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="corporationName" label="公司名称" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="billDate" label="账单日期" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="outBillDate" label="出账日期" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="completedAmount" label="完成金额" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="refundAmount" label="退款金额" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="repairAmount" label="补差金额" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="billStatus" label="状态" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <p v-if="scope.row.billStatus == 0" :style="{ color: color }">未打款</p>
                  <p v-if="scope.row.billStatus == 1">已打款</p>
                </template>
              </el-table-column>
              <el-table-column fixed="right" label="操作" width="60">
                <template slot-scope="scope">
                  <el-button @click.native.prevent="seeRow(scope.row)" type="text" size="small"> 打款 </el-button>
                </template>
              </el-table-column>
            </el-table>
            <div class="pageBox">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :page-sizes="[5, 10, 20]"
                layout="total, sizes, prev, pager, next, jumper"
                :total="tableData.total"
              >
              </el-pagination>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="已打款" name="second">
          <div class="list">
            <el-table :data="tableData2.rows" style="width: 100%">
              <el-table-column prop="id" label="ID" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="spName" label="商家名称" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="corporationName" label="公司名称" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="billDate" label="账单日期" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="outBillDate" label="出账日期" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="completedAmount" label="完成金额" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="refundAmount" label="退款金额" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="repairAmount" label="补差金额" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="billStatus" label="状态" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <p v-if="scope.row.billStatus == 0" :style="{ color: color }">未打款</p>
                  <p v-if="scope.row.billStatus == 1">已打款</p>
                </template>
              </el-table-column>
            </el-table>
            <div class="pageBox">
              <el-pagination
                @size-change="handleSizeChange2"
                @current-change="handleCurrentChange2"
                :page-sizes="[5, 10, 20]"
                layout="total, sizes, prev, pager, next, jumper"
                :total="tableData2.total"
              >
              </el-pagination>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-main>
  </el-container>
</template>
<script>
import { findByBillStatusList, findSpBusBillDateUpdate } from '@/api/moneyMan.js'
export default {
  components: {},
  data() {
    return {
      activeName: 'first',
      tableData: [],
      tableData2: [],
      query: {
        page: 1,
        pageSize: 10
      },
      color: 'red'
    }
  },
  created() {
    this.init()
  },
  methods: {
    // 切换
    handleClick(tab, event) {
      if (tab.index == 0) {
        this.init()
      } else if (tab.index == 1) {
        this.getMoneyManDataList()
      }
    },
    //初始化表格
    async init() {
      let upPaidTotal = '0'
      let result1 = await findByBillStatusList(upPaidTotal, this.query)
      if (result1.success) {
        this.tableData = result1.data
        this.unpaidMoneyan = 720 * parseInt(result1.data.total)
        this.unpaidTotal = result1.data.total
      }
    },
    //分页-size
    handleSizeChange(val) {
      this.query.pageSize = val
      this.init()
    },
    //分页-page
    handleCurrentChange(val) {
      this.query.page = val
      this.init()
    },
    // 数据统计
    async getMoneyManDataList() {
      let paidTotal = '1'
      let result2 = await findByBillStatusList(paidTotal, this.query)
      if (result2.success) {
        this.tableData2 = result2.data
        this.paidMoneyan = 720 * parseInt(result2.data.total)
        this.paidTotal = result2.data.total
      }
    },
    //分页-size
    handleSizeChange2(val) {
      this.query2.pageSize = val
      this.getMoneyManDataList()
    },
    //分页-page
    handleCurrentChange2(val) {
      this.query2.page = val
      this.getMoneyManDataList()
    },
    seeRow(item) {
      console.log('ddd', item)
      this.$confirm('请确认已经打款完成, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          let formData = {
            billStatus: 1
          }
          let res = await findSpBusBillDateUpdate(item.id, formData)
          if (res.success) {
            this.init()
            this.$message({
              type: 'success',
              message: '更改成功!'
            })
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    }
  }
}
</script>
<style lang="less" scoped>
.header {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  line-height: 60px;
}
.listData {
  padding: 16px;
  .grid-content {
    height: 95px;
    display: flex;
    align-items: center;
    justify-content: center;
    .grid_inner {
      text-align: center;
      .data_number {
        font-size: 32px;
        font-weight: 500;
        padding-bottom: 8px;
      }
    }
  }
}
.searchBox {
  margin: 0 16px;
  padding: 16px;
  background: #ffffff;
  border: 1px solid #c7cfcf;
  .searchTit {
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 16px;
  }
  .key_group {
    display: flex;
    .key_group_item {
      display: flex;
      align-items: center;
      margin-right: 24px;
      .key_tit {
        margin-right: 16px;
      }
    }
  }
}
.list {
  padding: 16px;
  .pageBox {
    height: 60px;
    display: flex;
    justify-content: end;
    align-items: center;
  }
}
</style>
