<template>
  <el-container>
    <el-header class="header">
      <div>服务商信息管理</div>
    </el-header>
    <el-main>
      <!-- 列表 -->
      <div class="list">
        <el-table :data="tableData.rows" style="width: 100%">
          <el-table-column prop="id" label="ID" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column label="所属地区" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <p>
                {{ scope.row.spProvinceId }}
                {{ scope.row.spCityId }}
                {{ scope.row.spAreaId }}
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="spAddress" label="详细地址" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="spName" label="商家名称" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="spNature" label="商家性质" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="busLicense" label="营业执照" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="certificationStatus" label="认证状态" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <p>
                {{ scope.row.certificationStatus == '1' ? '已认证' : '未认证' }}
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="contactPerson" label="负责人姓名" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="contactPhone" label="联系电话" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="serviceItems" label="服务项目" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="serviceArea" label="服务区域" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="serviceStatus" label="服务状态" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <p>
                {{ scope.row.serviceStatus == '1' ? '正常服务' : '停止服务' }}
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="busScale" label="商家规模" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column fixed="right" label="操作" width="120">
            <template slot-scope="scope">
              <el-button @click="openReviewDialog(scope.row)" type="text" size="small"> 编辑 </el-button>
              <el-popconfirm title="确定删除此账号吗？" @confirm="delAc(scope.row.id)">
                <el-button slot="reference" type="text" size="small"> 删除</el-button>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
        <div class="pageBox">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-sizes="[5, 10, 20]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="tableData.total"
          >
          </el-pagination>
        </div>
      </div>
      <!-- 服务商审核 -->
      <el-dialog title="审核" :visible.sync="dialogVisible" width="30%">
        <div>
          <el-select v-model="certificationStatus" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="openReview">确 定</el-button>
        </span>
      </el-dialog>
    </el-main>
  </el-container>
</template>
<script>
import { queryBusInfoFindAll, queryBusInfoReview } from '@/api/serviceProvider.js'
export default {
  components: {},
  data() {
    return {
      dialogVisible: false,
      spBusInfo: {},
      options: [
        {
          value: 1,
          label: '同意'
        },
        {
          value: 0,
          label: '拒绝'
        }
      ],
      certificationStatus: '',
      query: {
        page: 1,
        pageSize: 10
      },
      tableData: []
    }
  },
  created() {
    this.init()
  },
  mounted() {},
  methods: {
    //初始化表格
    async init() {
      let result = await queryBusInfoFindAll(this.query)
      if (result.success) {
        this.tableData = result.data
      }
    },
    //分页-size
    handleSizeChange(val) {
      this.query.pageSize = val
      this.init()
    },
    //分页-page
    handleCurrentChange(val) {
      this.query.page = val
      this.init()
    },
    // 显示审核弹框
    openReviewDialog(row) {
      this.dialogVisible = true
      this.spBusInfo = row
    },
    // 审核
    async openReview(row) {
      let fromData = {
        certificationStatus: this.certificationStatus
      }
      let res = await queryBusInfoReview(this.spBusInfo.id, fromData)
      if (res.success) {
        this.dialogVisible = true
        this.$message({
          message: '审核成功！',
          type: 'success'
        })
        this.init()
      } else {
        this.$message.error('发生错误，审核失败!')
      }
    }
  }
}
</script>
<style lang="less" scoped>
.header {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  line-height: 60px;
  display: flex;
  justify-content: space-between;
}
.searchBox {
  margin: 0 16px;
  padding: 16px;
  background: #ffffff;
  border: 1px solid #c7cfcf;
  .searchTit {
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 16px;
  }
  .key_group {
    display: flex;
    .key_group_item {
      display: flex;
      align-items: center;
      margin-right: 24px;
      .key_tit {
        margin-right: 16px;
      }
    }
  }
}
.list {
  padding: 16px;
  .pageBox {
    height: 60px;
    display: flex;
    justify-content: end;
    align-items: center;
  }
}
.el-avatar {
  background: #f2f4f4;
}
</style>
