<template>
  <el-container>
    <el-dialog
      :title="title"
      :visible.sync="addNoticeClass"
      width="20%"
      @close="close"
      append-to-body
      :close-on-click-modal="false"
    >
      <div class="content">
        <div class="input_item" :model="formData" ref="formData">
          <div class="item_vlaue">分类名称</div>
          <div class="item_key">
            <el-input v-model="formData.className" placeholder="请输入内容"></el-input>
          </div>
        </div>
        <div class="input_item" :model="formData" ref="formData">
          <div class="item_vlaue">显示终端</div>
          <div class="item_key">
            <el-radio v-model="formData.displayTerminal" label="0">用户</el-radio>
            <el-radio v-model="formData.displayTerminal" label="1">物业</el-radio>
            <el-radio v-model="formData.displayTerminal" label="2">运营</el-radio>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addNoticeClass = false">取 消</el-button>
        <el-button type="primary" @click="getAddNoticeClassInfo()">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>
<script>
export default {
  props: ['addNoticeClass', 'addNoticeClassInfo', 'title', 'noticeClassDataInfo', 'editNoticeClass'],
  data() {
    return {
      formData: {
        id: '',
        className: '',
        displayTerminal: ''
      },
      addNoticeClassShow: this.addNoticeClass
    }
  },
  watch: {
    noticeClassDataInfo() {
      this.formData.className = this.noticeClassDataInfo.className
      this.formData.displayTerminal = this.noticeClassDataInfo.displayTerminal
      this.formData.id = this.noticeClassDataInfo.id
    }
  },
  methods: {
    close() {
      this.$emit('closeAddNoticeClass')
      this.formData.className = ''
      this.formData.displayTerminal = ''
    },
    async getAddNoticeClassInfo() {
      if (this.title == '新增分类') {
        this.addNoticeClassInfo(this.formData)
        this.addNoticeClass = false
        this.formData.className = ''
        this.formData.displayTerminal = ''
      } else if (this.title == '编辑分类') {
        this.editNoticeClass(this.formData)
        this.addNoticeClass = false
      }
    }
  }
}
</script>
<style lang="less" scoped>
.input_item {
  padding: 8px 0;
  .item_vlaue {
    padding-bottom: 8px;
  }
  .select {
    width: 100%;
  }
}
</style>
