import request from '@/utils/request'
// 服务商信息 - 审核
export function queryBusInfoReview(id, data) {
  return request({
    url: '/sp/busInfo/review/' + id,
    method: 'put',
    data: data
  })
}
// 服务商信息 - 分页查询全部
export function queryBusInfoFindAll(params) {
  console.log(params)
  return request({
    url: '/sp/busInfo/findAll',
    method: 'get',
    params: params
  })
}
// 服务商信息 - 根据id查询
export function queryBusInfoFindId(id) {
  return request({
    url: '/sp/busInfo/' + id,
    method: 'get',
  })
}
// 服务商品分类 - 查询全部
export function queryServiceItems() {
  return request({
    url: '/sp/serviceItems/findAll',
    method: 'get',
  })
}
// 服务商品分类 - 新增
export function addAerviceItems(data) {
  console.log(data)
  return request({
    url: '/sp/serviceItems',
    method: 'POST',
    data: data
  })
}
// 服务商品分类 - 更新
export function putAerviceItems(data, id) {
  console.log(data)
  return request({
    url: '/sp/serviceItems/' + id,
    method: 'put',
    data: data
  })
}
// 服务商品分类 - 删除
export function delServiceItems(id) {
  return request({
    url: '/sp/serviceItems/' + id,
    method: 'delete',
  })
}
// 服务商品 - 根据分类分页查询全部
export function queryItemsServiceGoods(serviceItemsId, params) {
  return request({
    url: '/sp/serviceGoods/serviceItemsId/' + serviceItemsId,
    method: 'get',
    params: params
  })
}
// 服务商品 - 新增
export function addServiceGoodsData(data) {
  console.log(data)
  return request({
    url: '/sp/serviceGoods',
    method: 'POST',
    data: data
  })
}
// 服务商品 - 更新
export function putServiceGoodsDate(data) {
  console.log(data)
  return request({
    url: '/sp/serviceGoods/' + data.id,
    method: 'put',
    data: data
  })
}
// 服务商品 - 删除
export function delServiceGoodsData(id) {
  return request({
    url: '/sp/serviceGoods/' + id,
    method: 'delete',
  })
}
// 服务订单管理 - 根据订单状态分页查询全部
export function queryUserOrdersStatus(ordersStatus, params) {
  return request({
    url: '/sp/userOrders/ordersStatus/' + ordersStatus,
    method: 'get',
    params: params
  })
}
// 服务订单管理 - 根据id 查看订单详情
export function queryUserOrdersById(id) {
  return request({
    url: '/sp/userOrders/' + id,
    method: 'get',
  })
}
// 服务订单管理 - 根据退款/售后id 查看详情
export function queryUserOrdersRefundById(id) {
  return request({
    url: '/sp/userOrdersRefund/' + id,
    method: 'get',
  })
}
// 服务订单管理 - 根据审核退款/售后id
export function queryOrderRefundById(data, id, scale) {
  return request({
    url: '/sp/userOrdersRefund/sysUpdate/' + id + '/' + scale,
    method: 'put',
    data: data
  })
}
// 服务订单管理 - 根据退款/售后id 退款
export function sysConfirmRemittanceByRefundId(id) {
  return request({
    url: '/sysPay/sysConfirmRemittance/' + id,
    method: 'get',
  })
}
// 服务配置 - 新增配置
export function spSetAdd(data) {
  return request({
    url: '/sp/set',
    method: 'post',
    data: data
  })
}
// 服务配置 - 查询全部配置
export function spSetFindAll(data) {
  return request({
    url: '/sp/set/findAll',
    method: 'get',
    params: data
  })
}
// 服务配置 - 更新配置
export function spSetPUT(data) {
  return request({
    url: '/sp/set/' + data.id,
    method: 'put',
    data: data
  })
}
// 服务配置 - 删除配置
export function spSetDelete(data) {
  return request({
    url: '/sp/set/' + data.id,
    method: 'DELETE',
  })
}