<template>
  <el-container>
    <el-header class="header">
      <div>服务订单管理</div>
    </el-header>
    <el-main>
      <!-- 列表 -->
      <div class="list">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="待支付" name="stayPay"></el-tab-pane>
          <el-tab-pane label="待确认" name="stayConfirm"></el-tab-pane>
          <el-tab-pane label="待预约" name="staySub"></el-tab-pane>
          <el-tab-pane label="待服务" name="stayService"></el-tab-pane>
          <el-tab-pane label="已完成" name="completed"></el-tab-pane>
          <el-tab-pane label="退款/售后" name="refunded"></el-tab-pane>
          <el-tab-pane label="取消订单" name="closeOrder"></el-tab-pane>
        </el-tabs>
        <!-- 根据id查询 -->
        <div>
          <el-input placeholder="请输入单号" v-model="searchInput" class="input-with-select">
            <el-button slot="append" type="primary" icon="el-icon-search" @click="openById"></el-button
          ></el-input>
        </div>
        <el-table v-if="this.refundShow == false" :data="tableData.rows" style="width: 100%">
          <el-table-column prop="id" label="ID" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="userId" label="购买用户" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="goodsId" label="商品ID" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="goodsContent" label="订单详情" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="addressInfo" label="收货地址" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="payMode" label="支付方式 " :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="ordersStatus" label="订单状态" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <p>
                {{
                  scope.row.ordersStatus == 0
                    ? '待支付'
                    : scope.row.ordersStatus == 1
                    ? '待确认'
                    : scope.row.ordersStatus == 2
                    ? '在线预约'
                    : scope.row.ordersStatus == 3
                    ? '待服务'
                    : scope.row.ordersStatus == 4
                    ? '已完成'
                    : scope.row.ordersStatus == 5
                    ? '退款/售后'
                    : scope.row.ordersStatus == 7
                    ? '取消订单'
                    : ''
                }}
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="payStatus" label="支付状态" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <p>
                {{ scope.row.payStatus == '1' ? '已支付' : '未支付' }}
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="goodsAmount" label="订单金额" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="plusAmount" label="plus优惠金额" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="subsidyAmount" label="服务补贴金额" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="totalAmount" label="交易金额" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="ordersPayId" label="订单交易id" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="repairAmount" label="补差价" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="repairAmountContent" label="补差价说明" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="repairPayId" label="补差价交易id" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="payAmount" label="实付金额" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="refundPayId" label="退款交易id" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="spBusId" label="商家id" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="spServiceInfo" label="商家服务详情" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="serviceEvaluation" label="用户评价" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column fixed="right" label="操作" width="100">
            <template slot-scope="scope">
              <el-button
                @click="updateMember(scope.row)"
                type="text"
                size="small"
                v-if="scope.row.ordersStatus == 5"
                style="padding-right: 16px"
              >
                查看
              </el-button>
              <el-popconfirm title="确定删除此账号吗？" @confirm="delAc(scope.row.id)">
                <el-button slot="reference" type="text" size="small"> 删除</el-button>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
        <el-table v-if="this.refundShow == true" :data="tableData.rows" style="width: 100%">
          <el-table-column prop="id" label="ID" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="userId" label="购买用户" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="goodsId" label="商品ID" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="goodsContent" label="订单详情" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="addressInfo" label="收货地址" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="payMode" label="支付方式 " :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="ordersStatus" label="订单状态" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <p>
                {{
                  scope.row.ordersStatus == 0
                    ? '待支付'
                    : scope.row.ordersStatus == 1
                    ? '待确认'
                    : scope.row.ordersStatus == 2
                    ? '在线预约'
                    : scope.row.ordersStatus == 3
                    ? '待服务'
                    : scope.row.ordersStatus == 4
                    ? '已完成'
                    : scope.row.ordersStatus == 5
                    ? '退款/售后'
                    : scope.row.ordersStatus == 7
                    ? '取消订单'
                    : ''
                }}
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="payStatus" label="支付状态" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <p>
                {{ scope.row.payStatus == '1' ? '已支付' : '未支付' }}
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="goodsAmount" label="订单金额" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="plusAmount" label="plus优惠金额" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="subsidyAmount" label="服务补贴金额" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="totalAmount" label="交易金额" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="ordersPayId" label="订单交易id" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="repairAmount" label="补差价" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="repairAmountContent" label="补差价说明" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="repairPayId" label="补差价交易id" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="payAmount" label="实付金额" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="refundPayId" label="退款交易id" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="spBusId" label="商家id" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="spServiceInfo" label="商家服务详情" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="serviceEvaluation" label="用户评价" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column fixed="right" label="操作" width="100">
            <template slot-scope="scope">
              <el-button
                @click="refundById(scope.row)"
                v-if="this.refundShow == true"
                type="text"
                size="small"
                style="padding-right: 16px"
              >
                查看
              </el-button>
              <el-popconfirm title="确定删除此账号吗？" @confirm="delAc(scope.row.id)">
                <el-button slot="reference" type="text" size="small"> 删除</el-button>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
        <div class="pageBox">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-sizes="[5, 10, 20]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="tableData.total"
          >
          </el-pagination>
        </div>
      </div>
      <!-- 退款/售后详情 -->
      <refundOrder
        :refundOrder="refundOrderShow"
        @closeRefundOrder="showRefundOrder"
        :refundId="refundId"
      ></refundOrder>
    </el-main>
  </el-container>
</template>
<script>
import { queryUserOrdersStatus, queryUserOrdersRefundById, queryUserOrdersById } from '@/api/serviceProvider.js'
import RefundOrder from '@/components/serviceProvider/refundOrder'
export default {
  components: {
    RefundOrder: RefundOrder
  },
  data() {
    return {
      searchInput: '',
      select: '1',
      activeName: 'stayPay',
      ordersStatus: '0',
      refundShow: false,
      query: {
        page: 1,
        pageSize: 10
      },
      tableData: [],
      refundOrderShow: false, //退款/售后审核
      refundId: null
    }
  },
  created() {
    this.init()
  },
  mounted() {},
  methods: {
    //初始化表格
    async init() {
      let result = await queryUserOrdersStatus(this.ordersStatus, this.query)
      if (result.success) {
        this.tableData = result.data
      }
      console.log('rrr', result)
    },
    //分页-size
    handleSizeChange(val) {
      this.query.pageSize = val
      this.init()
    },
    //分页-page
    handleCurrentChange(val) {
      this.query.page = val
      this.init()
    },
    // 根据订单状态切换
    handleClick(tab) {
      this.refundShow = false
      this.searchInput = ''
      if (tab.index == 6) {
        this.ordersStatus = 7
        this.init()
      } else {
        this.ordersStatus = tab.index
        this.init()
      }
    },
    // 根据id查询
    async openById() {
      if (this.ordersStatus == 5) {
        let list = []
        this.refundShow = true
        let res = await queryUserOrdersRefundById(this.searchInput)
        if (res.success) {
          list.push(res.data)
          this.tableData.rows = list
          this.tableData.total = list.length
        } else {
          this.tableData.rows = list
          this.tableData.total = list.length
        }
      } else {
        let list = []
        this.refundShow = false
        let res = await queryUserOrdersById(this.searchInput)
        if (res.success) {
          list.push(res.data)
          this.tableData.rows = list
          this.tableData.total = list.length
        } else {
          this.tableData.rows = list
          this.tableData.total = list.length
        }
      }
    },
    // 查看根据Id查询退款详情
    refundById(row) {
      this.refundId = row.id
      this.refundOrderShow = true
    },
    // 退款/售后审核
    updateMember(row) {
      this.refundId = row.refundPayId
      this.refundOrderShow = true
    },
    showRefundOrder() {
      this.refundOrderShow = false
    }
  }
}
</script>
<style lang="less" scoped>
.header {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  line-height: 60px;
  display: flex;
  justify-content: space-between;
}
.searchBox {
  margin: 0 16px;
  padding: 16px;
  background: #ffffff;
  border: 1px solid #c7cfcf;
  .searchTit {
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 16px;
  }
  .key_group {
    display: flex;
    .key_group_item {
      display: flex;
      align-items: center;
      margin-right: 24px;
      .key_tit {
        margin-right: 16px;
      }
    }
  }
}
.list {
  padding: 16px;
  .pageBox {
    height: 60px;
    display: flex;
    justify-content: end;
    align-items: center;
  }
}
.el-avatar {
  background: #f2f4f4;
}
</style>
