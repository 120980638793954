<template>
  <el-container>
    <el-dialog
      :title="title"
      :visible.sync="addServiceItems"
      width="20%"
      @close="close"
      append-to-body
      :close-on-click-modal="false"
    >
      <div class="content">
        <div class="input_item" :model="formData" ref="formData">
          <div>
            <div class="item_vlaue">分类名称</div>
            <div class="item_key">
              <el-input v-model="formData.name" placeholder="请输入内容"></el-input>
            </div>
          </div>
          <div class="itemsBox">
            <div class="item_vlaue">描述</div>
            <div class="item_key">
              <el-input type="textarea" v-model="formData.subtitle" placeholder="请输入内容"></el-input>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addServiceItems = false">取 消</el-button>
        <el-button type="primary" @click="getAddKnowClassInfo()">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>
<script>
export default {
  props: ['addServiceItems', 'addServiceItemsInfo', 'title', 'serviceItemsDataInfo', 'editServiceItems'],
  data() {
    return {
      formData: {
        id: '',
        name: '',
        subtitle: ''
      },
      addServiceItemsShow: this.addServiceItems
    }
  },
  watch: {
    serviceItemsDataInfo() {
      this.formData.name = this.serviceItemsDataInfo.name
      this.formData.subtitle = this.serviceItemsDataInfo.subtitle
      this.formData.id = this.serviceItemsDataInfo.id
    }
  },
  methods: {
    close() {
      this.$emit('closeAddServiceItems')
      this.formData.name = ''
      this.formData.subtitle = ''
    },
    async getAddKnowClassInfo() {
      if (this.title == '新增分类') {
        this.addServiceItemsInfo(this.formData)
        this.addServiceItems = false
        this.formData.name = ''
        this.formData.subtitle = ''
      } else if (this.title == '编辑分类') {
        this.editServiceItems(this.formData)
        this.addServiceItems = false
      }
    }
  }
}
</script>
<style lang="less" scoped>
.itemsBox {
  padding-top: 24px;
}
.input_item {
  padding: 8px 0;
  .item_vlaue {
    padding-bottom: 8px;
  }
  .select {
    width: 100%;
  }
}
</style>
