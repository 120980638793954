import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Index from '../views/home/index.vue'
import Login from '../views/login/login.vue'
import Register from '../views/login/register.vue'
import AddArchives from '../views/archives/add.vue'
import Confirmed from '../views/archives/confirmed.vue'
import FileList from '../views/archives/fileList.vue'
import FileDetails from '../views/archives/fileDetails.vue'
import FMDetails from '../views/archives/FMDetails.vue'
import Fail from '../views/archives/fail.vue'
import FRescind from '../views/archives/FRescind.vue'
import PfileListDetails from '../views/property/pfileListDetails.vue'
import PfileList from '../views/property/pfileList.vue'
import Audit from '../views/property/audit.vue'
import AuditDetails from '../views/property/auditDetails.vue'
import AccessFailure from '../views/property/accessFailure.vue'
import RescindList from '../views/property/rescindList.vue'
import Call from '../views/warning/call.vue'
import Seat from '../views/warning/seat.vue'
import Device from '../views/device/device.vue'
import DeviceCompany from '../views/device/deviceCompany.vue'
import DeviceGoods from '../views/device/deviceGoods.vue'
import OpenDevice from '../views/device/openDevice.vue'
import UserAlreadyInsurance from '../views/insuranceManage/userAlreadyInsurance.vue'
import UserStayInsurance from '../views/insuranceManage/userStayInsurance.vue'
import InsuranceCompany from '../views/insuranceManage/insuranceCompany.vue'
import InsuranceGoods from '../views/insuranceManage/insuranceGoods.vue'
import Finance from '../views/finance/finance.vue'
import UserOrderList from '../views/finance/userOrderList.vue'
import UserDepositList from '../views/finance/userDepositList.vue'
import UserDepositPayList from '../views/finance/userDepositPayList.vue'
import SpBusFinance from '../views/finance/spBusFinance.vue'
import Notice from '../views/notice/notice.vue'
import AddNotice from '../views/notice/addNotice.vue'
import Knowledge from '../views/knowledge/knowledge.vue'
import Equipment from '../views/warning/equipment.vue'
import Incoming from '../views/traffic/incoming.vue'
import Exhale from '../views/traffic/exhale.vue'
import Database from '../views/personBase/database.vue'
import Operation from '../views/account/operation.vue'
import ServiceGoods from '../views/serviceGoods/serviceGoods.vue'
import AppOperateImg from '../views/appOperateImg/appOperateImg.vue'
import AppOperateConfig from '../views/appOperateImg/appOperateConfig.vue'
import CashCoupon from '../views/activity/cashCoupon.vue'
import DepositList from '../views/deposit/depositList.vue'
import BranchCompany from '../views/hkGroup/branchCompany.vue'
import SpBusInfo from '../views/serviceProvider/spBusInfo.vue'
import SpServiceGoods from '../views/serviceProvider/spServiceGoods.vue'
import SpUserOrders from '../views/serviceProvider/spUserOrders.vue'
import MemberCard from '../views/memberManage/memberCard.vue'
import SpSet from '../views/serviceProvider/spSet.vue'
import HkUnionGoods from '../views/unionGoods/unionGoods.vue'
import HkFoods from '../views/hkFoods/hkFoods.vue'
import HkFoodsTags from '../views/hkFoods/tags.vue'
import CooInstall from '../views/coo/cooInstall.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/login' }, // 默认
  // 登录
  { path: '/login', component: Login },
  // 注册
  { path: '/register', component: Register },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    children: [
      { path: '/', redirect: '/index' }, // 默认
      // 首页
      { path: '/index', component: Index },
      // 居民档案
      { path: '/addArchives', component: AddArchives }, // 新增接入
      { path: '/confirmed', component: Confirmed }, // 待确认接入
      { path: '/fileList', component: FileList }, // 档案列表
      { path: '/fileDetails', component: FileDetails }, // 档案详情
      { path: '/FMDetails', component: FMDetails }, // 成员详情
      { path: '/fail', component: Fail }, // 接入失败
      { path: '/FRescind', component: FRescind }, // 解约记录
      // 物业管理
      { path: '/audit', component: Audit }, // 待审核
      { path: '/auditDetails', component: AuditDetails }, // 待审核-详情
      { path: '/pfileList', component: PfileList }, // 档案列表
      { path: '/pfileListDetails', component: PfileListDetails }, // 小区详情
      { path: '/accessFailure', component: AccessFailure }, // 接入失败
      { path: '/rescindList', component: RescindList }, // 解约记录
      // 合作管理
      { path: '/cooInstall', component: CooInstall }, // 合作待安装申请
      // 预警管理
      { path: '/seat', component: Seat }, // 坐席预警
      { path: '/call', component: Call }, // 来电预警
      { path: '/equipment', component: Equipment }, // 设备预警
      // 设备管理
      { path: '/device', component: Device }, // 设备管理
      { path: '/deviceCompany', component: DeviceCompany }, // 设备公司
      { path: '/deviceGoods', component: DeviceGoods }, // 设备公司
      { path: '/openDevice', component: OpenDevice }, // 设备开通
      // 保险管理
      { path: '/userAlreadyInsurance', component: UserAlreadyInsurance }, // 已购保险
      { path: '/userStayInsurance', component: UserStayInsurance }, // 待购保险
      { path: '/insuranceCompany', component: InsuranceCompany }, // 保险公司
      { path: '/insuranceGoods', component: InsuranceGoods }, // 保险套餐
      // 服务商管理
      { path: '/spBusInfo', component: SpBusInfo }, // 服务商信息
      { path: '/spServiceGoods', component: SpServiceGoods }, // 服务商品管理
      { path: '/spUserOrders', component: SpUserOrders }, // 服务订单管理
      { path: '/spSet', component: SpSet }, // 服务商配置
      // 财务管理
      { path: '/finance', component: Finance }, // 财务报表
      { path: '/userDepositPayList', component: UserDepositPayList }, // 押金收入列表
      { path: '/userOrderList', component: UserOrderList }, // 用户服务订单
      { path: '/userDepositList', component: UserDepositList }, // 用户支付押金订单
      { path: '/spBusFinance', component: SpBusFinance }, // 服务商周账单
      // 通知公告
      { path: '/notice', component: Notice }, // 通知
      { path: '/addNotice', component: AddNotice }, // 新增通知
      // 知识库
      { path: '/knowledge', component: Knowledge }, // 知识
      // 话务管理
      { path: '/incoming', component: Incoming }, // 呼入记录
      { path: '/exhale', component: Exhale }, // 呼出记录
      // 资料管理
      { path: '/database', component: Database }, // 资料库
      //账号管理
      { path: '/operation', component: Operation }, // 运营账号
      //服务管理
      { path: '/serviceGoods', component: ServiceGoods }, // 用户服务分类
      // 会员管理
      { path: '/memberCard', component: MemberCard }, // 会员卡管理
      //APP运营管理
      { path: '/appOperateImg', component: AppOperateImg }, // APP运营图片管理
      { path: '/appOperateConfig', component: AppOperateConfig }, // APP运营配置
      //活动管理
      { path: '/cashCoupon', component: CashCoupon }, // 代金券
      //押金管理
      { path: '/depositList', component: DepositList }, // 押金列表
      //菏康集团
      { path: '/branchCompany', component: BranchCompany }, // 公司列表
      // 菏康好物
      { path: '/HkUnionGoods', component: HkUnionGoods }, // 菏康好物
      // 菏康饮食
      { path: '/HkFoods', component: HkFoods }, // 饮食管理
      { path: '/HkFoodsTags', component: HkFoodsTags }, // 标签管理

    ]
  }
]

const router = new VueRouter({
  routes
})

export default router
