<template>
  <el-container>
    <el-header class="header">
      <div>运营账号</div>
      <div>
        <el-button type="primary" @click="createMember">新增成员</el-button>
      </div>
    </el-header>
    <el-main>
      <!-- 列表 -->
      <div class="list">
        <!-- 打开开通弹框 -->
        <addOpen :addOpen="openShow" @closeOpen="showOpen"></addOpen>
        <el-table :data="tableData" style="width: 100%">
          <el-table-column prop="id" label="ID" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="avatar" label="头像" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <el-avatar shape="square" :src="scope.row.avatar"></el-avatar>
            </template>
          </el-table-column>
          <el-table-column prop="roleName" label="角色名称" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <p>
                {{
                  scope.row.roleName == '0'
                    ? '超级管理员'
                    : scope.row.roleName == '1'
                    ? '市场人员'
                    : scope.row.roleName == '2'
                    ? '安装人员'
                    : scope.row.roleName == '3'
                    ? '顾问团'
                    : ''
                }}
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="resArea" label="负责地区" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="power" label="权限说明" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="fullName" label="负责人" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="mobile" label="联系电话" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="createTime" label="开通时间" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column fixed="right" label="操作" width="120">
            <template slot-scope="scope">
              <el-button @click="updateMember(scope.row)" type="text" size="small"> 编辑 </el-button>
              <el-popconfirm title="确定删除此账号吗？" @confirm="delAc(scope.row.id)">
                <el-button slot="reference" type="text" size="small"> 删除</el-button>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 隐私电话弹框 -->
      <privacyCall :privacyCall="privacyCallShow" @closePrivacyCall="showPrivacyCall"></privacyCall>
      <!-- 拒绝弹框 -->
      <refuse :refuse="refuseShow" @closeRefuse="showRefuse"></refuse>
    </el-main>
  </el-container>
</template>
<script>
import AddOpen from '@/components/archives/addOpen'
import PrivacyCall from '@/components/public/privacyCall'
import Refuse from '@/components/archives/refuse'
import { queryAcList, createForm, delAccount, updateAccount } from '@/api/account.js'
import { formatDate } from '@/utils/index.js'
import Dlg from './comp/dlg.vue'
export default {
  props: ['addOpen', 'privacyCall', 'refuse'],
  components: {
    addOpen: AddOpen,
    privacyCall: PrivacyCall,
    refuse: Refuse,
    Dlg
  },
  data() {
    return {
      openShow: false,
      refuseShow: false,
      privacyCallShow: false,
      formatDate: formatDate,
      query: {
        page: 1,
        pageSize: 10
      },
      tableData: []
    }
  },
  created() {
    this.init()
  },
  mounted() {},
  methods: {
    //初始化表格
    async init() {
      let result = await queryAcList(this.query)
      console.log(result)
      if (result.success) {
        this.tableData = result.data
      }
    },
    //提交表单
    async submitCb(form) {
      let result = await createForm(form)
      if (result.success) {
        this.$message.success('操作成功')
        this.init()
      }
    },
    createMember() {
      this.$create(Dlg, {
        title: '新增账号',
        submitCb: this.submitCb
      }).show()
    },
    updateMember(data) {
      this.$create(Dlg, {
        title: '编辑账号',
        submitCb: this.updateAc,
        recordData: data
      }).show()
    },
    async delAc(id) {
      let result = await delAccount(id)
      if (result.success) {
        this.init()
      }
    },
    async updateAc(form) {
      let result = await updateAccount(form)
      if (result.success) {
        this.init()
      }
    },
    showOpen() {
      this.openShow = false
    },
    showPrivacyCall() {
      this.privacyCallShow = false
    },
    openRefuseRow(index) {
      this.refuseShow = true
    },
    showRefuse() {
      this.refuseShow = false
    },
    callbackRow(index) {
      console.log(index)
      this.privacyCallShow = true
    },
    seeRow(index) {
      console.log(index)
    }
  }
}
</script>
<style lang="less" scoped>
.header {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  line-height: 60px;
  display: flex;
  justify-content: space-between;
}
.searchBox {
  margin: 0 16px;
  padding: 16px;
  background: #ffffff;
  border: 1px solid #c7cfcf;
  .searchTit {
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 16px;
  }
  .key_group {
    display: flex;
    .key_group_item {
      display: flex;
      align-items: center;
      margin-right: 24px;
      .key_tit {
        margin-right: 16px;
      }
    }
  }
}
.list {
  padding: 16px;
  .pageBox {
    height: 60px;
    display: flex;
    justify-content: end;
    align-items: center;
  }
}
.el-avatar {
  background: #f2f4f4;
}
</style>
