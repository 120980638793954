<template>
  <el-container>
    <el-dialog title="签约" :visible.sync="editAudit" width="30%" @close="close" :close-on-click-modal="false">
      <div class="content" :model="fromData" ref="fromData">
        <div class="input_item">
          <div class="item_vlaue">合同编号</div>
          <div class="item_key">
            <el-input v-model="fromData.contractNo" placeholder="请输入合同编号"></el-input>
          </div>
        </div>
        <div class="input_item">
          <div class="item_vlaue">合作期限</div>
          <div class="item_key">
            <el-input v-model="fromData.term" placeholder="请输入合作期限"></el-input>
          </div>
        </div>
        <div class="input_item">
          <div class="item_vlaue">开始日期</div>
          <div class="item_key">
            <el-date-picker v-model="fromData.startTime" type="date" placeholder="选择开始日期">
            </el-date-picker>
          </div>
        </div>
        <div class="input_item">
          <div class="item_vlaue">到期日期</div>
          <div class="item_key">
            <el-date-picker v-model="fromData.entTime" type="date" placeholder="选择到期日期">
            </el-date-picker>
          </div>
        </div>
        <div class="input_item">
          <div class="item_vlaue">合同照片</div>
          <div class="item_key">
            <el-upload action="https://jsonplaceholder.typicode.com/posts/" list-type="picture-card" :on-preview="handlePictureCardPreview" :on-remove="handleRemove">
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="">
            </el-dialog>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editAudit = false">取 消</el-button>
        <el-button type="primary" @click="getAddContract()">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>
<script>
import { queryAddContract, queryPropertyRescind, queryPropertyContactPhone } from '@/api/property.js'
export default {
  props: ['editAudit', 'propertyIdInfo'],
  data () {
    return {
      propertyName: '',
      fromData: {
        propertyId: '',
        contractNo: '',
        startTime: '',
        entTime: '',
        contractImg: '',
        mobile: '13700000000',
        name: 'sang',
        term: ''
      },
      dialogImageUrl: '',
      dialogVisible: false
    }
  },
  watch: {
    'propertyIdInfo' (n, o) {
      this.propertyName = n.propertyName
    }
  },
  methods: {
    // 添加物业合同
    async getAddContract () {
      // 先执行将待审核的信息转移到已审核列表
      let res = await queryPropertyRescind(this.propertyIdInfo.id, this.propertyIdInfo)
      if (res.success) {
        // 根据负责人手机号查询物业信息
        let ress = await queryPropertyContactPhone(this.propertyIdInfo.contactPhone)
        this.fromData.propertyId = ress.data.id
        if (ress.success) {
          let result = await queryAddContract(this.fromData)
          console.log(result)
          if (result.success) {
            this.$message.success('签约成功')
            this.editAudit = false
            this.$router.push({ path: '/audit' })
          } else {
            this.$message.success('签约失败')
          }
        }
      }
    },
    close () {
      this.$emit('closeEditAudit')
    },
    handleRemove (file, fileList) {
      console.log(file, fileList)
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    }
  }
}
</script>
<style lang="less" scoped>
.input_item {
  padding: 8px 0;
  .item_vlaue {
    padding-bottom: 8px;
  }
  .item_time {
    width: 100%;
  }
}
.el-date-editor.el-input {
  width: 100%;
}
</style>
