<template>
  <el-container>
    <el-dialog
      :title="title"
      :visible.sync="addServiceGoods"
      width="50%"
      @close="close"
      @opened="getEditor()"
      append-to-body
      :close-on-click-modal="false"
    >
      <div class="content">
        <el-form ref="formData" :model="formData" label-width="80px">
          <el-form-item label="服务项目:">
            <el-input type="textarea" v-model="formData.serviceTitle"></el-input>
          </el-form-item>
          <el-form-item label="所属分类:">
            <el-select v-model="formData.serviceItemsId" placeholder="请选择" class="f_sekect">
              <el-option v-for="item in serviceItemsList" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="服务地区:">
            <el-cascader
              v-model="formData.serviceArea"
              ref="cascader"
              placeholder="试试搜索：指南"
              :props="proCityAreaTreeProps"
              :options="proCityFindAll"
              filterable
            ></el-cascader>
          </el-form-item>
          <el-form-item label="服务图片JsonList:">
            <el-input type="textarea" v-model="formData.goodsImage"></el-input>
          </el-form-item>
          <el-form-item label="起步价:">
            <el-input v-model="formData.floorPrice"></el-input>
          </el-form-item>
          <el-form-item label="已售数量:">
            <el-input v-model="formData.sold"></el-input>
          </el-form-item>
          <el-form-item label="plus状态:">
            <el-input type="textarea" v-model="formData.plusStatus"></el-input>
          </el-form-item>
          <el-form-item label="服务描述:">
            <el-input type="textarea" v-model="formData.serviceDec"></el-input>
          </el-form-item>
          <div class="groupBox">
            <div>上门时间</div>
            <!-- 上门时间 -->
            <!-- <el-form :model="serviceContentJson" ref="serviceContentJson" label-width="100px" class="formItemsBox">
              <div class="small_tit">
                <div class="item_tit">上门时间</div>
                <div class="item_dec">如清洁工具、使用场景等</div>
              </div>
              <div
                class="value_items"
                v-for="(timeInfo, index) in serviceContentJson.comeDoorTime"
                :key="timeInfo.key"
                :prop="'domains.' + index + '.value'"
              >
                <el-form-item class="v_i_item" prop="value" label="时间范围:">
                  <el-input v-model="timeInfo.value"></el-input>
                </el-form-item>
                <div class="item_btn" @click.prevent="removeTimeInfo(timeInfo)">删除</div>
              </div>
              <el-button class="addBtn" type="primary" size="mini" icon="el-icon-plus" @click="addTimeInfo"
                >新增时长</el-button
              >
            </el-form> -->
          </div>
          <div class="groupBox">
            <div>服务内容</div>
            <!-- 清洁面积 -->
            <el-form :model="serviceContentJson" ref="serviceContentJson" label-width="100px" class="formItemsBox">
              <div class="small_tit">
                <div class="item_tit">清洁面积</div>
                <div class="item_dec">增加数量决定用户可选择范围</div>
              </div>
              <div
                class="value_items"
                v-for="(area, index) in serviceContentJson.cleanArea"
                :key="area.key"
                :prop="'domains.' + index + '.value'"
              >
                <el-form-item class="v_i_item" prop="value" label="面积:">
                  <el-input v-model="area.value"></el-input>
                </el-form-item>
                <div class="item_btn" @click.prevent="removeArea(area)">删除</div>
              </div>
              <el-button class="addBtn" type="primary" size="mini" icon="el-icon-plus" @click="addArea"
                >新增面积</el-button
              >
            </el-form>
            <!-- 服务时长 -->
            <el-form :model="serviceContentJson" ref="serviceContentJson" label-width="100px" class="formItemsBox">
              <div class="small_tit">
                <div class="item_tit">服务时长</div>
                <div class="item_dec">增加数量决定用户可选择范围</div>
              </div>
              <div
                class="value_items"
                v-for="(duration, index) in serviceContentJson.serviceDuration"
                :key="duration.key"
                :prop="'domains.' + index + '.value'"
              >
                <el-form-item class="v_i_item" prop="value" label="时长:">
                  <el-input v-model="duration.value"></el-input>
                </el-form-item>
                <el-form-item class="v_i_item" prop="label" label="价格:">
                  <el-input v-model="duration.label"></el-input>
                </el-form-item>
                <div class="item_btn" @click.prevent="removeDuration(duration)">删除</div>
              </div>
              <el-button class="addBtn" type="primary" size="mini" icon="el-icon-plus" @click="addDuration"
                >新增时长</el-button
              >
            </el-form>
            <!-- 普通新增 -->
            <el-form :model="serviceContentJson" ref="serviceContentJson" label-width="100px" class="formItemsBox">
              <div
                class="value_items"
                v-for="(domain, index) in serviceContentJson.domains"
                :key="domain.key"
                :prop="'domains.' + index + '.value'"
              >
                <el-form-item class="v_i_item" prop="value" label="标题:">
                  <el-input v-model="domain.value"></el-input>
                </el-form-item>
                <el-form-item class="v_i_item" prop="label" label="说明:">
                  <el-input type="textarea" autosize v-model="domain.label"></el-input>
                </el-form-item>
                <div class="item_btn" @click.prevent="removeDomain(domain)">删除</div>
              </div>
              <el-button class="addGroup" type="primary" size="mini" icon="el-icon-plus" @click="addDomain"
                >新增字段</el-button
              >
            </el-form>
            <!-- 注意事项 -->
            <el-form :model="serviceContentJson" ref="serviceContentJson" label-width="100px" class="formItemsBox">
              <div class="small_tit">
                <div class="item_tit">注意事项</div>
                <div class="item_dec">如清洁工具、使用场景等</div>
              </div>
              <div
                class="value_items"
                v-for="(lookMatterInfo, index) in serviceContentJson.lookMatter"
                :key="lookMatterInfo.key"
                :prop="'domains.' + index + '.value'"
              >
                <el-form-item class="v_i_item" prop="value" label="标题:">
                  <el-input v-model="lookMatterInfo.value"></el-input>
                </el-form-item>
                <el-form-item class="v_i_item" prop="label" label="描述:">
                  <el-input type="textarea" v-model="lookMatterInfo.label"></el-input>
                </el-form-item>
                <div class="item_btn" @click.prevent="removeLookMatterInfo(lookMatterInfo)">删除</div>
              </div>
              <el-button class="addBtn" type="primary" size="mini" icon="el-icon-plus" @click="addLookMatterInfo"
                >新增事项</el-button
              >
            </el-form>
          </div>
          <el-form-item label="图文详情:">
            <div id="div1"></div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addServiceGoods = false">取 消</el-button>
        <el-button type="primary" @click="getAddServiceGoodsInfo()">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>
<script>
import { proCityFindAll } from '@/api/public.js'
import E from 'wangeditor'
let editor
export default {
  props: [
    'addServiceGoods',
    'addServiceGoodsInfo',
    'navDataList',
    'serviceGoodsDataInfo',
    'title',
    'editServiceGoodsData'
  ],
  data() {
    return {
      formData: {
        serviceTitle: '',
        serviceDec: '',
        serviceArea: '',
        serviceItemsId: '',
        serviceDetails: '',
        serviceContent: '',
        floorPrice: null,
        sold: null,
        plusStatus: null
      },
      serviceItemsList: [],
      addServiceGoodsShow: this.addServiceGoods,
      serviceContentJson: {
        comeDoorTime: [],
        serviceDuration: [],
        cleanArea: [],
        lookMatter: [],
        domains: []
      },
      proCityFindAll: [],
      proCityAreaTreeProps: {
        emitPath: false,
        value: 'code',
        label: 'name',
        children: 'children'
      }
    }
  },
  watch: {
    navDataList() {
      this.serviceItemsList = this.navDataList
    },
    serviceGoodsDataInfo() {
      this.formData = this.serviceGoodsDataInfo
      editor.txt.html(this.formData.serviceDetails)
      let serviceContent = JSON.parse(this.serviceGoodsDataInfo.serviceContent)
      this.serviceContentJson = serviceContent
    },
    title() {
      if (this.title == '新增服务') {
        this.formData.serviceTitle = ''
        this.formData.serviceArea = ''
        this.formData.serviceDec = ''
        this.formData.serviceItemsId = ''
        this.formData.serviceContent = ''
        this.formData.floorPrice = null
        this.formData.sold = null
        this.formData.plusStatus = null
        editor.txt.html((this.formData.serviceDetails = ''))
      }
    }
  },
  mounted() {
    this.getProCityFindAll()
  },
  methods: {
    async getProCityFindAll() {
      let res = await proCityFindAll()
      if (res.success) {
        this.proCityFindAll = res.data
      }
    },
    handleChange(value) {
      let nodesObj = this.$refs['cascader'].getCheckedNodes()
      this.formData.serviceArea = nodesObj[0].path[1]
    },
    close() {
      this.$emit('closeAddServiceGoods')
    },
    getEditor() {
      editor = new E('#div1')
      editor.create()
    },
    removeDomain(item) {
      var index = this.serviceContentJson.domains.indexOf(item)
      if (index !== -1) {
        this.serviceContentJson.domains.splice(index, 1)
      }
    },
    removeDuration(item) {
      var index = this.serviceContentJson.serviceDuration.indexOf(item)
      if (index !== -1) {
        this.serviceContentJson.serviceDuration.splice(index, 1)
      }
    },
    removeArea(item) {
      var index = this.serviceContentJson.cleanArea.indexOf(item)
      if (index !== -1) {
        this.serviceContentJson.cleanArea.splice(index, 1)
      }
    },
    removeLookMatterInfo(item) {
      var index = this.serviceContentJson.lookMatter.indexOf(item)
      if (index !== -1) {
        this.serviceContentJson.lookMatter.splice(index, 1)
      }
    },
    removeTimeInfo(item) {
      var index = this.serviceContentJson.comeDoorTime.indexOf(item)
      if (index !== -1) {
        this.serviceContentJson.comeDoorTime.splice(index, 1)
      }
    },
    // 上门时间
    addTimeInfo() {
      this.serviceContentJson.comeDoorTime.push({
        value: ''
      })
    },
    // 新增服务时长
    addDuration() {
      this.serviceContentJson.serviceDuration.push({
        value: '',
        label: ''
      })
    },
    // 新增清洁面积
    addArea() {
      this.serviceContentJson.cleanArea.push({
        value: ''
      })
    },
    // 注意事项
    addLookMatterInfo() {
      this.serviceContentJson.lookMatter.push({
        value: '',
        label: ''
      })
    },
    // 普通新增
    addDomain() {
      this.serviceContentJson.domains.push({
        value: '',
        label: ''
      })
    },
    // 提交表单
    async getAddServiceGoodsInfo() {
      this.formData.serviceContent = JSON.stringify(this.serviceContentJson)
      const content = editor.txt.html()
      this.formData.serviceDetails = content
      if (this.title == '新增服务') {
        console.log('新增服务', this.formData)
        this.addServiceGoodsInfo(this.formData)
        this.addServiceGoods = false
        this.formData.serviceTitle = ''
        this.formData.serviceDec = ''
        this.formData.serviceItemsId = ''
        this.formData.serviceContent = ''
        editor.txt.html((this.formData.serviceDetails = ''))
      } else if (this.title == '编辑服务') {
        console.log('编辑服务', this.formData)
        this.editServiceGoodsData(this.formData)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.el-select,
.el-cascader {
  width: 100%;
}
.groupBox {
  margin-bottom: 16px;
}
.formItemsBox {
  background: #f2f3f4;
  padding: 16px;
  margin-top: 8px;
  .small_tit {
    display: flex;
    justify-content: space-between;
    .item_dec {
      font-size: 12px;
      opacity: 0.6;
    }
  }
  .value_items {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .v_i_item {
      width: 50%;
      margin-top: 8px;
      margin-bottom: 0;
    }
    .item_btn {
      padding: 0 16px;
      width: 32px;
      margin-top: 8px;
      line-height: 40px;
      color: red;
    }
  }
  .addBtn {
    margin-top: 8px;
  }
}
</style>
