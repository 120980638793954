<template>
  <el-container>
    <el-dialog
      :title="title"
      :visible.sync="appOperateConfig"
      width="50%"
      @close="close"
      append-to-body
      :close-on-click-modal="false"
    >
      <div class="content">
        <el-form ref="formData" :model="formData" label-width="80px">
          <el-form-item label="模块名称: ">
            <el-radio-group v-model="formData.moduleTitle">
              <el-radio label="首页配置">首页配置</el-radio>
            </el-radio-group>
          </el-form-item>
          <div class="groupBox">
            <el-tabs v-model="activeName">
              <el-tab-pane label="金刚区配置" name="gonggeData">
                <el-form :model="configJson" ref="configJson" label-width="100px" class="formItemsBox">
                  <div
                    class="value_items"
                    v-for="(gonggeInfo, index) in configJson.gonggeData"
                    :key="gonggeInfo.key"
                    :prop="'domains.' + index + '.value'"
                  >
                    <el-row class="rowContent">
                      <el-col :span="2">
                        <el-form-item label-width="0" class="item_input">
                          <el-upload
                            class="avatar-uploader"
                            :action="`https://kwangda.com:8092/sys/appImg/upload/${formData.appType} ${
                              new Date().getTime() / 1000
                            }`"
                            :show-file-list="false"
                            :on-success="fileSuced"
                            :before-upload="beforeFileUp"
                          >
                            <img v-if="gonggeInfo.imageUrl" :src="gonggeInfo.imageUrl" class="avatar" />
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                          </el-upload>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-form-item label="标题:" label-width="50px" class="item_input">
                          <el-input v-model="gonggeInfo.title" placeholder="4个字以内"></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item label="选择商品:" label-width="100px" class="item_input">
                          <el-select v-model="gonggeInfo.goodsId" placeholder="请选择">
                            <el-option
                              v-for="item in goodsList"
                              :key="item.id"
                              :label="item.serviceTitle"
                              :value="item.id"
                            >
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                      <el-col :span="2">
                        <div class="item_btn" @click.prevent="removeGonggeInfo(gonggeInfo)">删除</div>
                      </el-col>
                    </el-row>
                  </div>
                  <el-button class="addBtn" type="primary" size="mini" icon="el-icon-plus" @click="addGonggeInfo">
                    新增
                  </el-button>
                </el-form>
              </el-tab-pane>
              <el-tab-pane label="主分类配置" name="mainClassData">
                <el-form :model="configJson" ref="configJson" label-width="100px" class="formItemsBox">
                  <div
                    class="value_items"
                    v-for="(mainClassInfo, index) in configJson.mainClassData"
                    :key="mainClassInfo.key"
                    :prop="'domains.' + index + '.value'"
                  >
                    <el-row class="rowContent">
                      <el-col :span="4">
                        <el-form-item label="图片:" label-width="50px" class="item_input">
                          <el-upload
                            class="avatar-uploader"
                            :action="`https://kwangda.com:8092/sys/appImg/upload/${formData.appType} ${
                              new Date().getTime() / 1000
                            }`"
                            :show-file-list="false"
                            :on-success="fileSuced"
                            :before-upload="beforeFileUp"
                          >
                            <img v-if="mainClassInfo.imageUrl" :src="mainClassInfo.imageUrl" class="avatar" />
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                          </el-upload>
                        </el-form-item>
                      </el-col>
                      <el-col :span="18">
                        <el-form-item label="选择分类:" label-width="100px" class="item_input">
                          <el-select v-model="mainClassInfo.goodsItemId" placeholder="请选择">
                            <el-option
                              v-for="item in goodsItemsList"
                              :key="item.id"
                              :label="item.name"
                              :value="item.id"
                            >
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                      <el-col :span="2">
                        <div class="item_btn" @click.prevent="removeMainClassInfo(mainClassInfo)">删除</div>
                      </el-col>
                    </el-row>
                  </div>
                  <el-button class="addBtn" type="primary" size="mini" icon="el-icon-plus" @click="addMainClassInfo">
                    新增
                  </el-button>
                </el-form>
              </el-tab-pane>
              <el-tab-pane label="商品区配置" name="goodsListData">
                <el-form :model="configJson" ref="configJson" label-width="100px" class="formItemsBox">
                  <div
                    class="value_items"
                    v-for="(goodsListInfo, index) in configJson.goodsListData"
                    :key="goodsListInfo.key"
                    :prop="'domains.' + index + '.value'"
                  >
                    <el-row class="rowContent">
                      <el-col :span="6">
                        <el-form-item label="标题:" label-width="50px" class="item_input">
                          <el-input v-model="goodsListInfo.title" placeholder="4个字以内"></el-input>
                        </el-form-item>
                      </el-col>

                      <el-col :span="6">
                        <el-form-item label="描述:" label-width="50px" class="item_input">
                          <el-input v-model="goodsListInfo.dec" placeholder="4个字以内"></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="10">
                        <el-form-item label="选择分类:" label-width="100px" class="item_input">
                          <el-select v-model="goodsListInfo.goodsItemId" placeholder="请选择">
                            <el-option
                              v-for="item in goodsItemsList"
                              :key="item.id"
                              :label="item.name"
                              :value="item.id"
                            >
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                      <el-col :span="2">
                        <div class="item_btn" @click.prevent="removeGoodsListInfo(goodsListInfo)">删除</div>
                      </el-col>
                    </el-row>
                  </div>
                  <el-button class="addBtn" type="primary" size="mini" icon="el-icon-plus" @click="addGoodsListInfo">
                    新增
                  </el-button>
                </el-form>
              </el-tab-pane>
            </el-tabs>
          </div>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="appOperateConfig = false">取 消</el-button>
        <el-button type="primary" @click="getAppOperateConfig()">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>
<script>
import { getServiceGoodsFindAll, getServiceItemsFindAll } from '@/api/appOperate.js'
export default {
  props: ['appOperateConfig', 'appOperateConfigFormData', 'appOperateDataInfo', 'title', 'editOperateInfo'],
  data() {
    return {
      activeName: 'gonggeData',
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      formData: {
        id: '',
        moduleTitle: '',
        moduleData: ''
      },
      configJson: {
        gonggeData: [],
        mainClassData: [],
        goodsListData: []
      },
      goodsList: [],
      goodsItemsList: [],
      appOperateConfigShow: this.appOperateConfig
    }
  },
  watch: {
    appOperateDataInfo() {
      this.formData = this.appOperateDataInfo
      let json = JSON.parse(this.appOperateDataInfo.moduleData)
      this.configJson.gonggeData = json.gonggeData
      this.configJson.mainClassData = json.mainClassData
      this.configJson.goodsListData = json.goodsListData
    }
  },
  mounted() {
    this.getGoodsList()
    this.getGoodsItemsList()
    console.log('ddd', this.formData)
  },
  methods: {
    close() {
      this.$emit('closeAppOperateConfig')
    },
    // 查询服务商品列表
    async getGoodsList() {
      let res = await getServiceGoodsFindAll()
      if (res.success) {
        this.goodsList = res.data
      }
    },
    // 查询服务分类列表
    async getGoodsItemsList() {
      let res = await getServiceItemsFindAll()
      if (res.success) {
        this.goodsItemsList = res.data
      }
    },
    // 新增金刚区配置
    addGonggeInfo() {
      this.configJson.gonggeData.push({
        title: '',
        imageUrl: '',
        goodsId: ''
      })
    },
    removeGonggeInfo(item) {
      var index = this.configJson.gonggeData.indexOf(item)
      if (index !== -1) {
        this.configJson.gonggeData.splice(index, 1)
      }
    },
    // 新增主分类配置
    addMainClassInfo() {
      this.configJson.mainClassData.push({
        imageUrl: '',
        goodsItemId: ''
      })
    },
    removeMainClassInfo(item) {
      var index = this.configJson.mainClassData.indexOf(item)
      if (index !== -1) {
        this.configJson.mainClassData.splice(index, 1)
      }
    },
    // 新增商品区配置
    addGoodsListInfo() {
      this.configJson.goodsListData.push({
        title: '',
        dec: '',
        goodsItemId: ''
      })
    },
    removeGoodsListInfo(item) {
      var index = this.configJson.goodsListData.indexOf(item)
      if (index !== -1) {
        this.configJson.goodsListData.splice(index, 1)
      }
    },
    // 提交表单
    async getAppOperateConfig() {
      this.formData.moduleData = JSON.stringify(this.configJson)
      if (this.title == '新增配置') {
        this.appOperateConfigFormData(this.formData)
        this.appOperateConfig = false
      } else if (this.title == '编辑配置') {
        this.editOperateInfo(this.formData)
        this.addKnowClass = false
      }
    },
    handleRemove(file) {
      // console.log(file)
    },
    async handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    handleDownload(file) {
      // console.log(file)
    },
    // beforeFileUp() {
    //   if (!this.formData.appType) {
    //     this.$message.error('请先输入APP类型')
    //     return false
    //   }
    // },
    fileSuced(res) {
      if (res.success) {
        console.log('文件上传成功：：', res)
        this.formData.imgUrl = res.data
        this.$message.success('上传成功')
      }
    }
  }
}
</script>
<style lang="less" scoped>
.el-input {
  width: 100%;
}
.groupBox {
  margin-bottom: 16px;
}

.formItemsBox {
  background: #f2f3f4;
  padding: 16px;
  margin-top: 8px;
  .value_items {
    width: 100%;
    margin-bottom: 8px;
    .item_btn {
      width: 32px;
      line-height: 40px;
      color: red;
    }
  }
  .addBtn {
    margin-top: 8px;
  }
}
.item_input {
  margin: 0;
  width: 100%;
}
.avatar-uploader {
  width: 44px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader:hover {
  border-color: #09bebe;
}
.avatar-uploader-icon {
  font-size: 16px;
  color: #8c939d;
  width: 44px;
  height: 44px;
  line-height: 44px;
  text-align: center;
}
.avatar {
  width: 44px;
  height: 44px;
  display: block;
}
</style>
