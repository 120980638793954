<template>
  <el-container>
    <el-dialog
      title="批量新增"
      :visible.sync="isShow"
      width="50%"
      @close="close"
      append-to-body
      :close-on-click-modal="false"
    >
      <div class="content">
        <el-form ref="formData" :model="formData" label-width="80px">
          <el-form-item label="分类:">
            <el-input v-model="formData.couponClass" placeholder="分类"></el-input>
          </el-form-item>
          <el-form-item label="名称:">
            <el-input v-model="formData.couponName" placeholder="名称"></el-input>
          </el-form-item>
          <el-form-item label="金额:">
            <div class="couponAmount">
              <el-input v-model="formData.couponAmount" placeholder="金额"></el-input>
              <p>元</p>
            </div>
          </el-form-item>
          <el-form-item label="开始编号:">
            <el-input v-model="year" style="width:30%" placeholder="年份如：23"></el-input> - 
            <el-input v-model="month" style="width:30%" placeholder="月份如：01"></el-input> -
            <el-input v-model="cashNum" style="width:30%"  placeholder="编号如：001"></el-input>
          </el-form-item>
          <el-form-item label="个数:">
            <el-input v-model="count" placeholder="用户可以使用的 8 位数优惠编号"></el-input>
          </el-form-item>
          <el-form-item label="状态:">
            <el-radio-group v-model="formData.writeOff">
              <el-radio :label="0">可用</el-radio>
              <el-radio :label="1">已用</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close()">取 消</el-button>
        <el-button type="primary" @click="getAddCashCouponInfo()">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>
<script>
export default {
  props: ['isShow', 'addCashCouponInfoBatch', 'navDataList', 'cashCouponDataInfo', 'title', 'editCashCouponData'],
  data() {
    return {
      formData: {
        couponAmount: '',
        couponClass: '',
        couponName: '',
        couponNumber: '',
        writeOff: 0
      },
      year: '',
      month: '',
      cashNum: '',
      count: 1,
      isShow: this.isShow
    }
  },
  watch: {
    cashCouponDataInfo() {
      this.formData = this.cashCouponDataInfo
    },
    title() {
      if (this.title == '新增') {
        this.formData.couponAmount = ''
        this.formData.couponClass = ''
        this.formData.couponName = ''
        this.formData.couponNumber = ''
      }
    }
  },
  methods: {
    close() {
      this.$emit('closeDlg',false)
    },
    //计算数组
    generateNum() {
      let beginNum = parseFloat(this.cashNum),
        count = this.count
      let arr = []
      for (let i = 0; i < count; i++) {
        console.log(this.year)
        console.log((beginNum + '').length)
        arr.push(
           (this.year + '' + this.month ) + (
           (beginNum+'').length == 3 ? beginNum : 
           (beginNum+'').length == 2 ? '0' + beginNum :
           (beginNum+'').length == 1 ? '00' + beginNum : beginNum+''
           )
        )
        ++beginNum
      }
      console.log('arrrr::', arr)

      return arr
    },
    // 提交表单
    async getAddCashCouponInfo() {
      const arr = this.generateNum()
      let newArr = []
      for (let val of arr) {
        newArr.push({
          couponClass: this.formData.couponClass,
          couponName: this.formData.couponName,
          couponNumber: val,
          couponAmount: this.formData.couponAmount,
          writeOff: this.formData.writeOff
        })
      }
      // let data = {
      //   ...this.formData,
      //   couponNumber:arr,
      // }
      this.addCashCouponInfoBatch(newArr)
      this.isShow = false
      this.formData.couponAmount = ''
      this.formData.couponClass = ''
      this.formData.couponName = ''
      this.formData.couponNumber = ''
    }
  }
}
</script>
<style lang="less" scoped>
.el-select {
  width: 100%;
}
.couponAmount {
  display: flex;
  p {
    padding: 0;
    margin: 0;
    padding-left: 12px;
  }
}
</style>
