<template>
  <el-container>
    <el-header class="headerBox">
      <div class="title">智慧社区 ｜ 运营服务中心系统</div>
      <div class="rightBox">
        <div class="userBox">
          <div class="ImgBox">
            <img src="../assets/logo.png" alt="" />
          </div>
          <div>超级管理员</div>
        </div>
        <div class="logout" @click="logout"><i class="el-icon-delete"></i>退出</div>
      </div>
    </el-header>
    <el-container>
      <el-aside width="200px">
        <el-menu
          default-active="2"
          router
          class="menuBox"
          @open="handleOpen"
          @close="handleClose"
          background-color="#e4e8e8"
          text-color="#202626"
          active-text-color="#09BEBE"
        >
          <el-menu-item index="1" route="index">
            <i class="el-icon-menu"></i>
            <span slot="title">首页</span>
          </el-menu-item>
          <el-submenu index="2">
            <template slot="title">
              <i class="el-icon-location"></i>
              <span>物业管理</span>
            </template>
            <el-menu-item index="2-1" route="audit">待审核</el-menu-item>
            <el-menu-item index="2-2" route="pfileList">档案管理</el-menu-item>
            <!-- <el-menu-item index="2-3" route="accessFailure">接入失败</el-menu-item>
            <el-menu-item index="2-4" route="rescindList">解约记录</el-menu-item> -->
          </el-submenu>
          <el-submenu index="3">
            <template slot="title">
              <i class="el-icon-location"></i>
              <span>居民管理</span>
            </template>
            <el-menu-item index="3-1" route="addArchives">接入进度</el-menu-item>
            <el-menu-item index="3-2" route="fileList">档案列表</el-menu-item>
            <!-- <el-menu-item index="3-3" route="fail">接入失败</el-menu-item>
            <el-menu-item index="3-4" route="FRescind">解约记录</el-menu-item> -->
          </el-submenu>
          <el-submenu index="21">
            <template slot="title">
              <i class="el-icon-location"></i>
              <span>合作管理</span>
            </template>
            <el-menu-item index="21-1" route="cooInstall">待安装</el-menu-item>
          </el-submenu>
          <el-submenu index="4">
            <template slot="title">
              <i class="el-icon-location"></i>
              <span>预警管理</span>
            </template>
            <el-menu-item index="4-1" route="seat">坐席预警</el-menu-item>
            <el-menu-item index="4-2" route="call">来电预警</el-menu-item>
            <el-menu-item index="4-3" route="equipment">设备预警</el-menu-item>
          </el-submenu>
          <el-submenu index="5">
            <template slot="title">
              <i class="el-icon-location"></i>
              <span>设备管理</span>
            </template>
            <!-- <el-menu-item index="5-1" route="openDevice">设备开通</el-menu-item> -->
            <el-menu-item index="5-1" route="device">设备管理</el-menu-item>
            <el-menu-item index="5-2" route="deviceCompany">设备公司</el-menu-item>
            <el-menu-item index="5-3" route="deviceGoods">设备列表</el-menu-item>
          </el-submenu>
          <el-submenu index="6">
            <template slot="title">
              <i class="el-icon-location"></i>
              <span>保险管理</span>
            </template>
            <el-menu-item index="6-1" route="userStayInsurance">待购保单</el-menu-item>
            <el-menu-item index="6-2" route="userAlreadyInsurance">已购保单</el-menu-item>
            <el-menu-item index="6-3" route="insuranceCompany">保险公司</el-menu-item>
            <el-menu-item index="6-4" route="insuranceGoods">保险套餐</el-menu-item>
          </el-submenu>
          <el-submenu index="18">
            <template slot="title">
              <i class="el-icon-location"></i>
              <span>服务商管理</span>
            </template>
            <el-menu-item index="18-1" route="spBusInfo">服务商信息</el-menu-item>
            <el-menu-item index="18-2" route="spServiceGoods">服务商品</el-menu-item>
            <el-menu-item index="18-3" route="spUserOrders">服务订单</el-menu-item>
            <el-menu-item index="18-4" route="spSet">服务商配置</el-menu-item>
          </el-submenu>
          <el-submenu index="7">
            <template slot="title">
              <i class="el-icon-location"></i>
              <span>账户管理</span>
            </template>
            <el-menu-item index="7-1" route="operation">运营账号</el-menu-item>
          </el-submenu>
          <el-submenu index="8">
            <template slot="title">
              <i class="el-icon-location"></i>
              <span>财务管理</span>
            </template>
            <el-menu-item index="8-1" route="finance">服务收入报表</el-menu-item>
            <el-menu-item index="8-2" route="userDepositPayList">押金收入报表</el-menu-item>
            <el-menu-item index="8-3" route="userOrderList">用户服务订单</el-menu-item>
            <el-menu-item index="8-4" route="userDepositList">用户押金订单</el-menu-item>
            <el-menu-item index="8-5" route="spBusFinance">服务商周账单</el-menu-item>
          </el-submenu>
          <el-submenu index="15">
            <template slot="title">
              <i class="el-icon-location"></i>
              <span>活动管理</span>
            </template>
            <el-menu-item index="15-1" route="cashCoupon">代金券</el-menu-item>
          </el-submenu>
          <el-submenu index="16">
            <template slot="title">
              <i class="el-icon-location"></i>
              <span>押金管理</span>
            </template>
            <el-menu-item index="16-1" route="depositList">押金列表</el-menu-item>
          </el-submenu>
          <el-menu-item index="9" route="notice">
            <i class="el-icon-menu"></i>
            <span slot="title">通知公告</span>
          </el-menu-item>
          <el-menu-item index="10" route="knowledge">
            <i class="el-icon-menu"></i>
            <span slot="title">知识库</span>
          </el-menu-item>
          <el-submenu index="11">
            <template slot="title">
              <i class="el-icon-location"></i>
              <span>话务记录</span>
            </template>
            <el-menu-item index="11-1" route="incoming">呼入记录</el-menu-item>
            <el-menu-item index="11-2" route="exhale">呼出记录</el-menu-item>
          </el-submenu>
          <el-submenu index="12">
            <template slot="title">
              <i class="el-icon-location"></i>
              <span>菏康好物</span>
            </template>
            <el-menu-item index="12-1" route="HkUnionGoods">菏康联盟</el-menu-item>
          </el-submenu>
          <el-submenu index="20">
            <template slot="title">
              <i class="el-icon-location"></i>
              <span>菏康饮食</span>
            </template>
            <el-menu-item index="20-1" route="HkFoods">饮食管理</el-menu-item>
            <el-menu-item index="20-2" route="HkFoodsTags">标签管理</el-menu-item>
          </el-submenu>
          <el-submenu index="13">
            <template slot="title">
              <i class="el-icon-location"></i>
              <span>服务管理</span>
            </template>
            <el-menu-item index="13-1" route="serviceGoods">用户服务</el-menu-item>
          </el-submenu>
          <el-submenu index="19">
            <template slot="title">
              <i class="el-icon-location"></i>
              <span>会员管理</span>
            </template>
            <el-menu-item index="19-1" route="memberCard">会员卡管理</el-menu-item>
          </el-submenu>
          <el-submenu index="14">
            <template slot="title">
              <i class="el-icon-location"></i>
              <span>APP运营管理</span>
            </template>
            <el-menu-item index="14-1" route="appOperateImg">引导页管理</el-menu-item>
            <el-menu-item index="14-2" route="appOperateConfig">运营配置</el-menu-item>
          </el-submenu>
          <el-submenu index="17">
            <template slot="title">
              <i class="el-icon-location"></i>
              <span>菏康集团</span>
            </template>
            <el-menu-item index="17-1" route="branchCompany">公司列表</el-menu-item>
          </el-submenu>
        </el-menu>
      </el-aside>

      <el-main class="mainBox">
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>
<script>
export default {
  data() {
    return {}
  },
  methods: {
    logout() {
      sessionStorage.removeItem('session')
      this.$router.push({ path: '/' })
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath)
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath)
    }
  }
}
</script>
<style lang="less" scoped>
.NavBox {
  position: fixed;
}
.logout {
  cursor: pointer;
}
.headerBox {
  background: #09bebe;
  display: flex;
  justify-content: space-between;
  position: fixed;
  width: 100vw;
  z-index: 999;
  .title {
    font-size: 20px;
    font-weight: 500;
    text-align: left;
    color: #ffffff;
    line-height: 60px;
  }
  .rightBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #ffffff;
    .userBox {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-right: 40px;
      .ImgBox {
        width: 32px;
        height: 32px;
      }
    }
  }
}
.menuBox {
  height: calc(100vh - 60px);
  position: fixed;
  width: 200px;
  z-index: 999;
  margin-top: 60px;
  overflow: auto;
}
.mainBox {
  // margin-top: 60px;
  margin: 60px 0 16px 0;
}
</style>
