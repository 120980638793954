import request from '@/utils/request'

// 登录方法
export function login(data) {
    return request({
        url: '/sys/account/login',
        method: 'post',
        data: data
    })
}
// 解析token
export function tokenParse(data) {
    return request({
        url: '/sys/account/profile',
        method: 'post',
        data: data
    })
}