<template>
  <el-container>
    <el-header class="header">设备管理</el-header>
    <el-main>
      <!-- 数据统计 -->
      <div class="listData">
        <el-row :gutter="8">
          <el-col :span="4">
            <div class="grid-content bg-purple" style="background: #e9fbf2">
              <div class="grid_inner">
                <div class="data_number">{{ phoneDeviceTotal }}</div>
                <div>总接入设备(台)</div>
              </div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple" style="background: #e9fbfb">
              <div class="grid_inner">
                <div class="data_number">{{ phoneTotal }}</div>
                <div>智能电话(台)</div>
              </div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple" style="background: #fbe9e9">
              <div class="grid_inner">
                <div class="data_number">{{ deviceTotal }}</div>
                <div>智能设备(台)</div>
              </div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple" style="background: #e9fbf2">
              <div class="grid_inner">
                <div class="data_number">{{ onDeviceTotal }}</div>
                <div>设备在线(台)</div>
              </div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple" style="background: #fbe9e9">
              <div class="grid_inner">
                <div class="data_number">{{ offDeviceTotal }}</div>
                <div>设备离线(台)</div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- 精准查询 -->
      <div class="searchBox">
        <div class="searchTit">精准查询</div>
        <div class="key_group">
          <div class="key_group_item">
            <div class="key_tit">物业公司</div>
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </div>
          <div class="key_group_item">
            <div class="key_tit">所属地区</div>
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </div>
          <div class="key_group_item">
            <div class="key_tit">小区</div>
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </div>
          <div class="key_group_item">
            <div class="key_tit">房号</div>
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </div>
          <el-button type="primary">查询</el-button>
        </div>
      </div>
      <el-tabs style="padding: 20px" v-model="activeName">
        <el-tab-pane label="智能电话" name="first">
          <!-- 列表 -->
          <div class="list">
            <el-table :data="tableData.rows" style="width: 100%">
              <el-table-column prop="id" label="ID" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="propertyName" label="所属物业" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="propertyArea" label="所属地区" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="villageName" label="所属小区" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="propertyAddress" label="小区地址" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="roomName" label="房号" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <p>
                    {{ scope.row.floorName }}号楼 {{ scope.row.unitName }}单元 {{ scope.row.layerName }}层
                    {{ scope.row.roomName }}室
                  </p>
                </template>
              </el-table-column>
              <el-table-column prop="ownerName" label="业主姓名" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="ownerPhone" label="联系电话" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="deviceBrand" label="智能电话" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="deviceNumber" label="设备编号" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column fixed="right" label="操作" width="120">
                <template slot-scope="scope">
                  <el-button @click.native.prevent="callbackRow(scope.$index, tableData)" type="text" size="small">
                    电话
                  </el-button>
                  <el-button @click.native.prevent="seeRow(scope.$index, tableData)" type="text" size="small">
                    回收
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <div class="pageBox">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :page-sizes="[5, 10, 20]"
                layout="total, sizes, prev, pager, next, jumper"
                :total="tableData.total"
              >
              </el-pagination>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="智能设备" name="second">
          <!-- 列表 -->
          <div class="list">
            <el-table :data="tableData2.rows" style="width: 100%">
              <el-table-column prop="id" label="ID" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="propertyName" label="所属物业" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="propertyArea" label="所属地区" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="villageName" label="所属小区" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="propertyAddress" label="小区地址" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="roomName" label="房号" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <p>
                    {{ scope.row.floorName }}号楼 {{ scope.row.unitName }}单元 {{ scope.row.layerName }}层
                    {{ scope.row.roomName }}室
                  </p>
                </template>
              </el-table-column>
              <el-table-column prop="ownerName" label="业主姓名" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="deviceName" label="智能设备" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="eventType" label="设备状态" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <p v-if="scope.row.eventType == 0 || scope.row.eventType == null" :style="{ color: redColor }">
                    离线
                  </p>
                  <p v-if="scope.row.eventType == 1" :style="{ color: greenColor }">在线</p>
                </template>
              </el-table-column>
              <el-table-column prop="IMEI" label="设备编号" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column fixed="right" label="操作" width="120">
                <template slot-scope="scope">
                  <el-button @click.native.prevent="callbackRow(scope.$index, tableData)" type="text" size="small">
                    电话
                  </el-button>
                  <el-button @click.native.prevent="seeRow(scope.$index, tableData)" type="text" size="small">
                    回收
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <div class="pageBox">
              <el-pagination
                @size-change="handleSizeChange2"
                @current-change="handleCurrentChange2"
                :page-sizes="[5, 10, 20]"
                layout="total, sizes, prev, pager, next, jumper"
                :total="tableData2.total"
              >
              </el-pagination>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>

      <!-- 隐私电话弹框 -->
      <privacyCall :privacyCall="privacyCallShow" @closePrivacyCall="showPrivacyCall"></privacyCall>
    </el-main>
  </el-container>
</template>
<script>
import PrivacyCall from '@/components/public/privacyCall'
import { queryPhoneList, queryDeviceList } from '@/api/device.js'

export default {
  props: ['PrivacyCall'],
  components: {
    privacyCall: PrivacyCall
  },
  data() {
    return {
      privacyCallShow: false,
      activeName: 'first',
      query: {
        page: 1,
        pageSize: 10
      },
      query2: {
        page: 1,
        pageSize: 10
      },
      options: [
        {
          value: '选项1',
          label: '黄金糕'
        },
        {
          value: '选项2',
          label: '双皮奶'
        },
        {
          value: '选项3',
          label: '蚵仔煎'
        },
        {
          value: '选项4',
          label: '龙须面'
        },
        {
          value: '选项5',
          label: '北京烤鸭'
        }
      ],
      value: '',
      tableData: [],
      tableData2: [],
      phoneTotal: 0,
      deviceTotal: 0,
      phoneDeviceTotal: 0,
      onDeviceTotal: 0,
      offDeviceTotal: 0,
      redColor: 'red',
      greenColor: 'green'
    }
  },
  created() {
    this.init()
    this.init2()
  },
  methods: {
    //初始化表格
    async init() {
      let result = await queryPhoneList(this.query)
      if (result.success) {
        this.tableData = result.data
        this.phoneTotal = result.data.total
      }
    },
    async init2() {
      let result = await queryDeviceList(this.query2)
      if (result.success) {
        let onDevice = result.data.rows.filter((i) => i.eventType == 1)
        this.onDeviceTotal = onDevice.length
        let offDevice = result.data.rows.filter((i) => i.eventType == 0)
        this.offDeviceTotal = offDevice.length
        this.tableData2 = result.data
        this.deviceTotal = result.data.total
      }
      this.init3()
    },
    // 计算总量
    async init3() {
      this.phoneDeviceTotal = parseInt(this.phoneTotal) + parseInt(this.deviceTotal)
    },
    //分页-size
    handleSizeChange(val) {
      this.query.pageSize = val
      this.init()
    },
    //分页-page
    handleCurrentChange(val) {
      this.query.page = val
      this.init()
    },
    //分页-size
    handleSizeChange2(val) {
      this.query2.pageSize = val
      this.init2()
    },
    //分页-page
    handleCurrentChange2(val) {
      this.query2.page = val
      this.init2()
    },
    callbackRow(index) {
      console.log(index)
      this.privacyCallShow = true
    },
    showPrivacyCall() {
      this.privacyCallShow = false
    },
    seeRow(index) {
      this.$confirm('确认所有设备已经回收', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$message({
            type: 'success',
            message: '已确认!'
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
    }
  }
}
</script>
<style lang="less" scoped>
.header {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  line-height: 60px;
}
.listData {
  padding: 16px;
  .grid-content {
    height: 95px;
    display: flex;
    align-items: center;
    justify-content: center;
    .grid_inner {
      text-align: center;
      .data_number {
        font-size: 32px;
        font-weight: 500;
        padding-bottom: 8px;
      }
    }
  }
}
.searchBox {
  margin: 0 16px;
  padding: 16px;
  background: #ffffff;
  border: 1px solid #c7cfcf;
  .searchTit {
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 16px;
  }
  .key_group {
    display: flex;
    .key_group_item {
      display: flex;
      align-items: center;
      margin-right: 24px;
      .key_tit {
        margin-right: 16px;
      }
    }
  }
}
.list {
  padding: 20px;
  .pageBox {
    height: 60px;
    display: flex;
    justify-content: end;
    align-items: center;
  }
}
</style>
