import request from '@/utils/request'

// 获取全部知识库分类信息
export function getKnowledgeData() {
    return request({
        url: '/sys/knowClass',
        method: 'get',
    })
}
//树形查询全部分类
export function getKnowClassThree() {
    return request({
        url: '/sys/knowClass/treeFindAll',
        method: 'get',
    })
}
// 新增知识分类
export function addKnowClass(data) {
    return request({
        url: '/sys/knowClass',
        method: 'post',
        data: data
    })
}
// 编辑知识分类
export function putEditKnowClass(data, id) {
    return request({
        url: '/sys/knowClass/' + id,
        method: 'put',
        data: data
    })
}
// 删除知识分类
export function deleteKnowClass(id) {
    return request({
        url: '/sys/knowClass/' + id,
        method: 'delete',
    })
}
// 根据知识分类获取知识列表
export function getClassToKnowledgeData(id, data) {
    return request({
        url: '/sys/know/list/' + id,
        method: 'get',
        params: data
    })
}
// 新增知识
export function addKnow(data) {
    return request({
        url: '/sys/know',
        method: 'post',
        data: data
    })
}
// 编辑知识
export function editKnow(data, id) {
    return request({
        url: '/sys/know/' + id,
        method: 'put',
        data: data
    })
}
// 删除知识
export function deleteKnow(id) {
    return request({
        url: '/sys/know/' + id,
        method: 'delete'
    })
}


// 获取全部通知分类信息
export function getNoticeClassData() {
    return request({
        url: '/sys/noticeClass',
        method: 'get',
    })
}
// 新增通知分类
export function addNoticeClass(data) {
    return request({
        url: '/sys/noticeClass',
        method: 'post',
        data: data
    })
}
// 编辑通知分类
export function putNoticeClass(data, id) {
    return request({
        url: '/sys/noticeClass/' + id,
        method: 'put',
        data: data
    })
}
// 删除通知分类
export function deleteNoticeClass(id) {
    return request({
        url: '/sys/noticeClass/' + id,
        method: 'delete',
    })
}
// 根据通知分类获取知识列表
export function getClassToNoticeData(id, data) {
    return request({
        url: '/sys/notice/list/' + id,
        method: 'get',
        params: data
    })
}
// 新增通知
export function addNotice(data) {
    return request({
        url: '/sys/notice',
        method: 'post',
        data: data
    })
}
// 编辑通知
export function editNotice(data, id) {
    return request({
        url: '/sys/notice/' + id,
        method: 'put',
        data: data
    })
}
// 删除通知
export function deleteNotice(id) {
    return request({
        url: '/sys/notice/' + id,
        method: 'delete'
    })
}

// 省市区二级联动 - 查询全部树
export function proCityFindAll() {
    return request({
        url: '/sys/province/proCityFindAll',
        method: 'get',
    })
}
// 省市区三级联动 - 查询全部树
export function proCityAreaFindAll() {
    return request({
        url: '/sys/province/proCityAreaFindAll',
        method: 'get',
    })
} 