<template>
  <el-container>
    <el-dialog
      :title="title"
      :visible.sync="addFoodsClass"
      width="20%"
      @close="close"
      append-to-body
      :close-on-click-modal="false"
    >
      <div class="content">
        <div class="input_item" :model="formData" ref="formData">
          <div class="item_vlaue">标题</div>
          <div class="item_key">
            <el-input v-model="formData.title" placeholder="请输入内容"></el-input>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addFoodsClass = false">取 消</el-button>
        <el-button type="primary" @click="getaddFoodsClassInfo()">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>
<script>
export default {
  props: ['addFoodsClass', 'addFoodsClassInfo', 'title', 'foodsClassDataInfo', 'editFoodsClass'],
  data() {
    return {
      formData: {
        id: '',
        title: ''
      },
      addFoodsClassShow: this.addFoodsClass
    }
  },
  watch: {
    foodsClassDataInfo() {
      this.formData.title = this.foodsClassDataInfo.title
      this.formData.id = this.foodsClassDataInfo.id
    }
  },
  methods: {
    close() {
      this.$emit('closeAddFoodsClass')
      this.formData.title = ''
      this.formData.id = ''
    },
    async getaddFoodsClassInfo() {
      if (this.title == '新增分类') {
        this.addFoodsClassInfo(this.formData)
        this.addFoodsClass = false
        this.formData.title = ''
        this.formData.id = ''
      } else if (this.title == '编辑分类') {
        this.editFoodsClass(this.formData)
        this.addFoodsClass = false
        this.formData.title = ''
        this.formData.id = ''
      }
    }
  }
}
</script>
<style lang="less" scoped>
.input_item {
  padding: 8px 0;
  .item_vlaue {
    padding-bottom: 8px;
  }
  .select {
    width: 100%;
  }
}
</style>
