import request from '@/utils/request'

// 押金管理 - 押金列表
export function queryDepositAll (params) {
  console.log(params)
  return request({
    url: '/sys/deposit/list',
    method: 'GET',
    params: params
  })
}
// 押金管理 - 新增
export function addDeposit (data) {
  console.log(data)
  return request({
    url: '/sys/deposit',
    method: 'POST',
    data: data
  })
}
// 押金管理 - 编辑
export function editDeposit (id,data) {
  console.log(data)
  return request({
    url: '/sys/deposit/' + id,
    method: 'PUT',
    data: data
  })
}
// 押金管理 - 删除
export function deleteDeposit (id) {
  return request({
    url: '/sys/deposit/'+id,
    method: 'DELETE'
  })
}