<template>
  <el-container>
    <el-dialog
      :title="title"
      :visible.sync="addKnowClass"
      width="20%"
      @close="close"
      append-to-body
      :close-on-click-modal="false"
    >
      <div class="content">
        <div class="input_item" :model="formData" ref="formData">
          <div class="item_vlaue">标题</div>
          <div class="item_key">
            <el-input v-model="formData.className" placeholder="请输入内容"></el-input>
          </div>
        </div>
        <div class="input_item" :model="formData" ref="formData">
          <div class="item_vlaue">一级分类名称</div>
          <div class="item_key">
            <el-select v-model="formData.oneLevelId" placeholder="请选择">
              <el-option v-for="item in oneLevelList" :key="item.id" :label="item.title" :value="item.id"> </el-option>
            </el-select>
          </div>
        </div>
        <div class="input_item" :model="formData" ref="formData">
          <div class="item_vlaue">二级分类名称</div>
          <div class="item_key">
            <el-select v-model="formData.twoLevelId" placeholder="请选择">
              <el-option v-for="item in twoLevelList" :key="item.id" :label="item.title" :value="item.id"> </el-option>
            </el-select>
          </div>
        </div>
        <div class="input_item" :model="formData" ref="formData">
          <div class="item_vlaue">浏览量</div>
          <div class="item_key">
            <el-input v-model="formData.pageView" placeholder="请输入浏览量"></el-input>
          </div>
        </div>
        <div class="input_item" :model="formData" ref="formData">
          <div class="item_vlaue">显示终端</div>
          <div class="item_key">
            <el-radio v-model="formData.displayTerminal" label="0">用户</el-radio>
            <el-radio v-model="formData.displayTerminal" label="1">物业</el-radio>
            <el-radio v-model="formData.displayTerminal" label="2">运营</el-radio>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addKnowClass = false">取 消</el-button>
        <el-button type="primary" @click="getAddKnowClassInfo()">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>
<script>
export default {
  props: ['addKnowClass', 'addKnowClassInfo', 'title', 'knowClassDataInfo', 'editKnowClass', 'navDataList'],
  data() {
    return {
      formData: {
        id: '',
        className: '',
        oneLevelId: '',
        twoLevelId: '',
        pageView: '',
        displayTerminal: ''
      },
      addKnowClassShow: this.addKnowClass,
      classKnowList: [],
      oneLevelList: [],
      twoLevelList: []
    }
  },
  watch: {
    navDataList() {
      var oneList = []
      var twoList = []
      this.navDataList.forEach((element) => {
        var oneJson = {
          id: element.id,
          title: element.title
        }
        oneList.push(oneJson)
        element.children.forEach((a) => {
          var twoJson = {
            id: a.id,
            title: a.title
          }
          twoList.push(twoJson)
        })
      })
      this.oneLevelList = oneList
      this.twoLevelList = twoList
    },
    knowClassDataInfo() {
      this.formData.className = this.knowClassDataInfo.className
      this.formData.displayTerminal = this.knowClassDataInfo.displayTerminal
      this.formData.id = this.knowClassDataInfo.id
    }
  },
  methods: {
    close() {
      this.$emit('closeAddKnowClass')
      this.formData.className = ''
      this.formData.displayTerminal = ''
    },
    async getAddKnowClassInfo() {
      if (this.title == '新增分类') {
        this.addKnowClassInfo(this.formData)
        this.addKnowClass = false
        this.formData.className = ''
        this.formData.displayTerminal = ''
      } else if (this.title == '编辑分类') {
        this.editKnowClass(this.formData)
        this.addKnowClass = false
      }
    }
  }
}
</script>
<style lang="less" scoped>
.input_item {
  padding: 8px 0;
  .item_vlaue {
    padding-bottom: 8px;
  }
  .select {
    width: 100%;
  }
}
</style>
