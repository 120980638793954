import request from '@/utils/request'

// 用户服务 - 保存服务分类
export function addUserServiceGoods(data) {
  return request({
    url: `/sys/serviceGoods`,
    method: 'post',
    data: data,
  })
}
// 用户服务 - 根据id查询服务
export function getUserServiceGoodsId(id) {
  return request({
    url: `/sys/serviceGoods/` + id,
    method: 'get',
  })
}
// 用户服务 - 根据id删除服务
export function deleteUserServiceGoodsId(id) {
  return request({
    url: `/sys/serviceGoods/` + id,
    method: 'DELETE',
  })
}
// 用户服务 - 根据id更新服务
export function putUserServiceGoodsId(data, id) {
  return request({
    url: `/sys/serviceGoods/` + id,
    method: 'put',
    data: data,
  })
}
// 用户服务 - 查询全部服务列表
export function getUserServiceGoodsAll() {
  return request({
    url: `/sys/serviceGoods/list`,
    method: 'get',
  })
}
// 用户服务 - 根据城市查询全部服务列表
export function getTocityServiceGoods(serviceCity) {
  return request({
    url: `/sys/serviceGoods/city/` + serviceCity,
    method: 'get',
  })
}
