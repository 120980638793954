<template>
  <el-container>
    <el-main>
      <el-row>
        <el-col :span="4" class="leftBox">
          <div class="left_titBox">
            <div class="l_t_txt">智能标签</div>
          </div>
          <div class="left_contentBox">
            <el-tree :data="navDataList" :props="treesProps" @node-click="handleNodeClick">
              <!-- <span class="custom-tree-node" slot-scope="{ node, data }">
                <span>{{ node.label }}</span>
                <span>
                  <el-button type="text" size="mini" @click="() => openEditFoodsClass(data)"> 编辑 </el-button>
                  <el-button type="text" size="mini" @click="() => openDeleteFoodsClass(data)"> 删除 </el-button>
                </span>
              </span> -->
            </el-tree>
          </div>
        </el-col>
        <!-- 列表 -->
        <div class="rightBox">
          <div class="rightBtn">
            <div class="leftTit">标签列表</div>
            <el-button type="primary" @click="OpenAddTagsClass">新增标签</el-button>
          </div>
          <el-col class="list" :span="20">
            <el-table :data="tableData" style="width: 100%">
              <el-table-column prop="id" label="ID" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column label="分类名称" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <p>
                    {{ scope.row.type == 0 ? '标签' : '功效' }}
                  </p>
                </template>
              </el-table-column>
              <el-table-column prop="title" label="标题" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="createTime" label="发布时间" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <p>
                    {{ formatDate(scope.row.createTime) }}
                  </p>
                </template>
              </el-table-column>
              <el-table-column fixed="right" label="操作" width="120">
                <template slot-scope="scope">
                  <el-button @click.native.prevent="openEditFoodsClass(scope.row)" type="text" size="small">
                    编辑
                  </el-button>
                  <el-button @click.native.prevent="openDeleteFoodsClass(scope.row)" type="text" size="small">
                    删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </div>
      </el-row>
      <!-- 添加分类 -->
      <addTagsClass
        :title="title"
        :addTagsClass="addTagsClassShow"
        @closeAddTagsClass="showAddTagsClass"
        :addTagsClassInfo="addTagsClassInfo"
        :tagsClassDataInfo="tagsClassDataInfo"
        :editTagsClass="editTagsClass"
      ></addTagsClass>
    </el-main>
  </el-container>
</template>
<script>
import { addFoodsTags, editFoodsTags, deleteFoodsTags, getTagsFindAll } from '@/api/hkFoods.js'
import AddTagsClass from '@/components/hkFoods/addTags'
export default {
  components: {
    AddTagsClass: AddTagsClass
  },
  data() {
    return {
      navList: [],
      addTagsClassShow: false,
      navDataList: [
        {
          title: '标签',
          type: 0
        },
        {
          title: '功效',
          type: 1
        }
      ],
      treesProps: {
        label: 'title',
        children: 'children'
      },
      tableData: [],
      query: {
        page: 1,
        pageSize: 10
      },
      title: '',
      tagsClassDataInfo: '' //编辑分类
    }
  },
  mounted() {
    this.getFoodsClassList()
  },
  methods: {
    //获取分类
    async getFoodsClassList() {
      let result = await getTagsFindAll()
      if (result.success) {
        this.navList = result.data
        var data = []
        result.data.forEach((element) => {
          if (element.type == 0) {
            data.push(element)
          }
        })
        this.tableData = data
      }
    },
    // 获取分类下知识
    async handleNodeClick(data) {
      var data1 = []
      this.navList.forEach((element) => {
        if (element.type == data.type) {
          data1.push(element)
        }
      })
      this.tableData = data1
    },
    // 新增分类
    OpenAddTagsClass() {
      this.addTagsClassShow = true
      this.title = '新增分类'
    },
    showAddTagsClass() {
      this.addTagsClassShow = false
    },
    // 新增分类接口
    async addTagsClassInfo(data) {
      let res = await addFoodsTags(data)
      if (res.success) {
        this.$message({
          type: 'success',
          message: '新增分类成功!'
        })
        this.getFoodsClassList()
        this.addTagsClassShow = false
      } else {
        this.$message({
          type: 'error',
          message: '新增分类失败!'
        })
      }
      this.getFoodsClassList()
    },
    // 编辑分类
    async openEditFoodsClass(row) {
      console.log(row)
      this.addTagsClassShow = true
      this.tagsClassDataInfo = row
      this.title = '编辑分类'
    },
    // 编辑分类接口
    async editTagsClass(data) {
      let res = await editFoodsTags(data, data.id)
      if (res.success) {
        this.$message({
          type: 'success',
          message: '编辑知识成功!'
        })
        this.getFoodsClassList()
        this.addTagsClassShow = false
      } else {
        this.$message({
          type: 'error',
          message: '编辑知识失败!'
        })
      }
    },
    // 删除分类
    async openDeleteFoodsClass(row) {
      this.$confirm('此操作将永久删除该分类, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      })
        .then(async () => {
          let res = await deleteFoodsTags(row.id)
          if (res.success) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.getFoodsClassList()
          } else {
            this.$message({
              type: 'error',
              message: '删除失败'
            })
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    }
  }
}
</script>
<style lang="less" scoped>
.listData {
  padding: 16px;
  .grid-content {
    height: 95px;
    display: flex;
    align-items: center;
    justify-content: center;
    .grid_inner {
      text-align: center;
      .data_number {
        font-size: 32px;
        font-weight: 500;
        padding-bottom: 8px;
      }
    }
  }
}
.searchBox {
  margin: 0 16px;
  padding: 16px;
  background: #ffffff;
  border: 1px solid #c7cfcf;
  .searchTit {
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 16px;
  }
  .key_group {
    display: flex;
    .key_group_item {
      display: flex;
      align-items: center;
      margin-right: 24px;
      .key_tit {
        margin-right: 16px;
      }
    }
  }
}
.active {
  color: #09bebe;
}
.rightBtn {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 500;
}
.leftBox {
  border-right: #c7cfcf 0.5px solid;
  height: calc(100vh - 76px);
  .left_titBox {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .l_t_txt {
      font-size: 16px;
      font-weight: 500;
    }
    .l_t_btn {
      font-size: 14px;
      color: #09bebe;
      cursor: pointer;
    }
  }
  .left_contentBox {
    .l_c_items {
      padding: 16px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .l_c_i_dele {
        color: #be0909;
        display: none;
      }
      .l_c_i_edit {
        color: #09bebe;
        display: none;
        margin-right: 16px;
      }
      .l_c_i_btn {
        display: flex;
        justify-content: space-between;
      }
      .l_c_i_txt {
        display: flex;
        p {
          padding: 0;
          margin: 0;
        }
      }
    }
    .l_c_items:hover {
      background: #f2f3f4;
      .l_c_i_dele {
        display: block;
      }
      .l_c_i_edit {
        display: block;
      }
    }
  }
}
.list {
  padding: 16px;
  .pageBox {
    height: 60px;
    display: flex;
    justify-content: end;
    align-items: center;
  }
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
</style>
