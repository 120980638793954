import request from '@/utils/request'

// 物业管理

// 物业待审核列表
export function queryPropertyRescindList(data) {
  return request({
    url: '/sys/propertyReview',
    method: 'get',
    params: data
  })
}
// 根据propertyArea查询物业
export function getToAreaPropertyList(data) {
  return request({
    url: '/sys/property/findByPropertyArea/' + data,
    method: 'get',
  })
}
// 物业待审核管理 添加物业合同
export function queryAddContract(data) {
  return request({
    url: `/sys/contract`,
    method: 'POST',
    data: data
  })
}
// 物业审核接口
export function queryPropertyRescind(id, data) {
  return request({
    url: '/sys/propertyReview/' + id,
    method: 'put',
    data: data
  })
}
// 根据物业负责人手机号查询物业
export function queryPropertyContactPhone(contactPhone) {
  return request({
    url: '/sys/property/contactPhone/' + contactPhone,
    method: 'get'
  })
}
// 查询合同
export function queryPage(data) {
  return request({
    url: '/sys/contract',
    method: 'get',
    params: data
  })
}
// 档案管理
export function queryProperty(data) {
  return request({
    url: '/sys/property/findAll',
    method: 'post',
    data: data
  })
}
// 档案详情 根据id查物业
export function queryPropertyByID(id) {
  return request({
    url: `/sys/property/${id}`,
    method: 'get',
  })
}
// 档案详情 合同信息
export function queryContractByID(id) {
  return request({
    url: `/sys/contract/${id}`,
    method: 'get',
  })
}
// 档案详情 房屋信息
export function queryHouseList(data) {
  return request({
    url: `/sys/property/houseList/${data.id}`,
    method: 'POST',
    data: data
  })
}
// 档案详情 查询所有房屋信息
export function queryRoomAllList() {
  return request({
    url: `/sys/property/roomList`,
    method: 'GET',
  })
}
// 档案详情 本月新增
export function queryPropertyThisMonthList(data) {
  return request({
    url: `/sys/property/thisMonth`,
    method: 'POST',
    data: data
  })
}
// 档案详情 本月新增房屋
export function queryRoomThisMonthList(data) {
  return request({
    url: `/sys/property/roomList/thisMonth`,
    method: 'POST',
    data: data
  })
}