<template>
  <el-container class="mainBox">
    <div class="title">欢迎使用智慧社区平台</div>
    <div>为保证更好的为您服务，请认真填写一下信息</div>
    <el-row :gutter="20" class="from">
      <el-col :span="12" class="from_item">
        <div class="grid-content bg-purple">
          <div class="from_item_vlaue">登陆账号</div>
          <div class="from_item_key">
            <el-input v-model="input" placeholder="请输入内容"></el-input>
          </div>
        </div>
      </el-col>
      <el-col :span="12" class="from_item">
        <div class="grid-content bg-purple">
          <div class="from_item_vlaue">登陆密码</div>
          <div class="from_item_key">
            <el-input v-model="input" placeholder="请输入内容"></el-input>
          </div>
        </div>
      </el-col>
      <el-col :span="12" class="from_item">
        <div class="grid-content bg-purple">
          <div class="from_item_vlaue">物业名称</div>
          <div class="from_item_key">
            <el-input v-model="input" placeholder="请输入内容"></el-input>
          </div>
        </div>
      </el-col>
      <el-col :span="12" class="from_item">
        <div class="grid-content bg-purple">
          <div class="from_item_vlaue">开发商</div>
          <div class="from_item_key">
            <el-input v-model="input" placeholder="请输入内容"></el-input>
          </div>
        </div>
      </el-col>
      <el-col :span="12" class="from_item">
        <div class="grid-content bg-purple">
          <div class="from_item_vlaue">地址</div>
          <div class="from_item_key">
            <el-cascader class="address" v-model="value" :options="options" :props="{ expandTrigger: 'hover' }" @change="handleChange"></el-cascader>
          </div>
        </div>
      </el-col>
      <el-col :span="12" class="from_item">
        <div class="grid-content bg-purple">
          <div class="from_item_vlaue">详细地址</div>
          <div class="from_item_key">
            <el-input v-model="input" placeholder="请输入内容"></el-input>
          </div>
        </div>
      </el-col>
      <el-col :span="12" class="from_item">
        <div class="grid-content bg-purple">
          <div class="from_item_vlaue">负责人姓名</div>
          <div class="from_item_key">
            <el-input v-model="input" placeholder="请输入内容"></el-input>
          </div>
        </div>
      </el-col>
      <el-col :span="12" class="from_item">
        <div class="grid-content bg-purple">
          <div class="from_item_vlaue">联系电话</div>
          <div class="from_item_key">
            <el-input v-model="input" placeholder="请输入内容"></el-input>
          </div>
        </div>
      </el-col>
      <el-col :span="12" class="from_item">
        <div class="grid-content bg-purple">
          <div class="from_item_vlaue">营业执照</div>
          <div class="from_item_key">
            <el-upload class="upload-demo" action="https://jsonplaceholder.typicode.com/posts/" :on-preview="handlePreview" :on-remove="handleRemove" :before-remove="beforeRemove" multiple :limit="3" :on-exceed="handleExceed" :file-list="fileList">
              <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>
          </div>
        </div>
      </el-col>
      <el-col :span="12" class="from_item">
        <div class="grid-content bg-purple">
          <div class="from_item_vlaue">信用代码</div>
          <div class="from_item_key">
            <el-input v-model="input" placeholder="请输入内容"></el-input>
          </div>
        </div>
      </el-col>
      <el-col :span="12" class="from_item">
        <div class="grid-content bg-purple">
          <div class="from_item_vlaue">法人姓名</div>
          <div class="from_item_key">
            <el-input v-model="input" placeholder="请输入内容"></el-input>
          </div>
        </div>
      </el-col>
      <el-col :span="12" class="from_item">
        <div class="grid-content bg-purple">
          <div class="from_item_vlaue">联系电话</div>
          <div class="from_item_key">
            <el-input v-model="input" placeholder="请输入内容"></el-input>
          </div>
        </div>
      </el-col>
      <el-col :span="12" class="from_item">
        <div class="grid-content bg-purple">
          <div class="from_item_vlaue">建设时间</div>
          <div class="from_item_key">
            <el-date-picker class="year" v-model="value3" type="year" placeholder="选择年">
            </el-date-picker>
          </div>
        </div>
      </el-col>
      <el-col :span="12" class="from_item">
        <div class="grid-content bg-purple">
          <div class="from_item_vlaue">占地面积</div>
          <div class="from_item_key">
            <el-input v-model="input" placeholder="请输入内容"></el-input>
          </div>
        </div>
      </el-col>
      <el-col :span="12" class="from_item">
        <div class="grid-content bg-purple">
          <div class="from_item_vlaue">总栋楼</div>
          <div class="from_item_key">
            <el-input v-model="input" placeholder="请输入内容"></el-input>
          </div>
        </div>
      </el-col>
      <el-col :span="12" class="from_item">
        <div class="grid-content bg-purple">
          <div class="from_item_vlaue">总户数</div>
          <div class="from_item_key">
            <el-input v-model="input" placeholder="请输入内容"></el-input>
          </div>
        </div>
      </el-col>
      <el-col :span="24" class="from_item">
        <div class="grid-content bg-purple">
          <div class="from_item_vlaue">行政用房</div>
          <div class="from_item_key checkbox">
            <el-checkbox-group v-model="checkList">
              <el-checkbox label="办公室"></el-checkbox>
              <el-checkbox label="门卫室"></el-checkbox>
              <el-checkbox label="监控室"></el-checkbox>
              <el-checkbox label="会议室"></el-checkbox>
              <el-checkbox label="培训室"></el-checkbox>
              <el-checkbox label="接待室"></el-checkbox>
              <el-checkbox label="库房"></el-checkbox>
              <el-checkbox label="其它"></el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
      </el-col>
      <el-col :span="24">
        <div class="from_item">
          <el-button type="primary" class="btn" @click="openRegister">注册</el-button>
        </div>
        <div class="from_item">
          <div class="switch">还没账号，<span @click="openLogin">去登录</span></div>
        </div>
      </el-col>
    </el-row>
  </el-container>
</template>
<script>
export default {
  methods: {
    openLogin () {
      this.$router.push({ path: '/login' })
    },
    openRegister () {
      this.$router.push({ path: '/index' })
    }
  }

}
</script>
<style lang="less" scoped>
.mainBox {
  margin: auto;
  width: 1000px;
  display: block;
  padding: 60px 0;
}
.title {
  font-size: 40px;
  font-weight: 500;
}
.from {
  padding-top: 40px;
  .from_item {
    padding: 12px 0;
    .from_item_vlaue {
      padding-bottom: 16px;
    }
    .from_item_key {
      display: flex;
      align-items: center;
    }
    .address {
      width: 100%;
      height: 40px;
    }
    .year {
      width: 100%;
    }
    .switch {
      display: flex;
      justify-content: end;
      span {
        color: #09bebe;
        font-weight: 500;
      }
    }

    .btn {
      width: 100%;
      margin-top: 24px;
    }
  }
}
</style>
