import request from '@/utils/request'

// 来电预警、设备预警


// 查询来电预警列表
export function queryCallWarning (data) {
  console.log(data)
  return request({
    url: '/sys/callWarning/list',
    method: 'POST',
    data: data
  })
}

// 来电数据统计查询
export function queryCallDataList (resolver, data) {
  console.log(data)
  return request({
    url: '/sys/callWarning/resolverList/' + resolver,
    method: 'POST',
    data: data
  })
}
// 来电本月新增
export function queryCallThisMonthEventList (data) {
  console.log(data)
  return request({
    url: '/sys/callWarning/callEventList/thisMonth',
    method: 'POST',
    data: data
  })
}
// 查询设备预警列表
export function queryDeviceWarning (data) {
  console.log(data)
  return request({
    url: '/sys/deviceWarning/list',
    method: 'POST',
    data: data
  })
}

// 设备数据统计查询
export function queryDeviceDataList (resolver, data) {
  console.log(data)
  return request({
    url: '/sys/deviceWarning/resolverList/' + resolver,
    method: 'POST',
    data: data
  })
}
// 设备报修次数
export function queryRepairDataList (repair, data) {
  console.log(data)
  return request({
    url: '/sys/deviceWarning/repairList/' + repair,
    method: 'POST',
    data: data
  })
}
// 设备未解决报警
export function queryIsNullEventList (data) {
  console.log(data)
  return request({
    url: '/sys/deviceWarning/isNullEventList',
    method: 'POST',
    data: data
  })
}
// 本月新增
export function queryThisMonthEventList (data) {
  console.log(data)
  return request({
    url: '/sys/deviceWarning/deviceEventList/thisMonth',
    method: 'POST',
    data: data
  })
}

// 物业坐席预警
// 所有已开通提醒小区
export function queryRemindList (remind,data) {
  console.log(data)
  return request({
    url: '/sys/property/findAllRemindList/' + remind,
    method: 'POST',
    data: data
  })
}
// 按照登录状态查询物业
export function queryRemindLoginStatusList (remind,data) {
  console.log(data)
  return request({
    url: '/sys/property/findAllRemindLoginStatusList/' + remind,
    method: 'POST',
    data: data
  })
}