<template>
  <el-container>
    <el-dialog
      :title="title"
      :visible.sync="addFoods"
      width="50%"
      @close="close"
      @opened="getEditor()"
      append-to-body
      :close-on-click-modal="false"
    >
      <div class="step">
        <el-form ref="formData" :model="formData" label-width="80px">
          <el-form-item label="标题:">
            <el-input v-model="formData.title"></el-input>
          </el-form-item>
          <el-form-item label="图片Url:" class="item_input">
            <el-input v-model="formData.imgUrl" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="所属分类:">
            <el-cascader
              ref="cascader"
              v-model="formData.itemId"
              :options="classFoodsList"
              :props="proCityAreaTreeProps"
              @change="handleChange"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="标签:">
            <el-select v-model="tags" multiple placeholder="请选择">
              <el-option v-for="item in tagsList" :key="item.label" :label="item.value" :value="item.label">
              </el-option>
            </el-select>
            <el-input v-model="formData.tags"></el-input>
          </el-form-item>
          <el-form-item label="功效:">
            <el-select v-model="effect" multiple placeholder="请选择">
              <el-option v-for="item in effectList" :key="item.label" :label="item.value" :value="item.label">
              </el-option>
            </el-select>
            <el-input v-model="formData.effect"></el-input>
          </el-form-item>
          <el-form-item label="浏览量:">
            <el-input v-model="formData.pageviews"></el-input>
          </el-form-item>
          <el-form-item label="食材:">
            <el-form :model="ingredientsList" ref="ingredientsList" label-width="100px" class="formItemsBox">
              <div
                class="value_items"
                v-for="(domain, index) in ingredientsList"
                :key="domain.key"
                :prop="'domains.' + index + '.value'"
              >
                <el-form-item class="v_i_item" prop="value" label="名称:">
                  <el-input v-model="domain.value"></el-input>
                </el-form-item>
                <el-form-item class="v_i_item" prop="label" label="用量:">
                  <el-input type="textarea" autosize v-model="domain.label"></el-input>
                </el-form-item>
                <div class="item_btn" @click.prevent="removeDomain(domain)">删除</div>
              </div>
              <el-button class="addGroup" type="primary" size="mini" icon="el-icon-plus" @click="addDomain"
                >新增字段</el-button
              >
            </el-form>
            <el-input v-model="formData.ingredients"></el-input>
          </el-form-item>
          <el-form-item label="步骤:">
            <div id="div1"></div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addFoods = false">取 消</el-button>
        <el-button type="primary" @click="getAddFoodsInfo()">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>
<script>
import E from 'wangeditor'
let editor
import { queryHkFoodsItemsList, getTagsFindAll } from '@/api/hkFoods.js'
export default {
  props: ['addFoods', 'addFoodsInfo', 'navDataList', 'foodsDataInfo', 'title', 'editFoodsData'],
  data() {
    return {
      formData: {
        id: '',
        itemId: '',
        title: '',
        imgUrl: '',
        tags: null,
        ingredients: null,
        effect: null,
        step: '',
        pageviews: ''
      },
      tagsList: [],
      effectList: [],
      tags: [],
      effect: [],
      ingredientsList: [],
      proCityAreaTreeProps: {
        emitPath: false,
        value: 'id',
        label: 'title',
        children: 'children'
      },
      classFoodsList: [],
      addFoodsShow: this.addFoods
    }
  },
  watch: {
    foodsDataInfo() {
      this.formData = this.foodsDataInfo
      let data = JSON.parse(this.foodsDataInfo.tags)
      data.forEach((element) => {
        this.tags.push(element.label)
      })
      let data1 = JSON.parse(this.foodsDataInfo.effect)
      data1.forEach((element) => {
        this.effect.push(element.label)
      })
      this.ingredientsList = JSON.parse(this.foodsDataInfo.ingredients)
      editor.txt.html(this.formData.step)
    },
    title() {
      if (this.title == '新增知识') {
        this.formData.itemId = ''
        this.formData.title = ''
        this.formData.imgUrl = ''
        this.formData.tags = ''
        this.formData.ingredients = ''
        this.formData.effect = ''
        this.formData.pageviews = ''
        this.formData.author = ''
        editor.txt.html((this.formData.step = ''))
      }
    }
  },
  mounted() {
    this.getFoodsClassList()
    this.getTagsList()
  },
  methods: {
    //获取分类
    async getFoodsClassList() {
      let result = await queryHkFoodsItemsList()
      if (result.success) {
        this.classFoodsList = result.data
      }
    },
    //获取标签
    async getTagsList() {
      let result = await getTagsFindAll()
      if (result.success) {
        var data = result.data.filter((items) => items.type == 0)
        data.forEach((element) => {
          var data = {
            label: element.id,
            value: element.title
          }
          this.tagsList.push(data)
        })
        var data1 = result.data.filter((items) => items.type == 1)
        data1.forEach((element) => {
          var data = {
            label: element.id,
            value: element.title
          }
          this.effectList.push(data)
        })
      }
    },
    close() {
      this.$emit('closeAddFoods')
    },
    handleChange(value) {
      let nodesObj = this.$refs['cascader'].getCheckedNodes()
      this.formData.oneLevelId = nodesObj[0].path[0]
      this.formData.twoLevelId = nodesObj[0].path[1]
      this.formData.threeLevelId = nodesObj[0].path[2]
    },
    getEditor() {
      editor = new E('#div1')
      editor.create()
    },
    closeNotice() {
      if (this.title == '新增知识') {
        this.addFoods = false
        this.formData.itemId = ''
        this.formData.title = ''
        this.formData.imgUrl = ''
        this.formData.tags = ''
        this.formData.ingredients = ''
        this.formData.effect = ''
        this.formData.pageviews = ''
        editor.txt.html((this.formData.step = ''))
      } else {
        this.addFoods = false
      }
    },
    // 普通新增
    addDomain() {
      this.ingredientsList.push({
        value: '',
        label: ''
      })
    },
    removeDomain(item) {
      var index = this.ingredientsList.indexOf(item)
      if (index !== -1) {
        this.ingredientsList.splice(index, 1)
      }
    },
    // 提交表单
    async getAddFoodsInfo() {
      var tagsList1 = []
      this.tags.forEach((element) => {
        this.tagsList.forEach((data) => {
          if (element == data.label) {
            tagsList1.push(data)
          }
        })
      })
      this.formData.tags = JSON.stringify(tagsList1)
      var effectList1 = []
      this.effect.forEach((element) => {
        this.effectList.forEach((data) => {
          if (element == data.label) {
            effectList1.push(data)
          }
        })
      })
      this.formData.effect = JSON.stringify(effectList1)
      this.formData.ingredients = JSON.stringify(this.ingredientsList)
      const step = editor.txt.html()
      this.formData.step = step
      if (this.title == '新增知识') {
        this.addFoods = false
        this.addFoodsInfo(this.formData)
        this.formData.itemId = ''
        this.formData.title = ''
        this.formData.imgUrl = ''
        this.formData.tags = ''
        this.formData.ingredients = ''
        this.formData.effect = ''
        this.formData.pageviews = ''
        editor.txt.html((this.formData.step = ''))
      } else if (this.title == '编辑知识') {
        this.editFoodsData(this.formData)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.el-select {
  width: 100%;
}
.value_items {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .v_i_item {
    width: 50%;
    margin-top: 8px;
    margin-bottom: 0;
  }
  .item_btn {
    padding: 0 16px;
    width: 32px;
    margin-top: 8px;
    line-height: 40px;
    color: red;
  }
}
.addBtn {
  margin-top: 8px;
}
</style>
