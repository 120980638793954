import request from '@/utils/request'

// 财务管理 - 查询全部列表
export function queryList(data) {
  return request({
    url: '/sys/busIncome',
    method: 'POST',
    data: data
  })
}
// 财务管理-数据统计查询
export function queryMoneyManDataList(paymentMethod, data) {
  console.log(data)
  return request({
    url: '/sys/busIncome/paidList/' + paymentMethod,
    method: 'POST',
    data: data
  })
}
// 财务管理-用户服务支付列表
export function queryUserPayOrderList(data) {
  console.log(data)
  return request({
    url: '/sys/userOrder/list',
    method: 'POST',
    data: data
  })
}
// 财务管理-用户押金支付订单列表
export function queryUserPayDepositList(data) {
  console.log(data)
  return request({
    url: '/sys/userDeposit/list',
    method: 'POST',
    data: data
  })
}
// 财务管理-用户押金收入列表
export function queryDepositIncomeList(data) {
  console.log(data)
  return request({
    url: '/sys/depositIncome',
    method: 'POST',
    data: data
  })
}
// 财务管理-服务商周账单 根据账单状态查询
export function findByBillStatusList(billStatus, data) {
  console.log(data)
  return request({
    url: '/sp/busBillInfo/findByBillStatus/' + billStatus,
    method: 'get',
    params: data
  })
}
// 财务管理-服务商周账单 根据id账单状态查询
export function findSpBusBillDateUpdate(id, data) {
  console.log(data)
  return request({
    url: '/sp/busBillInfo/spBusBillDateUpdate/' + id,
    method: 'put',
    data: data
  })
}