<template>
  <el-container>
    <!-- 面包屑导航 -->
    <el-header class="header">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/fileList' }">居民档案列表</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/fileDetails' }">
          {{ profileRecord.villageName }}小区{{ profileRecord.floorName }}号楼{{ profileRecord.unitName }}单元{{
            profileRecord.layerName
          }}层{{ profileRecord.roomName }}室</el-breadcrumb-item
        >
        <el-breadcrumb-item>{{ personeDt.name }}</el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>
    <el-main>
      <!-- 基本信息 -->
      <div class="baseInfo">
        <div class="baseInfo_tit">基本信息</div>
        <el-row>
          <el-col :span="24">
            <el-col :span="6">
              <div class="grid-content bg-purple">
                <div class="grid_vlaue">姓名:</div>
                <div class="grid_key">{{ personeDt.name }}</div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple">
                <div class="grid_vlaue">性别:</div>
                <div class="grid_key">{{ personeDt.gender }}</div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple">
                <div class="grid_vlaue">民族:</div>
                <div class="grid_key">{{ personeDt.nation }}</div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple">
                <div class="grid_vlaue">婚姻状况:</div>
                <div class="grid_key">{{ personeDt.maritalStatus }}</div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple">
                <div class="grid_vlaue">与业主关系:</div>
                <div class="grid_key">{{ personeDt.ownerRelationship }}</div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple">
                <div class="grid_vlaue">出生日期:</div>
                <div class="grid_key">{{ personeDt.birthDate }}</div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple">
                <div class="grid_vlaue">年龄:</div>
                <div class="grid_key">{{ personeDt.age }}</div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple">
                <div class="grid_vlaue">居住情况:</div>
                <div class="grid_key">{{ personeDt.livingConditions }}</div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple">
                <div class="grid_vlaue">联系电话:</div>
                <div class="grid_key">{{ personeDt.mobile }}</div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple">
                <div class="grid_vlaue">备用电话:</div>
                <div class="grid_key">{{ personeDt.standbyTelephone }}</div>
              </div>
            </el-col>
          </el-col>
        </el-row>
      </div>
      <!-- 身份证 -->
      <div class="idCard">
        <el-row>
          <el-col :span="6">
            <div class="grid-content bg-purple">
              <div class="grid_vlaue">身份证号码:</div>
              <div class="grid_key">{{ personeDt.cardNo }}</div>
            </div>
          </el-col>
          <el-col :span="9">
            <div class="i_positive">
              <el-image :src="personeDt.cardFront" class="img"></el-image>
            </div>
          </el-col>
          <el-col :span="9">
            <div class="i_positive">
              <el-image :src="personeDt.cardBack" class="img"></el-image>
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- 紧急联系人 -->
      <div class="baseInfo">
        <div class="baseInfo_tit">紧急联系人</div>
        <el-row>
          <el-col :span="24">
            <el-col :span="6">
              <div class="grid-content bg-purple">
                <div class="grid_vlaue">姓名:</div>
                <div class="grid_key">{{ personeDt.emergencyContactName }}</div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple">
                <div class="grid_vlaue">联系电话:</div>
                <div class="grid_key">{{ personeDt.emergencyContactPhone }}</div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple">
                <div class="grid_vlaue">关系:</div>
                <div class="grid_key">{{ personeDt.ownerRelationship }}</div>
              </div>
            </el-col>
          </el-col>
        </el-row>
      </div>
      <!-- 家庭经济状况 -->
      <div class="baseInfo">
        <div class="baseInfo_tit">家庭经济状况</div>
        <el-row>
          <el-col :span="24">
            <el-col :span="6">
              <div class="grid-content bg-purple">
                <div class="grid_vlaue">经济来源:</div>
                <div class="grid_key">{{ personeDt.economicSource }}</div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple">
                <div class="grid_vlaue">月收入:</div>
                <div class="grid_key">{{ personeDt.monthlyIncome }}</div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple">
                <div class="grid_vlaue">医疗保障:</div>
                <div class="grid_key">{{ personeDt.medicalSecurity }}</div>
              </div>
            </el-col>
          </el-col>
        </el-row>
      </div>
      <!-- 健康状况 -->
      <div class="baseInfo">
        <div class="baseInfo_tit">健康状况</div>
        <el-row>
          <el-col :span="24">
            <el-col>
              <div class="grid-content bg-purple">
                <div class="grid_vlaue">慢性病:</div>
                <div class="grid_key">{{ personeDt.chronicDisease }}</div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple">
                <div class="grid_vlaue">血型:</div>
                <div class="grid_key">{{ personeDt.bloodType }}</div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple">
                <div class="grid_vlaue">失能状况:</div>
                <div class="grid_key">{{ personeDt.disabilityCondition }}</div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple">
                <div class="grid_vlaue">残疾状况:</div>
                <div class="grid_key">{{ personeDt.disabilityStatus }}</div>
              </div>
            </el-col>
          </el-col>
        </el-row>
      </div>
      <!-- 特长爱好 -->
      <div class="baseInfo">
        <div class="baseInfo_tit">特长爱好</div>
        <div class="tagBox">
          <el-tag class="tag">{{ personeDt.hobbies }}</el-tag>
        </div>
      </div>
      <!-- 备注信息 -->
      <div class="baseInfo">
        <div class="baseInfo_tit">备注信息</div>
        <el-row>
          <el-col :span="24">
            <el-col :span="6">
              <div class="grid-content bg-purple">
                <div class="grid_vlaue">文化程度:</div>
                <div class="grid_key">{{ personeDt.cultureDegree }}</div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple">
                <div class="grid_vlaue">专业:</div>
                <div class="grid_key">{{ personeDt.major }}</div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple">
                <div class="grid_vlaue">职称:</div>
                <div class="grid_key">{{ personeDt.title }}</div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple">
                <div class="grid_vlaue">原单位:</div>
                <div class="grid_key">{{ personeDt.primaryCompany }}</div>
              </div>
            </el-col>
          </el-col>
        </el-row>
      </div>
    </el-main>
  </el-container>
</template>
<script>
import { queryDtById } from '@/api/archives.js'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
      personeDt: {}
    }
  },
  computed: {
    ...mapGetters(['profileRecord'])
  },
  mounted() {
    console.log('222:', this.$route.query.id)
    const id = this.$route.query.id
    this.getMemberDt(id)
  },
  methods: {
    //获取家庭成员详情
    async getMemberDt(id) {
      let result = await queryDtById(id)
      if (result.success) {
        this.personeDt = result.data
      }
    }
  }
}
</script>
<style lang="less" scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .el-breadcrumb {
    font-size: 16px !important;
    font-weight: 500;
  }
}
.baseInfo {
  margin: 16px;
  padding: 16px;
  background: #ffffff;
  border: 1px solid #c7cfcf;
  .baseInfo_tit {
    font-weight: 500;
    padding-bottom: 16px;
  }
  .grid-content {
    display: flex;
    padding: 8px 0;
  }
  .grid_vlaue {
    padding-right: 16px;
  }
  .r_avatarBox {
    width: 72px;
    height: 72px;
  }
}
.idCard {
  margin: 16px;
  padding: 16px;
  background: #ffffff;
  border: 1px solid #c7cfcf;
  .grid-content {
    display: flex;
    padding: 8px 0;
    .grid_vlaue {
      padding-right: 16px;
    }
  }
  .i_positive {
    width: 300px;
    height: 100px;
    .img {
      width: 100%;
      height: 100%;
    }
  }
}
.tagBox {
  display: flex;
  .tag {
    margin-right: 16px;
  }
}
</style>
