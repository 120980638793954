<template>
  <el-container>
    <el-dialog
      :title="title"
      :visible.sync="addGoods"
      width="50%"
      @close="close"
      @opened="getEditor()"
      append-to-body
      :close-on-click-modal="false"
    >
      <div class="content">
        <el-form ref="formData" :model="formData" label-width="80px">
          <el-form-item label="标题:">
            <el-input v-model="formData.title"></el-input>
          </el-form-item>
          <el-form-item label="图片Url:" class="item_input">
            <el-input v-model="formData.imgUrl" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="所属分类:">
            <el-cascader
              ref="cascader"
              v-model="formData.fourLevelId"
              :options="classGoodsList"
              :props="proCityAreaTreeProps"
              @change="handleChange"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="联盟类型:">
            <el-input placeholder=" 0 京东" v-model="formData.type"></el-input>
          </el-form-item>
          <el-form-item label="好评:">
            <el-input v-model="formData.sold"></el-input>
          </el-form-item>
          <el-form-item label="价格:">
            <el-input v-model="formData.currentPrice"></el-input>
          </el-form-item>
          <el-form-item label="推广口令:">
            <el-input v-model="formData.jdPsw"></el-input>
          </el-form-item>
          <el-form-item label="推广链接:">
            <el-input v-model="formData.goodsLink"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addGoods = false">取 消</el-button>
        <el-button type="primary" @click="getaddGoodsInfo()">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>
<script>
export default {
  props: ['addGoods', 'addGoodsInfo', 'navDataList', 'goodsDataInfo', 'title', 'editGoodsData'],
  data() {
    return {
      formData: {
        id: null,
        oneLevelId: null,
        twoLevelId: null,
        threeLevelId: null,
        fourLevelId: null,
        title: null,
        imgUrl: null,
        type: null,
        currentPrice: null,
        jdPsw: null,
        goodsLink: null,
        sold: null
      },
      proCityAreaTreeProps: {
        emitPath: false,
        value: 'id',
        label: 'title',
        children: 'children'
      },
      classGoodsList: [],
      addGoodsShow: this.addGoods
    }
  },
  watch: {
    navDataList() {
      this.classGoodsList = this.navDataList
    },
    goodsDataInfo() {
      this.formData = this.goodsDataInfo
    },
    title() {
      if (this.title == '新增知识') {
        this.formData.pageView = null
        this.formData.imgUrl = null
        this.formData.goodsTags = null
        this.formData.content = null
        this.formData.oneLevelId = null
        this.formData.twoLevelId = null
        this.formData.threeLevelId = null
        this.formData.className = null
        this.formData.pageView = null
        this.formData.prompt = null
        this.formData.source = null
        this.formData.author = null
      }
    }
  },
  methods: {
    close() {
      this.$emit('closeAddGoods')
    },
    handleChange(value) {
      let nodesObj = this.$refs['cascader'].getCheckedNodes()
      this.formData.oneLevelId = nodesObj[0].path[0]
      this.formData.twoLevelId = nodesObj[0].path[1]
      this.formData.threeLevelId = nodesObj[0].path[2]
      this.formData.fourLevelId = nodesObj[0].path[3]
    },
    closeNotice() {
      if (this.title == '新增知识') {
        this.addGoods = false
      } else {
        this.addGoods = false
      }
    },
    // 提交表单
    async getaddGoodsInfo() {
      if (this.title == '新增知识') {
        this.addGoods = false
        this.addGoodsInfo(this.formData)
      } else if (this.title == '编辑知识') {
        this.editGoodsData(this.formData)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.el-select {
  width: 100%;
}
</style>
