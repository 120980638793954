<template>
  <el-container>
    <el-dialog
      :title="title"
      :visible.sync="addCashCoupon"
      width="50%"
      @close="close"
      append-to-body
      :close-on-click-modal="false"
    >
      <div class="content">
        <el-form ref="formData" :model="formData" label-width="80px">
          <el-form-item label="分类:">
            <el-input v-model="formData.couponClass" placeholder="分类"></el-input>
          </el-form-item>
          <el-form-item label="名称:">
            <el-input v-model="formData.couponName" placeholder="名称"></el-input>
          </el-form-item>
          <el-form-item label="金额:">
            <div class="couponAmount">
              <el-input v-model="formData.couponAmount" placeholder="金额"></el-input>
              <p>元</p>
            </div>
          </el-form-item>
          <el-form-item label="编号:">
            <el-input v-model="formData.couponNumber" placeholder="用户可以使用的 8 位数优惠编号"></el-input>
          </el-form-item>
          <el-form-item label="状态:">
            <el-radio-group v-model="formData.writeOff">
              <el-radio :label="0">可用</el-radio>
              <el-radio :label="1">已用</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="getAddCashCouponInfo()">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>
<script>
export default {
  props: ['addCashCoupon', 'addCashCouponInfo', 'navDataList', 'cashCouponDataInfo', 'title', 'editCashCouponData'],
  data() {
    return {
      formData: {
        couponAmount: '',
        couponClass: '',
        couponName: '',
        couponNumber: '',
        writeOff: 0
      },
      addCashCoupon: this.addCashCoupon
    }
  },
  watch: {
    cashCouponDataInfo() {
      this.formData = this.cashCouponDataInfo
    },
    title() {
      if (this.title == '新增') {
        this.formData.couponAmount = ''
        this.formData.couponClass = ''
        this.formData.couponName = ''
        this.formData.couponNumber = ''
      }
    }
  },
  methods: {
    close() {
      this.$emit('closeDlg')
    },
    // 提交表单
    async getAddCashCouponInfo() {
      if (this.title == '新增') {
        this.addCashCouponInfo(this.formData)
        this.addCashCoupon = false
        this.formData.couponAmount = ''
        this.formData.couponClass = ''
        this.formData.couponName = ''
        this.formData.couponNumber = ''
      } else if (this.title == '编辑') {
        this.editCashCouponData(this.formData)
        this.addCashCoupon = false
      }
    }
  }
}
</script>
<style lang="less" scoped>
.el-select {
  width: 100%;
}
.couponAmount {
  display: flex;
  p {
    padding: 0;
    margin: 0;
    padding-left: 12px;
  }
}
</style>
