<template>
  <el-container>
    <el-dialog
      :title="title"
      :visible.sync="addHkCompany"
      width="50%"
      @close="close"
      append-to-body
      :close-on-click-modal="false"
    >
      <div class="content">
        <el-form ref="formData" :model="formData" label-width="80px">
          <el-form-item label="公司名称:">
            <el-input v-model="formData.companyName"></el-input>
          </el-form-item>
          <el-form-item label="法人姓名:">
            <el-input v-model="formData.corporation"></el-input>
          </el-form-item>
          <el-form-item label="负责人:">
            <el-input v-model="formData.superintendent"></el-input>
          </el-form-item>
          <el-form-item label="负责电话:">
            <el-input v-model="formData.superintendentPhone"></el-input>
          </el-form-item>
          <el-form-item label="所属地区:">
            <el-input v-model="formData.area"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addHkCompany = false">取 消</el-button>
        <el-button type="primary" @click="getAddHkCompanyInfo()">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>
<script>
export default {
  props: ['addHkCompany', 'addHkCompanyInfo', 'hkCompanyDataInfo', 'title', 'editHkCompanyData'],
  data() {
    return {
      formData: {
        id: '',
        companyName: '',
        corporation: '',
        superintendent: '',
        superintendentPhone: '',
        area: ''
      },
      addHkCompanyShow: this.addHkCompany
    }
  },
  watch: {
    hkCompanyDataInfo() {
      this.formData = this.hkCompanyDataInfo
      this.formData.id = this.hkCompanyDataInfo.id
    },
    title() {
      if (this.title == '新增') {
        this.formData.companyName = ''
        this.formData.corporation = ''
        this.formData.superintendent = ''
        this.formData.superintendentPhone = ''
        this.formData.area = ''
      }
    }
  },
  methods: {
    close() {
      this.$emit('closeAddHkCompany')
    },
    // 提交表单
    async getAddHkCompanyInfo() {
      if (this.title == '新增') {
        this.addHkCompanyInfo(this.formData)
        this.addHkCompany = false
      } else if (this.title == '编辑') {
        this.editHkCompanyData(this.formData)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.el-select {
  width: 100%;
}
</style>
