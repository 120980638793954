import request from '@/utils/request'

// 会员卡管理 - 分页查询全部
export function queryMemberCardFindAll (params) {
  console.log(params)
  return request({
    url: '/sys/memberCard/findAll',
    method: 'get',
    params: params
  })
}
// 会员卡管理 - 新增
export function addMemberCard(data) {
  console.log(data)
  return request({
      url: '/sys/memberCard',
      method: 'POST',
      data:data
  })
}
// 会员卡管理 - 更新
export function updateMemberCard(data) {
  return request({
    url: '/sys/memberCard/'+data.id,
      method: 'put',
      data:data

  })
}
// 会员卡管理 - 删除
export function delMemberCard(id) {
  return request({
    url: '/sys/memberCard/'+id,
      method: 'delete',
  })
}