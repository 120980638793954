<template>
  <el-container>
    <el-main>
      <el-row>
        <el-col :span="4" class="leftBox">
          <div class="left_titBox">
            <div class="l_t_txt">服务分类</div>
            <div class="l_t_btn" @click="OpenAddServiceItems">添加</div>
          </div>
          <div class="left_contentBox">
            <div class="l_c_items" v-for="(items, v) in navDataList" :key="v">
              <div class="l_c_i_txt" :class="{ active: isActive == v }" @click="getClassKnowList(items, v)">
                {{ items.name }}
              </div>
              <div class="l_c_i_btn">
                <div class="l_c_i_edit" @click="openEditServiceItems(items)">编辑</div>
                <div class="l_c_i_dele" @click="openDeleteServiceItems(items)">删除</div>
              </div>
            </div>
          </div>
        </el-col>
        <!-- 列表 -->
        <div class="rightBox">
          <div class="rightBtn">
            <div class="leftTit">服务列表</div>
            <el-button type="primary" @click="OpenServiceGoods">新增服务</el-button>
          </div>
          <el-col class="list" :span="20">
            <el-table :data="tableData.rows" style="width: 100%">
              <el-table-column prop="id" label="ID" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column label="分类名称" :show-overflow-tooltip="true">
                <template>
                  <p>{{ serviceItems }}</p>
                </template>
              </el-table-column>
              <el-table-column prop="cityName" label="服务地区" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="serviceTitle" label="服务标题" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="serviceDec" label="服务描述" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="serviceContent" label="服务内容" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="floorPrice" label="起步价" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <p>¥{{ scope.row.floorPrice }}元</p>
                </template>
              </el-table-column>
              <el-table-column prop="createTime" label="创建时间" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <p>{{ formatDate(scope.row.createTime) }}</p>
                </template>
              </el-table-column>
              <el-table-column fixed="right" label="操作" width="120">
                <template slot-scope="scope">
                  <el-button @click.native.prevent="openKnowDataInfo(scope.row)" type="text" size="small">
                    编辑
                  </el-button>
                  <el-button @click.native.prevent="deleteRow(scope.row)" type="text" size="small"> 删除 </el-button>
                </template>
              </el-table-column>
            </el-table>
            <div class="pageBox">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :page-sizes="[5, 10, 20]"
                layout="total, sizes, prev, pager, next, jumper"
                :total="tableData.total"
              >
              </el-pagination>
            </div>
          </el-col>
        </div>
      </el-row>
      <!-- 添加分类 -->
      <addServiceItems
        :title="title"
        :addServiceItems="addServiceItemsShow"
        @closeAddServiceItems="showAddServiceItems"
        :addServiceItemsInfo="addServiceItemsInfo"
        :serviceItemsDataInfo="serviceItemsDataInfo"
        :editServiceItems="editServiceItems"
      ></addServiceItems>
      <!-- 添加知识 -->
      <addServiceGoods
        :addServiceGoods="addServiceGoodsShow"
        :title="title"
        @closeAddServiceGoods="showAddServiceGoods"
        :addServiceGoodsInfo="addServiceGoodsInfo"
        :navDataList="navDataList"
        :serviceGoodsDataInfo="serviceGoodsDataInfo"
        :editServiceGoodsData="editServiceGoodsData"
      ></addServiceGoods>
    </el-main>
  </el-container>
</template>
<script>
import {
  queryServiceItems,
  queryItemsServiceGoods,
  addAerviceItems,
  putAerviceItems,
  delServiceItems,
  addServiceGoodsData,
  putServiceGoodsDate,
  delServiceGoodsData
} from '@/api/serviceProvider.js'
import AddServiceItems from '@/components/serviceProvider/addServiceItems'
import AddServiceGoods from '@/components/serviceProvider/addServiceGoods'
export default {
  components: {
    AddServiceItems: AddServiceItems,
    AddServiceGoods: AddServiceGoods
  },
  data() {
    return {
      addServiceItemsShow: false,
      addServiceGoodsShow: false,
      isActive: 0,
      input: '',
      navDataList: [],
      tableData: [],
      query: {
        page: 1,
        pageSize: 10
      },
      classId: '',
      serviceItems: '',
      displayTerminal: '',
      serviceGoodsDataInfo: '', // 编辑内容
      title: '',
      serviceItemsDataInfo: '' //编辑分类
    }
  },
  mounted() {
    this.getServiceItemsList()
  },
  methods: {
    //获取分类
    async getServiceItemsList() {
      let result = await queryServiceItems()
      if (result.success) {
        this.navDataList = result.data
        this.serviceItems = this.navDataList[0].name
        this.displayTerminal = this.navDataList[0].displayTerminal
        let res = await queryItemsServiceGoods(this.navDataList[0].id, this.query)
        if (res.success) {
          this.tableData = res.data
        }
      }
    },
    // 获取分类下商品
    getClassKnowList(items, v) {
      this.isActive = v
      this.classId = items.id
      this.serviceItems = items.name
      this.displayTerminal = items.displayTerminal
      this.getServiceGoodsList()
    },
    async getServiceGoodsList() {
      let res = await queryItemsServiceGoods(this.classId, this.query)
      console.log(res)
      if (res.success) {
        this.tableData = res.data
      }
    },
    //分页-size
    handleSizeChange(val) {
      this.query.pageSize = val
      this.getServiceGoodsList()
    },
    //分页-page
    handleCurrentChange(val) {
      this.query.page = val
      this.getServiceGoodsList()
    },
    // 新增分类
    OpenAddServiceItems() {
      this.addServiceItemsShow = true
      this.title = '新增分类'
    },
    showAddServiceItems() {
      this.addServiceItemsShow = false
    },
    // 新增分类接口
    async addServiceItemsInfo(data) {
      let res = await addAerviceItems(data)
      if (res.success) {
        this.$message({
          type: 'success',
          message: '新增分类成功!'
        })
        let result = await queryServiceItems()
        if (result.success) {
          this.navDataList = result.data
        }
        this.addServiceItemsShow = false
      } else {
        this.$message({
          type: 'error',
          message: '新增分类失败!'
        })
      }
      this.getKnowClassList()
    },
    // 编辑分类
    async openEditServiceItems(items) {
      this.addServiceItemsShow = true
      this.serviceItemsDataInfo = items
      this.title = '编辑分类'
    },
    // 编辑分类接口
    async editServiceItems(data) {
      let res = await putAerviceItems(data, data.id)
      if (res.success) {
        this.$message({
          type: 'success',
          message: '编辑分类成功!'
        })
        let result = await queryServiceItems()
        if (result.success) {
          this.navDataList = result.data
        }
        this.addServiceItemsShow = false
      } else {
        this.$message({
          type: 'error',
          message: '编辑分类失败!'
        })
      }
    },
    // 删除分类
    async openDeleteServiceItems(items) {
      this.$confirm('此操作将永久删除该分类, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      })
        .then(async () => {
          let res = await delServiceItems(items.id)
          if (res.success) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            let result = await queryServiceItems()
            if (result.success) {
              this.navDataList = result.data
            }
          } else {
            this.$message({
              type: 'error',
              message: '删除失败'
            })
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 新增服务
    async OpenServiceGoods() {
      this.title = '新增服务'
      let result = await queryServiceItems()
      if (result.success) {
        this.navDataList = result.data
      }
      this.addServiceGoodsShow = true
    },
    showAddServiceGoods() {
      this.addServiceGoodsShow = false
    },
    // 新增服务接口
    async addServiceGoodsInfo(data) {
      let res = await addServiceGoodsData(data)
      if (res.success) {
        this.$message({
          type: 'success',
          message: '新增服务成功!'
        })
        this.getServiceGoodsList()
      } else {
        this.$message({
          type: 'error',
          message: '新增服务失败!'
        })
      }
    },
    // 编辑服务
    openKnowDataInfo(row) {
      this.serviceGoodsDataInfo = row
      this.addServiceGoodsShow = true
      this.title = '编辑服务'
    },
    // 编辑服务接口
    async editServiceGoodsData(data) {
      let res = await putServiceGoodsDate(data)
      if (res.success) {
        this.$message({
          type: 'success',
          message: '编辑服务成功!'
        })
        this.getServiceGoodsList()
        this.addServiceGoodsShow = false
      } else {
        this.$message({
          type: 'error',
          message: '编辑服务失败!'
        })
      }
    },
    // 删除服务
    deleteRow(row) {
      this.$confirm('此操作将永久删除该服务, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      })
        .then(async () => {
          let res = await delServiceGoodsData(row.id)
          if (res.success) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.getClassKnowList()
          } else {
            this.$message({
              type: 'error',
              message: '删除失败'
            })
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    }
  }
}
</script>
<style lang="less" scoped>
.listData {
  padding: 16px;
  .grid-content {
    height: 95px;
    display: flex;
    align-items: center;
    justify-content: center;
    .grid_inner {
      text-align: center;
      .data_number {
        font-size: 32px;
        font-weight: 500;
        padding-bottom: 8px;
      }
    }
  }
}
.searchBox {
  margin: 0 16px;
  padding: 16px;
  background: #ffffff;
  border: 1px solid #c7cfcf;
  .searchTit {
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 16px;
  }
  .key_group {
    display: flex;
    .key_group_item {
      display: flex;
      align-items: center;
      margin-right: 24px;
      .key_tit {
        margin-right: 16px;
      }
    }
  }
}
.active {
  color: #09bebe;
}
.rightBtn {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 500;
}
.leftBox {
  border-right: #c7cfcf 0.5px solid;
  height: calc(100vh - 76px);
  .left_titBox {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .l_t_txt {
      font-size: 16px;
      font-weight: 500;
    }
    .l_t_btn {
      font-size: 14px;
      color: #09bebe;
      cursor: pointer;
    }
  }
  .left_contentBox {
    .l_c_items {
      padding: 16px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .l_c_i_dele {
        color: #be0909;
        display: none;
      }
      .l_c_i_edit {
        color: #09bebe;
        display: none;
        margin-right: 16px;
      }
      .l_c_i_btn {
        display: flex;
        justify-content: space-between;
      }
      .l_c_i_txt {
        display: flex;
        p {
          padding: 0;
          margin: 0;
        }
      }
    }
    .l_c_items:hover {
      background: #f2f3f4;
      .l_c_i_dele {
        display: block;
      }
      .l_c_i_edit {
        display: block;
      }
    }
  }
}
.list {
  padding: 16px;
  .pageBox {
    height: 60px;
    display: flex;
    justify-content: end;
    align-items: center;
  }
}
</style>
