<template>
  <el-container>
    <el-main>
      <el-row>
        <!-- 列表 -->
        <div class="minBox">
          <div class="rightBtn">
            <div class="leftTit">运营配置</div>
            <el-button type="primary" @click="addConfig">新增配置</el-button>
          </div>
          <el-col class="list" :span="24">
            <el-table :data="tableData.rows" style="width: 100%">
              <el-table-column prop="id" label="ID" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="moduleTitle" label="模块标题" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="sort" label="排序" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="serviceArea" label="服务地区" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column prop="moduleData" label="json" :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column fixed="right" label="操作" width="120">
                <template slot-scope="scope">
                  <el-button @click.native.prevent="openAppOperationDataInfo(scope.row)" type="text" size="small">
                    编辑
                  </el-button>
                  <el-button @click.native.prevent="deleteRow(scope.row)" type="text" size="small"> 删除 </el-button>
                </template>
              </el-table-column>
            </el-table>
            <div class="pageBox">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :page-sizes="[5, 10, 20]"
                layout="total, sizes, prev, pager, next, jumper"
                :total="tableData.total"
              >
              </el-pagination>
            </div>
          </el-col>
        </div>
      </el-row>
      <appOperateConfig
        :appOperateConfig="appOperateConfigShow"
        :title="title"
        @closeAppOperateConfig="showAppOperateConfig"
        :appOperateConfigFormData="appOperateConfigFormData"
        :appOperateDataInfo="appOperateDataInfo"
        :editOperateInfo="editOperateInfo"
      ></appOperateConfig>
    </el-main>
  </el-container>
</template>
<script>
import {
  appOperationPageFindAll,
  addAppOperateConfig,
  putAppOperateConfig,
  delAppOperateConfig
} from '@/api/appOperate.js'
import AppOperateConfig from '@/components/appOperate/appOperateConfig.vue'
export default {
  components: {
    AppOperateConfig: AppOperateConfig
  },
  data() {
    return {
      appOperateConfigShow: false,
      appOperateDataInfo: {},
      title: '',
      tableData: [],
      query: {
        page: 1,
        pageSize: 10
      }
    }
  },
  mounted() {
    this.getPageFindAll()
  },
  methods: {
    // 获取列表
    async getPageFindAll() {
      let res = await appOperationPageFindAll(this.query)
      if (res.success) {
        this.tableData = res.data
      }
      console.log(res)
    },
    //分页-size
    handleSizeChange(val) {
      this.query.pageSize = val
      this.getPageFindAll()
    },
    //分页-page
    handleCurrentChange(val) {
      this.query.page = val
      this.getPageFindAll()
    },
    // 新增
    addConfig() {
      this.appOperateConfigShow = true
      this.title = '新增配置'
    },
    showAppOperateConfig() {
      this.appOperateConfigShow = false
    },
    // 新增接口
    async appOperateConfigFormData(data) {
      let res = await addAppOperateConfig(data)
      if (res.success) {
        this.$message({
          type: 'success',
          message: '新增成功!'
        })
      } else {
        this.$message({
          type: 'error',
          message: '新增失败!'
        })
      }
      this.getPageFindAll()
    },
    // 编辑服务
    openAppOperationDataInfo(row) {
      this.appOperateDataInfo = row
      this.appOperateConfigShow = true
      this.title = '编辑配置'
      console.log('fff', row)
    },
    // 编辑服务接口
    async editOperateInfo(data) {
      let res = await putAppOperateConfig(data)
      if (res.success) {
        this.$message({
          type: 'success',
          message: '编辑服务成功!'
        })
        this.getPageFindAll()
        this.appOperateConfigShow = false
      } else {
        this.$message({
          type: 'error',
          message: '编辑服务失败!'
        })
      }
    },
    // 删除服务
    deleteRow(row) {
      this.$confirm('此操作将永久删除该服务, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      })
        .then(async () => {
          let res = await delAppOperateConfig(row.id)
          if (res.success) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.getPageFindAll()
          } else {
            this.$message({
              type: 'error',
              message: '删除失败'
            })
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    }
  }
}
</script>
<style lang="less" scoped>
.rightBtn {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 500;
}
.list {
  padding: 16px;
  .pageBox {
    height: 60px;
    display: flex;
    justify-content: end;
    align-items: center;
  }
}
</style>
