<template>
  <el-container>
    <el-dialog title="投保" :visible.sync="stayInsurance" width="50%" @close="close">
      <div class="content">
        <div class="c_tit">
          {{ rowInfo.propertyName }}小区 - {{ rowInfo.floorName }}号楼 - {{ rowInfo.unitName }}单元 -
          {{ rowInfo.layerName }}层 - {{ rowInfo.roomName }}室
        </div>
        <el-form :label-position="labelPosition" label-width="80px" :model="formData" ref="formData">
          <el-row :gutter="20">
            <el-col :span="12"
              ><div class="grid-content bg-purple">
                <el-form-item label="投保人" prop="insureName">
                  <el-select v-model="formData.insureName" placeholder="请选择">
                    <el-option v-for="item in hkGroupList" :key="item.id" :label="item.companyName" :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="grid-content bg-purple">
                <el-form-item label="投保名称" prop="insureTitle">
                  <el-select v-model="formData.insureTitle" placeholder="请选择" @change="openInsureTitle">
                    <el-option
                      v-for="item in insuranceGoodsList"
                      :key="item.id"
                      :label="item.insuranceGoodsName"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="grid-content bg-purple">
                <el-form-item label="理赔公司" prop="insureEnterprise">
                  <el-input disabled v-model="formData.insureEnterprise" placeholder="请输入"></el-input>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="grid-content bg-purple">
                <el-form-item label="保单期限" prop="insureTerm">
                  <div class="g_c_unit">
                    <el-input disabled v-model="formData.insureTerm" placeholder="请输入"></el-input>
                    <div class="g_c_unit_txt">年</div>
                  </div>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="grid-content bg-purple">
                <el-form-item label="总理赔额" prop="insureTotal">
                  <div class="g_c_unit">
                    <el-input disabled v-model="formData.insureTotal" placeholder="请输入"></el-input>
                    <div class="g_c_unit_txt">万</div>
                  </div>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="grid-content bg-purple">
                <el-form-item label="投保金额" prop="insureMoney">
                  <div class="g_c_unit">
                    <el-input disabled v-model="formData.insureMoney" placeholder="请输入"></el-input>
                    <div class="g_c_unit_txt">元</div>
                  </div>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="grid-content bg-purple">
                <el-form-item label="投保日期" prop="accessTime">
                  <el-date-picker
                    type="date"
                    placeholder="选择日期"
                    v-model="formData.accessTime"
                    style="width: 100%"
                  ></el-date-picker>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="grid-content bg-purple">
                <el-form-item label="截止日期" prop="dueDate">
                  <el-date-picker
                    type="date"
                    placeholder="选择日期"
                    v-model="formData.dueDate"
                    style="width: 100%"
                  ></el-date-picker>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="grid-content bg-purple">
                <el-form-item label="保单编号" prop="policyNo">
                  <el-input v-model="formData.policyNo" placeholder="请输入"></el-input>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="grid-content bg-purple">
                <el-form-item label="电子保单" prop="policyPdf">
                  <el-input v-model="formData.policyPdf" placeholder="请输入"></el-input>
                </el-form-item>
              </div>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="stayInsurance = false">取 消</el-button>
        <el-button type="primary" @click="submitInsurancePolicy()">确认投保</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>
<script>
import { insuranceGoodsAll, getInsuranceCompany, getInsuranceGoods } from '@/api/insurance.js'
import { queryHkGroupAllList } from '@/api/hkGroup.js'
export default {
  props: ['stayInsurance', 'stayInsuranceInfo', 'stayInsuranceRow'],
  data() {
    return {
      labelPosition: 'left',
      hkGroupList: [],
      insuranceGoodsList: [],
      rowInfo: {},
      formData: {
        roomId: '',
        insureTitle: '',
        insureEnterprise: '',
        policyNo: '',
        insureTerm: '',
        accessTime: '',
        dueDate: '',
        insureTotal: '',
        insureName: '',
        insureMoney: '',
        policyPdf: ''
      }
    }
  },
  watch: {
    stayInsuranceRow(n, o) {
      this.rowInfo = n
      this.formData.roomId = n.roomId
    }
  },
  mounted() {
    this.getInsuranceGoodsList()
    this.getHkGroupList()
  },
  methods: {
    // 查询所有投保人信息
    async getHkGroupList() {
      let res = await queryHkGroupAllList()
      this.hkGroupList = res.data
    },
    // 查询所有保险套餐
    async getInsuranceGoodsList() {
      let res = await insuranceGoodsAll()
      if (res.success) {
        this.insuranceGoodsList = res.data
      }
    },
    // 根据选中的保险套餐查询保险公司
    async openInsureTitle(value) {
      let res = await getInsuranceGoods(value)
      if (res.success) {
        this.formData.insureTerm = res.data.insuranceCycle
        this.formData.insureTotal = res.data.insureTotal
        this.formData.insureMoney = res.data.insuranceMoney
        let ress = await getInsuranceCompany(res.data.insuranceCompanyId)
        if (ress.success) {
          this.formData.insureEnterprise = ress.data.companyName
        } else {
          this.$message.success(ress.message)
        }
      } else {
        this.$message.success(res.message)
      }
    },
    close() {
      this.$emit('closeStayInsurance')
    },
    async submitInsurancePolicy() {
      this.stayInsuranceInfo(this.formData)
      this.stayInsurance = false
      this.resetForm()
    },
    resetForm() {
      this.formData.roomId = ''
      this.formData.insureTitle = ''
      this.formData.insureEnterprise = ''
      this.formData.policyNo = ''
      this.formData.insureTerm = ''
      this.formData.accessTime = ''
      this.formData.dueDate = ''
      this.formData.insureTotal = ''
      this.formData.insureName = ''
      this.formData.insureMoney = ''
      this.formData.policyPdf = ''
    }
  }
}
</script>
<style lang="less" scoped>
.content {
  color: #202626;
  text-align: center;
  .number {
    font-size: 40px;
    font-weight: 500;
    padding: 16px 0;
  }
  .c_tit {
    font-size: 18px;
    font-weight: 500;
    padding-bottom: 24px;
  }
  .g_c_unit {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .g_c_unit_txt {
      margin-left: 16px;
    }
  }
}
.el-select {
  display: flex;
  justify-content: start;
}
</style>
