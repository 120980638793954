import request from '@/utils/request'

// 投保管理 - 获取全部投保信息列表
export function queryInsureByAll (data) {
  return request({
    url: `/sys/userInsureInfo/findAll`,
    method: 'post',
    data: data,
  })
}
// 投保管理 - 获取全部待投保信息列表
export function queryFindByStayInsureAll (data) {
  return request({
    url: `/sys/userInsureInfo/findByStayInsureAll`,
    method: 'post',
    data: data,
  })
}
// 投保管理 - 投保信息根据roomId查询所有
export function queryInsureById (id,data) {
  return request({
    url: `/sys/userInsureInfo/roomIdList/` + id,
    method: 'post',
    data: data,
  })
}
// 投保管理 - 添加投保信息
export function queryAddUserInsureInfo (data) {
  return request({
    url: `/sys/userInsureInfo`,
    method: 'post',
    data: data,
  })
}
// 投保管理 - 删除投保信息
export function queryDeleteUserInsureInfo (id) {
  return request({
    url: `/sys/userInsureInfo/` + id,
    method: 'DELETE',
  })
}

// 保险公司管理 - 公司列表分页查询
export function insuranceCompanyPageList(data) {
  return request({
      url: '/sys/insuranceCompany/findAll',
      method: 'get',
      params:data
      
  })
}
// 保险公司管理 - 公司列表不分页
export function insuranceCompanyList() {
  return request({
      url: '/sys/insuranceCompany/list',
      method: 'get',
      
  })
}
// 保险公司管理 - 新增公司
export function addInsuranceCompany(data) {
  console.log(data)
  return request({
      url: '/sys/insuranceCompany',
      method: 'POST',
      data:data
  })
}
// 保险公司管理 - 根据id查询
export function getInsuranceCompany(id) {
  return request({
      url: '/sys/insuranceCompany/' + id,
      method: 'get',
  })
}
// 保险公司管理 - 删除
export function delInsuranceCompany(id) {
  return request({
      url: '/sys/insuranceCompany/' + id,
      method: 'delete',
  })
}

// 保险公司管理 - 更新
export function updateInsuranceCompany(data) {
  return request({
      url: '/sys/insuranceCompany/'+ data.id,
      method: 'put',
      data:data

  })
}

// 保险套餐管理 - 全部列表
export function insuranceGoodsList(data) {
  return request({
      url: '/sys/insuranceGoods/findAll',
      method: 'get',
      params:data
      
  })
}
// 保险套餐管理 - 全部列表不分页
export function insuranceGoodsAll() {
  return request({
      url: '/sys/insuranceGoods/findAllList',
      method: 'get',
  })
}
// 保险套餐管理 - 根据公司查询
export function insuranceGoodsById(data,id) {
  return request({
      url: '/sys/insuranceGoods/list/' + id,
      method: 'get',
      params:data
      
  })
}
// 保险套餐管理 - 新增
export function addInsuranceGoods(data) {
  console.log(data)
  return request({
      url: '/sys/insuranceGoods',
      method: 'POST',
      data:data
  })
}
// 保险套餐管理 - 根据id查询
export function getInsuranceGoods(id) {
  return request({
      url: '/sys/insuranceGoods/' + id,
      method: 'get',
  })
}
// 保险套餐管理 - 删除
export function delInsuranceGoods(id) {
  return request({
      url: '/sys/insuranceGoods/' + id,
      method: 'delete',
  })
}

// 保险套餐管理 - 更新
export function updateInsuranceGoods(data) {
  return request({
      url: '/sys/insuranceGoods/'+ data.id,
      method: 'put',
      data:data

  })
}