<template>
  <el-container>
    <el-header class="header">
      <div class="leftTit">解决记录</div>
    </el-header>
    <el-main>
      <!-- 列表 -->
      <div class="list">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column prop="id" label="ID">
          </el-table-column>
          <el-table-column prop="name" label="所属物业">
          </el-table-column>
          <el-table-column prop="address" label="所属地区">
          </el-table-column>
          <el-table-column prop="date" label="小区名称">
          </el-table-column>
          <el-table-column prop="name" label="小区地址">
          </el-table-column>
          <el-table-column prop="address" label="房号">
          </el-table-column>
          <el-table-column prop="address" label="业主姓名">
          </el-table-column>
          <el-table-column prop="address" label="联系电话">
          </el-table-column>
          <el-table-column prop="address" label="服务类型">
          </el-table-column>
          <el-table-column prop="address" label="服务套餐">
          </el-table-column>
          <el-table-column prop="address" label="接入时间">
          </el-table-column>
          <el-table-column prop="address" label="截止时间">
          </el-table-column>
          <el-table-column prop="address" label="解约原因">
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="120">
            <template slot-scope="scope">
              <el-button @click.native.prevent="callbackRow(scope.$index, tableData)" type="text" size="small">
                电话
              </el-button>
              <el-button @click.native.prevent="seeRow(scope.$index, tableData)" type="text" size="small">
                查看
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 隐私电话弹框 -->
        <privacyCall :privacyCall="privacyCallShow" @closePrivacyCall="showPrivacyCall"></privacyCall>
      </div>
    </el-main>
  </el-container>
</template>
<script>
import PrivacyCall from '@/components/public/privacyCall'
export default {
  props: ['PrivacyCall'],
  components: {
    privacyCall: PrivacyCall
  },
  data () {
    return {
      privacyCallShow: false,
      tableData: [{
        date: '2016-05-02',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-04',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1517 弄'
      }, {
        date: '2016-05-01',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1519 弄'
      }, {
        date: '2016-05-03',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1516 弄'
      }]
    }
  },
  methods: {
    callbackRow (index) {
      console.log(index)
      this.privacyCallShow = true
    },
    showPrivacyCall () {
      this.privacyCallShow = false
    },
    seeRow (index) {
      console.log(index)
      this.$router.push({ path: '/fileDetails' })
    }
  }
}
</script>
<style lang="less" scoped>
.header {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  line-height: 60px;
  display: flex;
  justify-content: space-between;
}
.list {
  padding: 16px;
}
</style>
