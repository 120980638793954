<template>
  <el-container>
    <el-dialog
      :title="title"
      :visible.sync="addInsurance"
      width="50%"
      @close="close"
      append-to-body
      :close-on-click-modal="false"
    >
      <div class="content">
        <el-form ref="formData" :model="formData" label-width="80px">
          <el-form-item label="公司名称:">
            <el-input v-model="formData.companyName"></el-input>
          </el-form-item>
          <el-form-item label="公司官网:">
            <el-input v-model="formData.companyWeb"></el-input>
          </el-form-item>
          <el-form-item label="官方电话:">
            <el-input v-model="formData.companyPhone"></el-input>
          </el-form-item>
          <el-form-item label="所属地区:">
            <el-input v-model="formData.area"></el-input>
          </el-form-item>
          <el-form-item label="对接人:">
            <el-input v-model="formData.dockName"></el-input>
          </el-form-item>
          <el-form-item label="对接电话:">
            <el-input v-model="formData.telephone"></el-input>
          </el-form-item>
          <el-form-item label="合作开始时间:">
            <el-date-picker class="picker" v-model="formData.startTime" type="datetime" placeholder="选择日期时间">
            </el-date-picker>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addInsurance = false">取 消</el-button>
        <el-button type="primary" @click="getAddInsuranceInfo()">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>
<script>
export default {
  props: ['addInsurance', 'addInsuranceInfo', 'insuranceCompanyDataInfo', 'title', 'editInsuranceCompanyData'],
  data() {
    return {
      formData: {
        id: '',
        companyName: '',
        companyWeb: '',
        companyPhone: '',
        area: '',
        dockName: '',
        telephone: '',
        startTime: ''
      }
    }
  },
  watch: {
    insuranceCompanyDataInfo() {
      this.formData = this.insuranceCompanyDataInfo
      this.formData.id = this.insuranceCompanyDataInfo.id
    },
    title() {
      if (this.title == '新增') {
        this.formData.companyName = ''
        this.formData.companyWeb = ''
        this.formData.area = ''
        this.formData.dockName = ''
        this.formData.telephone = ''
        this.formData.startTime = ''
      }
    }
  },
  methods: {
    close() {
      this.$emit('closeAddInsurance')
    },
    // 提交表单
    async getAddInsuranceInfo() {
      if (this.title == '新增') {
        this.addInsuranceInfo(this.formData)
        this.addInsurance = false
      } else if (this.title == '编辑') {
        this.editInsuranceCompanyData(this.formData)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.el-select {
  width: 100%;
}
.el-date-editor.el-input {
  width: 100%;
}
</style>
