<template>
  <div>
    <el-dialog :title="title" :visible.sync="isShow" width="50%" @close="hide" :close-on-click-modal="false">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="角色名称" prop="roleName">
          <el-radio-group v-model="ruleForm.roleName">
            <el-radio :label="0">超级管理员</el-radio>
            <el-radio :label="1">市场人员</el-radio>
            <el-radio :label="2">安装人员</el-radio>
            <el-radio :label="3">顾问团</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="负责人头像" prop="avatar">
          <el-upload
            class="upload-demo"
            :action="`https://kwangda.com:8092/sys/account/avatarUpload/file/${ruleForm.fullName}`"
            :headers="headerObj"
            :before-upload="beforeFileUp"
            :on-success="fileSuced"
            multiple
            :limit="1"
          >
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="负责人" prop="fullName">
          <el-input v-model="ruleForm.fullName"></el-input>
        </el-form-item>
        <el-form-item label="负责地区" prop="resArea">
          <el-input v-model="ruleForm.resArea" placeholder="顾问团必填"></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="mobile">
          <el-input v-model="ruleForm.mobile"></el-input>
        </el-form-item>
        <el-form-item label="登录密码" prop="password">
          <el-input v-model="ruleForm.password"></el-input>
        </el-form-item>
        <el-form-item label="权限说明" prop="power">
          <el-input type="textarea" v-model="ruleForm.power"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isShow = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: ['title', 'submitCb', 'recordData'],
  data() {
    return {
      headerObj() {
        Authorization: 'Bearer' + sessionStorage.getItem('token')
      },
      isShow: false,
      ruleForm: {
        avatar: '',
        roleName: '',
        mobile: '',
        password: '',
        fullName: '',
        power: ''
      },
      rules: {
        roleName: [{ required: true, message: '请输入', trigger: 'blur' }],
        fullName: [{ required: true, message: '请输入', trigger: 'blur' }],
        mobile: [
          { required: true, message: '请输入', trigger: 'blur' }
          // { min: 11, max: 11, message: '长度在11个字符', trigger: 'blur' }
        ],
        password: [{ required: true, message: '请输入', trigger: 'blur' }]
      }
    }
  },
  mounted() {
    if (this.recordData) {
      this.ruleForm = this.recordData
    }
  },
  methods: {
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw)
    },
    show() {
      this.isShow = true
    },
    hide() {
      this.isShow = false
      this.remove()
    },
    beforeFileUp() {
      if (!this.ruleForm.fullName) {
        this.$message.error('请先输入负责人名称')
        return false
      }
    },
    fileSuced(res) {
      if (res.success) {
        console.log('文件上传成功：：', res)
        this.ruleForm.avatar = res.data
        this.$message.success('上传成功')
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const params = {
            ...this.ruleForm,
            createTime: this.formatDate(new Date())
          }
          this.submitCb(params)
          this.hide()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    }
  }
}
</script>
<style lang="less" scoped>
.avatar-uploader-box {
  width: 98px;
  height: 98px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader-box:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 98px;
  height: 98px;
  line-height: 98px;
  text-align: center;
}
.avatar {
  width: 98px;
  height: 98px;
  display: block;
}
</style>